import get from 'lodash/get';
import { computed, makeObservable } from 'mobx';
import ItemBase, {
  type AggregateItemStore,
  type AggregateItemJson,
} from 'src/models/aggregates/item';
import type TransactionStore from 'src/stores/transaction-store';
import type {
  Item as ItemFromProto,
  ItemKind,
} from 'src/types/proto/transactions';
import type { Transaction } from '../transaction';
import ItemNamespace from './item-namespace';

export type ItemStore = TransactionStore & AggregateItemStore<Transaction>;

export type ItemJson<T extends ItemKind = 'UNKNOWN'> = AggregateItemJson &
  ItemFromProto & {
    kind: T;
    fieldValues?: Record<string, unknown>;
  };

export default class Item<
  TStore extends ItemStore,
  TJson extends ItemJson<TKind>,
  TKind extends ItemKind = TJson['kind']
> extends ItemBase<TStore, TJson, Transaction> {
  itemNamespace: ItemNamespace;

  constructor(store: TStore, json: TJson) {
    super(store, json);
    makeObservable(this);
    this.itemNamespace = new ItemNamespace(this);
  }

  // Seems it's never used and no kindItem has `task` field
  @computed
  get taskMeta() {
    return (this.kindItem as any).task;
  }

  @computed
  get transaction() {
    return this.aggregate;
  }

  @computed
  get thinTransaction() {
    return this.thinAggregate;
  }

  @computed
  get transactionTitle() {
    if (this.transaction) {
      return this.transaction.title;
    }
    if (this.thinTransaction) {
      return this.thinTransaction.title;
    }
    return undefined;
  }

  @computed
  get transactionParties() {
    if (this.transaction) {
      return this.transaction.parties;
    }
    if (this.thinTransaction) {
      return this.thinTransaction.parties;
    }
    return undefined;
  }

  @computed
  get transactionPermissions() {
    if (this.transaction) {
      return this.transaction.permissions;
    }
    if (this.thinTransaction) {
      return this.thinTransaction.permissions;
    }
    return [];
  }

  @computed
  get fieldValues() {
    return this.data.fieldValues || {};
  }

  getFieldValue(fieldId: string) {
    const fieldValues = get(this.data, 'fieldState.fieldValues', {});
    return get(fieldValues, [fieldId, 'value'], undefined);
  }

  itemsById = (ids: string[]) => {
    return ids.map((id) => this.store.itemsById.get(id)).filter(Boolean);
  };
}
