import intersection from 'lodash/intersection';
import { computed, makeObservable } from 'mobx';
import { KIND_PARTY } from 'src/models/transactions/recipients';
import { LISTING_AGENT, SELLER_ROLES } from 'src/models/transactions/roles';
import { ItemGuidedFormPacket } from 'src/types/proto/transactions';
import Item, { ItemJson, ItemStore } from './item';

type ItemGuidedFormPacketKind = ItemGuidedFormPacket & {
  isDemo: boolean;
  submissionErrors: unknown;
};

export interface GuidedFormPacketJson extends ItemJson<'GUIDED_FORM_PACKET'> {
  guidedFormPacket: ItemGuidedFormPacketKind;
}

const CLIENT_TAG_BY_STATUS = {
  CREATED: 'RECEIVED',
  STARTED: 'STARTED',
  FLOWS_COMPLETED: 'STARTED',
  FLOWS_EDITED: 'STARTED',
  FLOWS_EDITED_INCOMPLETE: 'STARTED',
  SUBMITTED: 'SUBMITTED',
  LOCKED: 'LOCKED',
  COMPLETED: 'COMPLETED',
};

const NOT_STARTED_FILLING_STATUSES = ['DRAFT', 'CREATED', 'NOT_STARTED'];

export default class GuidedFormPacket extends Item<
  ItemStore,
  GuidedFormPacketJson
> {
  resolvedItems = ['recipientParties'];

  constructor(store: ItemStore, json: GuidedFormPacketJson) {
    super(store, json);

    makeObservable(this);
  }

  @computed
  get status() {
    return this.kindItem.status;
  }

  @computed
  get reviewVers() {
    return this.kindItem.reviewVers;
  }

  @computed
  get creatorId() {
    return this.kindItem.creatorId;
  }

  @computed
  get generatedAt() {
    return this.kindItem.generatedAt;
  }

  @computed
  get taskId() {
    return this.inEdges
      .filter((e) => e.kind === 'TASK_HAS_MEMBER')
      .map((e) => e.item1Id)[0];
  }

  @computed
  get task() {
    return this.store.itemsById.get(this.taskId);
  }

  @computed
  get zfTxnId() {
    return this.kindItem.zfTxnId;
  }

  @computed
  get creationMode() {
    return this.kindItem.creationMode;
  }

  @computed
  get isDemo() {
    return this.kindItem.isDemo;
  }

  @computed
  get gfpFlowIds() {
    return this.outEdges
      .filter((e) => e.kind === 'GFP_HAS_GFPF')
      .map((e) => e.item2Id)
      .filter((id) => id);
  }

  @computed
  get attachmentTdvIds() {
    return (
      (this.kindItem.generateResult &&
        this.kindItem.generateResult.attachmentTdvIds) ||
      []
    );
  }

  @computed
  get attachmentTdvs() {
    return this.attachmentTdvIds
      .map((tdvId) => this.store.itemsById.get(tdvId))
      .filter((x) => x);
  }

  @computed
  get packetId() {
    return this.inEdges
      .filter((e) => e.kind === 'PACKET_HAS_CHILD')
      .map((e) => e.item1Id)[0];
  }

  @computed
  get gfpFlows() {
    return this.gfpFlowIds
      .map((gfpfId) => this.store.itemsById.get(gfpfId))
      .filter((gfpf) => gfpf);
  }

  @computed
  get hasSubmissionErrors() {
    return this.gfpFlows.some((gfpf) => gfpf.hasSubmissionErrors);
  }

  @computed
  get gfpFlowCount() {
    return this.gfpFlowIds.length;
  }

  @computed
  get recipients() {
    return this.kindItem.recipients || [];
  }

  @computed
  get invitesIds() {
    return this.kindItem.invitesIds || [];
  }

  @computed
  get recipientsCount() {
    // HACK this isn't completely reliable
    return this.recipients.length + this.invitesIds.length + 1;
  }

  @computed
  get isInPersonMode() {
    return (
      this.creationMode === 'AGENT_INPERSON' &&
      !this.kindItem.disableInPersonMode
    );
  }

  @computed
  get recipientParties() {
    return this.recipients
      .filter((r) => r.kind === KIND_PARTY)
      .map((r) => this.store.itemsById.get(r.party?.id));
  }

  @computed
  get sellers() {
    return this.recipientParties.filter(
      (p) => intersection(p.roles, SELLER_ROLES).length > 0
    );
  }

  @computed
  get listingAgent() {
    return this.recipientParties.filter((p) =>
      p.roles.includes(LISTING_AGENT)
    )[0];
  }

  get cc() {
    // Deprecated
    return [];
  }

  @computed
  get clientStatus() {
    type ClientTagByStatusKeys = keyof typeof CLIENT_TAG_BY_STATUS;

    return (
      CLIENT_TAG_BY_STATUS[this.status as ClientTagByStatusKeys] || this.status
    );
  }

  @computed
  get needsTutorial() {
    return (
      !this.myTutorialContext?.completed &&
      this.transaction?.isSale &&
      this.transaction.stateConfig.gfp_tutorial
    );
  }

  @computed
  get adminStatus() {
    const AGENT_TAG_BY_STATUS = {
      ...CLIENT_TAG_BY_STATUS,
    };
    AGENT_TAG_BY_STATUS.CREATED = 'SENT';
    type AgentTagByStatusKeys = keyof typeof AGENT_TAG_BY_STATUS;

    return (
      AGENT_TAG_BY_STATUS[this.status as AgentTagByStatusKeys] || this.status
    );
  }

  @computed
  get clientStatusColor() {
    const COLOR_BY_STATUS = {
      CREATED: undefined,
      STARTED: undefined,
      FLOWS_COMPLETED: undefined,
      FLOWS_EDITED: undefined,
      FLOWS_EDITED_INCOMPLETE: undefined,
      SUBMITTED: 'blue',
      COMPLETED: 'blue',
      LOCKED: undefined,
    };
    type ColorByStatusKeys = keyof typeof COLOR_BY_STATUS;

    return COLOR_BY_STATUS[this.status as ColorByStatusKeys];
  }

  @computed
  get adminStatusColor() {
    return this.clientStatusColor;
  }

  @computed
  get myTutorialContext() {
    return this.kindItem.myTutorialContext;
  }

  @computed
  get locked() {
    return this.kindItem.locked;
  }

  @computed
  get psd() {
    return this.kindItem.psd;
  }

  @computed
  get certificateTdvId() {
    return (
      this.kindItem.generateResult &&
      this.kindItem.generateResult.certificateTdvId
    );
  }

  @computed
  get gfpTdvIds() {
    return this.gfpFlowIds
      .map((gfpfId) => {
        return (
          this.kindItem.generateResult &&
          this.kindItem.generateResult.gfpTdvIds &&
          this.kindItem.generateResult.gfpTdvIds[gfpfId]
        );
      })
      .filter((x) => x);
  }

  @computed
  get clientUrl() {
    return `/client/transactions/${this.transactionId}/packets/${this.packetId}/`;
  }

  @computed
  get startedFilling() {
    return (
      Boolean(this.status) &&
      !NOT_STARTED_FILLING_STATUSES.includes(this.status)
    );
  }

  getGeneratedTdvIds(includeCertificate = false) {
    const tdvIds = [...this.gfpTdvIds];
    tdvIds.push(...this.attachmentTdvIds);
    if (this.certificateTdvId && includeCertificate) {
      tdvIds.push(this.certificateTdvId);
    }
    return tdvIds;
  }

  can(op: string) {
    if (op === 'submit') {
      return (
        this.status === 'FLOWS_EDITED' ||
        this.status === 'FLOWS_COMPLETED' ||
        this.status === 'SUBMITTED' ||
        this.status === 'COMPLETED'
      );
    }
    if (op === 'resend_invite') {
      return this.creationMode === 'AGENT';
    }
    if (op === 'sign') {
      return !!this.generatedAt;
    }
    if (op === 'navigate_flow') {
      return !this.locked;
    }
    if (op === 'generate') {
      return this.status !== 'CREATED' && !this.locked;
    }
    if (op === 'download') {
      return !!this.generatedAt && this.status === 'COMPLETED';
    }
    if (op === 'remove') {
      return this.gfpFlowCount > 1 && !this.locked;
    }
    return false;
  }

  @computed
  get isPremium() {
    return !!this.psd && this.psd.status === 'PREMIUM';
  }
}
