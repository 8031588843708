import Item from './item';
import { computed, makeObservable } from 'mobx';

export default class Timeline extends Item {
  constructor(store, json) {
    super(store, json);

    makeObservable(this);
  }

  @computed
  get setupFlowId() {
    return this.kindItem.setupFlowId;
  }

  @computed
  get keyDates() {
    return this.kindItem.keyDates;
  }

  @computed
  get setupStatus() {
    return this.kindItem.setupStatus;
  }

  @computed
  get ocrKeyDates() {
    return this.kindItem.ocrKeyDates;
  }

  @computed
  get team() {
    return this.kindItem.team || [];
  }

  @computed
  get logoUrl() {
    return this.kindItem.logoUrl;
  }

  @computed
  get sourceTdvIds() {
    return this.kindItem.sourceTdvIds;
  }

  @computed
  get splitStatus() {
    return this.kindItem.splitStatus;
  }

  @computed
  get splits() {
    return this.kindItem.splits;
  }

  @computed
  get splitTdvIds() {
    return this.kindItem.splitTdvIds;
  }

  @computed
  get needsDocumentSplit() {
    return this.kindItem.needsDocumentSplit;
  }

  @computed
  get attendeeIds() {
    return (this.outEdges || [])
      .filter((e) => e.kind === 'CALENDAR_ATTENDEE')
      .map((e) => e.item2Id);
  }

  @computed
  get isMeAttending() {
    return this.attendeeIds.some(
      (id) =>
        id === this.transaction.parties.me && this.transaction.parties.me.id
    );
  }
}
