import React from 'react';
import { datadogRum } from '@datadog/browser-rum';
import type { ToastVariant } from '@uc/compass-app-bridge/dist/actions';
import get from 'lodash/get';
import intersection from 'lodash/intersection';
import pick from 'lodash/pick';
import remove from 'lodash/remove';
import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
  toJS,
} from 'mobx';
import { Channel } from 'pusher-js';
import analytics from 'src/analytics';
import api from 'src/api';
import adminApi from 'src/api/admin-api';
import publicApi from 'src/api/public-api';
import ContactSuggestionModal from 'src/components/account/contact-suggestion-modal';
import FixEmailModal from 'src/components/account/fix-email-modal';
import type { EnrichedAssociation } from 'src/components/common/uploader/get-associations';
import {
  AGENT_SELECTED_ROLES,
  PREMIUM_TIERS,
  TC_SELECTED_ROLES,
} from 'src/constants';
import {
  APP_ROLE_ANNOTATOR,
  APP_ROLE_CAR_ADMIN,
  APP_ROLE_SUPER_ADMIN,
  APP_ROLE_SUPPORT_ONLY,
} from 'src/models/auth/user';
import { ensureAddress } from 'src/stores/address-store';
import type { User, UserFeatures } from 'src/types/proto/auth';
import type {
  LinkedAccountConfig,
  MlsMembership,
} from 'src/types/proto/integrations';
import type { PermissionEnum } from 'src/types/proto/permissions';
import { VerificationMethodBridgeIntegrationBridgeProvider } from 'src/types/proto/verifications';
import type { DeepPartial } from 'src/types/utils';
import { getFetch } from 'src/utils/get-fetch';
import getFullName from 'src/utils/get-full-name';
import getStateConfig from 'src/utils/get-state-config';
import logoutClear from 'src/utils/logout-clear';
import { receiveMessage as receiveMobileBridgeMessage } from 'src/utils/mobile-bridge';
import mockable from 'src/utils/mockable';
import pusher from 'src/utils/pusher';
import querySerialize from 'src/utils/query-serialize';
import * as FS from '@fullstory/browser';
import type { AppStore } from './app-store';
import { shouldCaptureSessionFS } from 'src/fullstory';

const LEGACY_USER_CUTOFF = 1610643600; // 9am 1/14/2021 PST

type Config = {
  sawOffersMgmtPromoModal?: boolean;
  pipelinePreferences?: any;
  seenAddFormLibrariesModal?: boolean;
  clientWorkspaceEnabled?: boolean;
};

type Team = {
  id: string;
  ownerId: string;
  kind: string;
  owner: {
    id: string;
  };
  permissions: string[];
};

type Org = {
  org: {
    team?: {
      membership: {
        roles: string[];
      };
    };
  };
  canSeeOrg?: boolean;
  canSeeGroups?: boolean;
  canSeeOffices?: boolean;
};

export type Contact = {
  firstName: string;
  lastName: string;
  email: string;
  address: string;
  cellPhone: string;
  reAgent: {
    address: string;
    licenseState: string;
    licenseNumber: string;
  };
};

type Recipient = {
  id: string;
  // eslint-disable-next-line camelcase
  first_name: string;
  // eslint-disable-next-line camelcase
  last_name: string;
  email: string;
};

type LogoutOptions = {
  redirect?: string;
  email?: string;
  next?: string;
  // eslint-disable-next-line camelcase
  msg_key?: string;
};

export default class AccountStore {
  @observable user: User | null = null;

  @observable recipient: Recipient | null = null;

  @observable emails: string[] | any[] | null = null;

  @observable config: Config = {};

  @observable myTeams = null;

  @observable isCompassOrgAdmin = false;

  @observable memberships = [];

  @observable showPolicyAcceptanceModal: null | boolean = null;

  @observable intercomUnreadCount = 0;

  @observable oboProfilesById = new Map();

  @observable ownAssociations:
    | null
    | Awaited<
        ReturnType<typeof publicApi['orgs']['listOwnOrganizations']>
      >['data'] = null;

  userEventsChannel: null | Channel = null;

  callbacksToSubscribe: { key: string; callback: () => void }[] = [];

  parent: AppStore;

  api: typeof publicApi['account'];

  orgs: typeof publicApi['orgs'];

  members: any;

  constructor(parent: AppStore) {
    makeObservable(this);
    this.parent = parent;
    this.api = this.parent.api.account;
    this.orgs = this.parent.api.orgs;

    /*
      API call for test purposes. Add a method that loads metrics and stores them
      here in this store. You will need metrics.txnCount for the popup triggering decision.

      To update user metrics in your dev environment:

      $ docker-compose exec webapp bash
      root@12c604b4f472:/code# python manage.py user_metrics --since_days 100
    */

    receiveMobileBridgeMessage({
      name: 'logout',
      cb: this.logOut,
      permanent: true,
    });
  }

  @computed
  get isAuthenticated() {
    return !!this.user;
  }

  @computed
  get fullName() {
    const { firstName = '', lastName = '' } = this.user?.contact || {};
    return getFullName({
      firstName,
      lastName,
    });
  }

  @computed
  get address() {
    return this.user?.contact!.reAgent!.address;
  }

  getNarApiCredentials = async () => {
    const { data } = await api.account.getNarCredentials();
    return {
      nrdsId: data.nar_nrds_id,
      lastName: data.nar_last_name,
    };
  };

  getNwmlsApiCredentials = async () => {
    const { data } = await api.account.getNwmlsCredentials();
    return {
      nwmlsId: data.nwmls_id,
      lastName: data.nar_last_name,
    };
  };

  getBridgeApiCredentials = async (
    provider: VerificationMethodBridgeIntegrationBridgeProvider = VerificationMethodBridgeIntegrationBridgeProvider.UNKNOWN
  ) => {
    if (provider === 'RHODE_ISLAND' && this.user) {
      return {
        nrdsId: this.user.riBridgeNrdsId,
        lastName: this.user.riBridgeLastName,
      };
    }
    return {};
  };

  isMemberFromAssociation = async (organization: EnrichedAssociation) => {
    if (!this.ownAssociations) {
      await this.refreshOwnAssociations();
    }
    if (organization.org_kind === 'BROKER') {
      return this.ownAssociations?.broker_associations.some(
        (a) => a.associationId === organization.id
      );
    }
    return Object.entries(this.ownAssociations ?? {}).some(
      ([mlsOrgKey, memberships]) =>
        mlsOrgKey !== 'broker_associations' &&
        (memberships as MlsMembership[]).some(
          (m) => m.mlsOrgId === organization.id
        )
    );
  };

  @computed
  get showCompassAliasEmailCTA() {
    return (
      this.user &&
      this.user.originalCompassEmail &&
      !this.user?.compassAliasEmailConfirmed
    );
  }

  @computed
  get originalCompassEmail() {
    return this.user?.originalCompassEmail;
  }

  @computed
  get email() {
    return this.user?.contact!.email;
  }

  @computed
  get isAdmin() {
    return this.user?.isAdmin;
  }

  hasAdminRole(role: string) {
    return this.user?.isAdmin && this.user.appRole === role;
  }

  @computed
  get availableStates(): string[] {
    const orgStates: string[] = [
      ...(get(this.user, 'orgInfo.org.states', []) || []),
    ];
    return orgStates.length ? orgStates : [this.user?.accountState || 'CA'];
  }

  @computed
  get isEarlyAccess(): boolean {
    return (
      !!this.user &&
      !!this.user.accountState &&
      getStateConfig(this.user.accountState).early_availability
    );
  }

  @computed
  get isSupportOnlyAdmin() {
    return this.hasAdminRole(APP_ROLE_SUPPORT_ONLY);
  }

  @computed
  get isSuperAdmin() {
    return this.hasAdminRole(APP_ROLE_SUPER_ADMIN);
  }

  @computed
  get isCarAdmin() {
    return this.hasAdminRole(APP_ROLE_CAR_ADMIN);
  }

  @computed
  get isAnnotator() {
    return this.hasAdminRole(APP_ROLE_ANNOTATOR);
  }

  @computed
  get isLegacyUser() {
    return Number(this.user?.createdAt) / 1000 < LEGACY_USER_CUTOFF;
  }

  @computed
  get isDemo() {
    return this.user && this.user.isDemo;
  }

  @computed
  get isBrokerageAccount() {
    return Boolean(this.user && this.user.orgId);
  }

  @computed
  get isBrokerageOwner() {
    return (
      this.isBrokerageAccount &&
      get(this.orgInfo, 'org.team.ownerId') === this.user?.id
    );
  }

  get isBrokerageAdmin() {
    if (!this.isBrokerageAccount) {
      return false;
    }
    return this.orgInfo?.org!.team?.membership?.roles?.includes('ADMIN');
  }

  getUserFeatures = mockable<Partial<UserFeatures>>(
    'features',
    'features',
    () => this.user?.features || {}
  );

  @computed
  get isLegacyTc() {
    return this.getUserFeatures().tier === 'LEGACY_PRO_TC';
  }

  @computed
  get isProAccount() {
    return PREMIUM_TIERS.includes(this.getUserFeatures().tier!);
  }

  @computed
  get isLegacyBrokerProAccount(): boolean {
    return get(this, 'user.features.tier') === 'LEGACY_BROKER_PRO';
  }

  @computed
  get isTmsEnabled() {
    return true;
  }

  @computed
  get isTmsTcEnabled() {
    return Boolean(
      this.user &&
        this.user.accessModes &&
        (this.user.accessModes.includes('TMS_TC') ||
          this.user.accessModes.includes('TMS'))
    );
  }

  get isAgentProfessional() {
    return (
      Boolean(this.user) &&
      intersection(this.user?.selectedRoles || [], AGENT_SELECTED_ROLES)
        .length > 0
    );
  }

  get isTcProfessional() {
    return (
      Boolean(this.user) &&
      intersection(this.user?.selectedRoles || [], TC_SELECTED_ROLES).length > 0
    );
  }

  get isPureTcProfessional() {
    return this.isTcProfessional && !this.isAgentProfessional;
  }

  @computed
  get accessMode() {
    if (this.user && this.user.accessModes) {
      if (this.user.accessModes.includes('AGENT')) {
        return 'AGENT';
      }
      if (this.user.accessModes.includes('CLIENT')) {
        return 'CLIENT';
      }
    }
    return 'AGENT';
  }

  @computed
  get teams() {
    return (this.user && this.user.teams) || [];
  }

  @computed
  get agentTeamOwners() {
    return this.teams
      .filter((t) => t.kind === 'AGENT' && t.ownerId !== this.user?.id)
      .map((t) => t.owner);
  }

  removeSelfFromAgentTeam = async (agentId: string) => {
    if (agentId === this.user?.id) {
      throw new Error('Cannot complete this action.');
    }
    const team = this.teams.find(
      (t) => t.kind === 'AGENT' && t.ownerId === agentId
    );
    if (!team) {
      throw new Error(`Could not find agent team for agent with id ${agentId}`);
    }
    await this.orgs.removeSelfFromTeam(team.id);
    await this.fetchMe();
  };

  hasPermissionForTeamIds = (permission: string, teamIds: string[]) =>
    this.teams.filter(
      (t) =>
        teamIds.includes(t.id) &&
        t.permissions &&
        t.permissions.includes(permission as PermissionEnum)
    ).length > 0;

  get orgInfo(): Partial<Org> {
    return this.user?.orgInfo || { org: {} };
  }

  @computed
  get canSeeOtherTeams() {
    return Boolean(
      this.orgInfo.canSeeOrg ||
        this.orgInfo.canSeeOffices ||
        this.orgInfo.canSeeGroups
    );
  }

  findLinkedAccount = (kind: string, linkKind?: string) => {
    const linkedAccounts = get(this.user, 'linkedAccounts', []);
    return linkedAccounts.find(
      (a) => a.kind === kind && (!linkKind || a.linkKind === linkKind)
    );
  };

  hasLinkedAccount = (kind: string, linkKind?: string) =>
    Boolean(this.findLinkedAccount(kind, linkKind));

  @computed
  get hasCrmlsLinkedAccount() {
    return this.hasLinkedAccount('CRMLS');
  }

  @computed
  get isAgentApp() {
    return this.parent.appName === 'agent';
  }

  @computed
  get isAuthApp() {
    return this.parent.appName === 'auth';
  }

  @computed
  get isAgentOrAuthApp() {
    return this.isAgentApp || this.isAuthApp;
  }

  @computed
  get isUpgradeable() {
    return (
      this.isAgentOrAuthApp &&
      this.user &&
      this.isAgentProfessional &&
      !this.isLegacyTc &&
      !this.isEarlyAccess
    );
  }

  @computed
  get showUpgradePromo() {
    return (
      this.isUpgradeable && !this.isProAccount && !this.isLegacyBrokerProAccount
    );
  }

  @action
  refreshOwnAssociations = async () => {
    const { data } = await this.orgs.listOwnOrganizations();
    this.ownAssociations = data;
  };

  @action
  shouldDisplayReferral = async () => {
    const { ui } = this.parent;
    if (ui.isReferralFlagOn) {
      const { data } = await this.api.shouldDisplayReferral();
      return data;
    }
    return false;
  };

  @action
  setUser(user: User) {
    const previousUser = this.user;
    this.user = user;
    this.emails = (user && user.emails) || [];

    if (previousUser && (!user || user.id !== previousUser.id)) {
      pusher.unsubscribe(String(previousUser.uuid));
      this.oboProfilesById.clear();
    }

    if (user && user.contact) {
      ensureAddress(user.contact.address);
      ensureAddress(user.contact.reAgent && user.contact.reAgent.address);
    }

    if (!user) {
      analytics().identify('__anonymous-user', {});
    }

    if (user && (!previousUser || user.id !== previousUser.id)) {
      this.setUserEventsChannel();
      analytics().identify(
        this.user.id,
        {
          accessMode: this.accessMode,
          firstName: this.user.contact!.firstName,
          lastName: this.user.contact!.lastName,
          email: this.user.contact!.email,
          createdAt: Number(+this.user.createdAt) / 1000,
          orgId: this.user.orgId,
        },
        {
          integrations: {
            Intercom: false,
          },
        }
      );

      if (window.Glide.FULLSTORY_ORG_ID) {
        FS.init({ orgId: window.Glide.FULLSTORY_ORG_ID });
        if (shouldCaptureSessionFS(user.selectedRoles)) {
          let params = {
            displayName: this.user.contact!.firstName,
            email: this.user.contact!.email,
            firstName: this.user.contact!.firstName,
            lastName: this.user.contact!.lastName,
            createdAt: Number(+this.user.createdAt) / 1000,
            orgId: this.user.orgId,
          };
          FS.identify(this.user.id, params);
          const sessionUrl = FS.getCurrentSessionURL(true);
          if (sessionUrl) {
            datadogRum.addRumGlobalContext('fullstory', { sessionUrl });
          }
        } else {
          FS.shutdown();
        }
      }

      if (window.Intercom) {
        window.Intercom('update', {
          app_id: window.Glide.INTERCOM_APP_ID,
          user_id: this.user.id,
          user_hash: this.parent.options.intercomUserHash,
          name: getFullName({
            firstName: this.user.contact!.firstName,
            lastName: this.user.contact!.lastName,
          }),
          email: this.user.contact!.email,
        });
      }

      datadogRum.setUser({
        id: this.user.id,
        email: this.user.contact!.email,
      });
    }

    if (this.user && this.user.config) {
      const { config } = this.user;
      delete this.user.config;
      this.config = toJS(config);
    }
    this.blockOnUndeliverableEmail();
    if (this.user && this.user.contactSuggestion) {
      this.handleContactSuggestion(this.user.contactSuggestion);
    }
  }

  setRecipient(recipient: Recipient) {
    const prev = this.recipient;
    this.recipient = recipient;

    if (
      this.user ||
      !this.recipient ||
      (prev && prev.id === this.recipient.id)
    ) {
      return;
    }

    // we don't care about Intercom for now (or ever?).
    datadogRum.setUser({
      email: this.recipient.email,
    });
  }

  @action
  initialize(options: { user: User; recipient: Recipient }) {
    this.setUser(options.user);
    this.updateCompassAdminStatus();
    this.setRecipient(options.recipient);
    this.ensurePrivacyPolicyAcceptance();
    this.registerIntercomHooks();
  }

  @action
  registerIntercomHooks() {
    if (!window.Intercom) {
      return;
    }

    window.Intercom('onUnreadCountChange', (unreadCount: number) => {
      runInAction(() => {
        this.intercomUnreadCount = unreadCount;
      });
    });
  }

  @action
  updateProfileInfo = async (...args: any[]) => {
    const { data } = await this.api.updateProfileInfo(...args);
    this.setUser(data);
    return this.user;
  };

  @action
  setUserEventsChannel() {
    this.userEventsChannel = pusher.subscribe(String(this.user?.uuid));
    this.callbacksToSubscribe.forEach(({ key, callback }) => {
      this.userEventsChannel!.bind(key, callback);
    });
    this.userEventsChannel.bind('user_async_toast', this.asyncToast);
    this.userEventsChannel.bind(
      'new_contact_suggestion',
      this.checkContactSuggestion
    );
    this.userEventsChannel.bind('fetch_me', this.fetchMe);
  }

  checkContactSuggestion = (data: { suggestion: string }) => {
    this.handleContactSuggestion(data.suggestion);
  };

  handleContactSuggestion = mockable(
    'prevent-handle-contact-suggestion',
    'prevent-handle-contact-suggestion',
    (suggestion: any) => {
      const { ui } = this.parent;
      if (!ui.isGlideMobileApp && !ui.isEmbedded) {
        ui.setCustomModal(({ onCancel }) => (
          <ContactSuggestionModal
            suggestion={suggestion}
            visible
            onCancel={onCancel}
          />
        ));
      }
    }
  );

  asyncToast = (data: { message: string; type: ToastVariant }) => {
    this.parent.ui.toast({
      message: data.message,
      type: data.type,
    });
  };

  blockOnUndeliverableEmail = async () => {
    if (!this.user || this.accessMode !== 'AGENT') {
      return;
    }

    const { ui } = this.parent;
    const badPrimaryEmail =
      this.emails &&
      this.emails.find((e) => e.isPrimary && e.state === 'UNDELIVERABLE');

    if (badPrimaryEmail) {
      ui.setCustomModal(({ onClose }) => (
        <FixEmailModal
          badPrimaryEmail={badPrimaryEmail}
          onSave={async (email: string) => {
            if (!this.emails?.find((e) => e.email === email)) {
              await this.addEmail(email);
            }
            await this.setDefaultEmail(email);
            await this.removeEmail(badPrimaryEmail.email);
            onClose();
          }}
        />
      ));
    }
  };

  @action
  subscribeUserEvent(key: string, callback: () => void) {
    if (this.userEventsChannel) {
      this.userEventsChannel.bind(key, callback);
    } else {
      this.callbacksToSubscribe.push({
        key,
        callback,
      });
    }
  }

  @action
  updateCompassAdminStatus = async () => {
    if (this.user) {
      try {
        const { data } = await adminApi.auth.isCompassOrgAdmin();
        this.isCompassOrgAdmin = data;
        return data;
      } catch (err) {
        return false;
      }
    }
    return false;
  };

  @action
  unsubscribeUserEvent(key: string, callback: () => void) {
    if (this.userEventsChannel) {
      this.userEventsChannel.unbind(key, callback);
    } else {
      remove(
        this.callbacksToSubscribe,
        (x) => x.key === key && (!callback || x.callback === callback)
      );
    }
  }

  @action
  fetchMe = async () => {
    const { data } = await this.api.fetchMe();
    this.setUser(data);
    return this.user;
  };

  fetchAgentTeamInviteRequests = async () => {
    const { data } = await this.orgs.listAgentTeamMembershipRequests();
    return data;
  };

  @action
  dashboardRedirects = async () => {
    const { data } = await this.api.dashboardRedirects();
    if (data && data.name) {
      this.parent.router.navigate(data.name, data.params);
    }
  };

  fetchMyTeams = getFetch({
    bindTo: this,
    getter: () => this.myTeams,
    fetcher: async () => {
      const { data } = await this.api.fetchMyTeams();

      runInAction(() => {
        this.myTeams = data;
      });
    },
  });

  @action
  addEmail = async (email: string, isMarkedPrimary?: boolean) => {
    const { data } = await this.api.addEmail(email, isMarkedPrimary);
    runInAction(() => {
      if (
        !this.emails?.some(
          (em) => em.email.toUpperCase() === data.email.toUpperCase()
        )
      ) {
        this.emails?.push(data);
      }
    });
    return this.emails;
  };

  @action
  removeEmail = async (email: string) => {
    await this.api.removeEmail(email);
    await this.fetchMe();
  };

  @action
  setDefaultEmail = async (email: string) => {
    await this.api.setDefaultEmail(email);
    await this.fetchMe();
  };

  @action
  setNarApiCredentials = async (narNrdsId: string, narLastName: string) => {
    await this.api.updateNarApiCredentials(narNrdsId, narLastName);
    await this.fetchMe();
  };

  @action
  setNwmlsApiCredentials = async (nwmlsId: string, lastName: string) => {
    await this.api.updateNwmlsApiCredentials(nwmlsId, lastName);
    await this.fetchMe();
  };

  @action
  setBridgeRiApiCredentials = async (nrdsId: string, lastName: string) => {
    await this.api.updateBridgeRiApiCredentials(nrdsId, lastName);
    await this.fetchMe();
  };

  @action
  updateSelectedRoles = async (selectedRoles: string[]) => {
    await this.api.updateSelectedRoles(selectedRoles);
    await this.fetchMe();
  };

  @action
  fetchMembers = () => {
    runInAction(() => {
      this.members = this.user?.defaultTeamMemberships;
    });
    return this.members;
  };

  @action
  addMember = async (email: string) => {
    const { data } = await this.orgs.inviteTeamMembers({
      members: [
        {
          email,
        },
      ],
    });
    runInAction(() => {
      this.members.push(data);
    });
    return this.members;
  };

  @action
  removeMember = async (member: { id: string }) => {
    await this.orgs.removeTeamMember(member.id);
    runInAction(() => {
      remove(this.members, {
        id: member.id,
      });
    });
  };

  @action
  updateConfig = async (configUpdate: Partial<Config>) => {
    const config = toJS(this.config);
    Object.assign(config, configUpdate);
    const newConfig = await this.api.updateConfig(config);
    runInAction(() => {
      this.config = newConfig.data;
    });
    return this.config;
  };

  @computed
  get pipelinePreferences() {
    return this.config.pipelinePreferences || {};
  }

  @action
  deleteReminder = async ({ key }: { key?: string } = {}) => {
    await this.api.deleteReminder(key);
    runInAction(() => {
      if (this.user?.userReminders!.reminders) {
        remove(this.user?.userReminders.reminders, {
          key,
        });
      }
    });
  };

  @action
  updateReminder = async (reminder: any) => {
    const {
      data: { reminders },
    } = await this.api.updateReminder(reminder);
    runInAction(() => {
      if (this.user?.userReminders) {
        this.user.userReminders.reminders = reminders;
      }
    });
  };

  @action
  deleteLinkedAccount = async (id: string) => {
    await this.parent.api.integrations.deleteLinkedAccount(id);
    runInAction(() => {
      if (this.user?.linkedAccounts) {
        remove(this.user.linkedAccounts, {
          id,
        });
      }
    });
  };

  @action
  updateLinkedAccountConfig = async (
    id: string,
    config: DeepPartial<LinkedAccountConfig>
  ) => {
    const { data: linkedAccount } =
      await this.parent.api.integrations.updateLinkedAccountConfig(id, config);
    runInAction(() => {
      this.user?.linkedAccounts
        .filter((la) => la.id !== linkedAccount.id)
        .concat([linkedAccount]);
    });
  };

  ensurePrivacyPolicyAcceptance = async () => {
    runInAction(() => {
      if (this.user?.pendingPrivacyPolicyAcceptance) {
        this.showPolicyAcceptanceModal = true;
      }
    });
  };

  @action
  acceptPrivacyPolicy = () => {
    this.api.acceptPrivacyPolicy(); // purposely with no await
    if (this.user) {
      this.user.pendingPrivacyPolicyAcceptance = false;
    }
    this.showPolicyAcceptanceModal = false;
  };

  updateOptOutDataSale = async (value: boolean, userId?: string) => {
    await this.api.updateOptOutDataSale(value, userId);
    runInAction(() => {
      if (this.user) {
        this.user.optOutDataSale = value;
      }
    });
  };

  @computed
  get showOffersMgmtPromoModal() {
    return !this.config.sawOffersMgmtPromoModal;
  }

  @computed
  get isInPerson() {
    return Boolean(this.getUserFeatures().inperson);
  }

  requireOboPermissionConfirmation = ({
    userId,
    contact,
    title = 'Permission Required',
    content,
    onSuccess,
    onFail,
  }: {
    userId: string;
    contact: Contact;
    title?: string;
    content?: string;
    onSuccess: () => void;
    onFail?: (arg: boolean) => void;
  }) => {
    const { ui } = this.parent;

    // Skip this flow for BT.
    // i.e., allow secondary agents to modify primary agents' txn settings.
    if (ui.isEmbedded) {
      onSuccess();
      return;
    }

    const user =
      userId !== this.user?.id
        ? this.agentTeamOwners.find((u) => u.id === userId)
        : this.user;

    if (user) {
      onSuccess();
      return;
    }

    ui.confirm({
      title,
      content:
        content ||
        `You need permission to access this resource. Ask ${contact.email} for access or switch to an account with access.`,
      okText: 'Request Access',
      onOk: async () => {
        try {
          await this.orgs.requestMembershipIntoAgentTeam(contact);
          ui.toast({
            type: 'info',
            message:
              "You'll get an email letting you know if your request was approved.",
          });
        } catch (err: unknown) {
          ui.wentWrong(err as Error);
        }
        if (onFail) {
          onFail(true);
        }
      },
      onCancel() {
        if (onFail) {
          onFail(false);
        }
      },
    });
  };

  logOut = (params?: LogoutOptions) => {
    if (window.Glide?.IS_FE_BE_SPLIT) {
      api.auth.logout().then(() => {
        logoutClear();
        if (params?.redirect) {
          window.location.href = params.redirect;
        }
        window.location.href = `/auth/login${
          params
            ? `?${querySerialize(pick(params, ['email', 'next', 'msg_key']))}`
            : ''
        }`;
      });
    } else {
      logoutClear();
      window.location.href = `/auth/logout${
        params ? `?${querySerialize(params)}` : ''
      }`;
    }
  };

  getFetchOboProfile = getFetch({
    bindTo: this,
    getMemoizeKey: ({ userId }: { userId: string }) => userId,
    getter: ({ userId }: { userId: string }) =>
      this.oboProfilesById.get(userId),
    fetcher: async ({ userId }: { userId: string }) => {
      const { data } = await this.api.fetchOboProfile(userId);
      const oboProfile = data;
      runInAction(() => {
        this.oboProfilesById.set(userId, oboProfile);
      });
    },
  });
}
