import Item from './item';
import getDateMoment from 'src/utils/get-date-moment';
import { computed, makeObservable } from 'mobx';

const ACCEPTANCE_KEY = 'std/acceptance_date';

export default class TimelineDate extends Item {
  constructor(store, json) {
    super(store, json);

    makeObservable(this);
  }

  @computed
  get date() {
    return this.kindItem.date;
  }

  @computed
  get key() {
    return this.kindItem.key;
  }

  @computed
  get dateMoment() {
    return getDateMoment(this.date);
  }

  @computed
  get dateTimestamp() {
    return +this.dateMoment;
  }

  @computed
  get description() {
    return this.kindItem.description;
  }

  @computed
  get status() {
    return this.kindItem.status;
  }

  @computed
  get labelIds() {
    return this.kindItem.labelIds;
  }

  @computed
  get isAcceptanceDate() {
    return this.key === ACCEPTANCE_KEY;
  }
}
