export function getCookieValue(a: string) {
  const b = document.cookie.match(`(^|;)\\s*${a}\\s*=\\s*([^;]+)`);
  return b ? b.pop() : '';
}

export function setCookie(
  name: string,
  value: string | number,
  seconds?: number
) {
  let expires = '';
  if (seconds) {
    const date = new Date();
    // eslint-disable-next-line no-mixed-operators
    date.setTime(date.getTime() + seconds * 1000);
    expires = date.toUTCString();
  }
  const domain =
    window.Glide.env === 'local' ? window.location.host : '.glide.com';

  document.cookie = `${name}=${value};expires=${expires};domain=${domain};path=/;`;
}

export function setLeadCookie() {
  let leadId = getCookieValue('leadId');

  if (!leadId) {
    leadId = Math.random().toString(36);
    const seconds = 365 * 24 * 60 * 60;
    setCookie('leadId', leadId, seconds);
  }
}
