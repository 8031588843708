/* Generated file, please DO NOT edit directly */
/* See jsapp/proto2ts.sh */

/* eslint-disable */

export const protobufPackage = 'dates';

export interface DateComputation {
  kind: DateComputationKind;
  absolute: string;
  dayDiff?: DateComputationDayDiff;
}

export const DateComputationKind = {
  EMPTY: 'EMPTY',
  ABSOLUTE: 'ABSOLUTE',
  DAYS_AFTER: 'DAYS_AFTER',
  DAYS_BEFORE: 'DAYS_BEFORE',
  BUSINESS_DAYS_AFTER: 'BUSINESS_DAYS_AFTER',
  BUSINESS_DAYS_BEFORE: 'BUSINESS_DAYS_BEFORE',
} as const;

export type DateComputationKind =
  typeof DateComputationKind[keyof typeof DateComputationKind];

export interface DateComputationDayDiff {
  days: number;
  objectId: string;
}

export interface KeyDate {
  uuid: string;
  kind: KeyDateKind;
  type: KeyDateEnum;
  static?: KeyDateStatic;
  relative?: KeyDateRelative;
  /** this is computed and will be overwritten every time */
  computedDate: number;
  title: string;
  description: string;
  visibility: KeyDateVisibility;
  avoidHoliday: boolean;
  isOcr: boolean;
  useBusinessDays: boolean;
  reminders: KeyDateReminder[];
  isComplete: boolean;
  completedAt: number;
  hasPendingReminders: boolean;
}

export const KeyDateVisibility = {
  EVERYONE: 'EVERYONE',
  ONLY_ME: 'ONLY_ME',
  ME_AND_TEAM: 'ME_AND_TEAM',
  ME_AND_CLIENT: 'ME_AND_CLIENT',
} as const;

export type KeyDateVisibility =
  typeof KeyDateVisibility[keyof typeof KeyDateVisibility];

export const KeyDateEnum = {
  NONE: 'NONE',
  ACCEPTANCE: 'ACCEPTANCE',
  INITIAL_DEPOSIT: 'INITIAL_DEPOSIT',
  VERIFICATION_DOWN_PAYMENT: 'VERIFICATION_DOWN_PAYMENT',
  SELLER_DISCLOSURES: 'SELLER_DISCLOSURES',
  INSPECTION_CONTINGENCY: 'INSPECTION_CONTINGENCY',
  APPRAISAL_CONTINGENCY: 'APPRAISAL_CONTINGENCY',
  LOAN_CONTINGENCY: 'LOAN_CONTINGENCY',
  VERIFICATION_PROPERTY_CONDITION: 'VERIFICATION_PROPERTY_CONDITION',
  CLOSE_ESCROW: 'CLOSE_ESCROW',
  ALL_CONTINGENCIES_REMOVAL: 'ALL_CONTINGENCIES_REMOVAL',
  CONTRACT_CREATED: 'CONTRACT_CREATED',
  SALE_OF_BUYER_PROPERTY: 'SALE_OF_BUYER_PROPERTY',
  PURCHASE_AGREEMENT: 'PURCHASE_AGREEMENT',
  POSESSION_DATE: 'POSESSION_DATE',
  CUSTOM: 'CUSTOM',
} as const;

export type KeyDateEnum = typeof KeyDateEnum[keyof typeof KeyDateEnum];

export const KeyDateKind = { STATIC: 'STATIC', RELATIVE: 'RELATIVE' } as const;

export type KeyDateKind = typeof KeyDateKind[keyof typeof KeyDateKind];

export interface KeyDateStatic {
  date: number;
}

export interface KeyDateRelative {
  /** relative to is fixed in RPA -- should this hard coded based on date type? */
  relativeTo: KeyDateEnum;
  delta: number;
  holidayAdjusted: boolean;
  isWaived: boolean;
  weekendAdjusted: boolean;
}

export interface KeyDateReminder {
  channel: KeyDateReminderChannel;
  scheduleType: KeyDateReminderScheduleType;
  scheduleCount: number;
  userId: string;
  status: KeyDateReminderStatus;
  skipReason: string;
  reminderDate: number;
  sentAt: number;
}

export const KeyDateReminderChannel = {
  UNKNOWN: 'UNKNOWN',
  EMAIL: 'EMAIL',
  SMS: 'SMS',
  BOTH: 'BOTH',
} as const;

export type KeyDateReminderChannel =
  typeof KeyDateReminderChannel[keyof typeof KeyDateReminderChannel];

export const KeyDateReminderScheduleType = {
  UNDEFINED: 'UNDEFINED',
  DAY: 'DAY',
  WEEK: 'WEEK',
} as const;

export type KeyDateReminderScheduleType =
  typeof KeyDateReminderScheduleType[keyof typeof KeyDateReminderScheduleType];

export const KeyDateReminderStatus = {
  CREATED: 'CREATED',
  PENDING: 'PENDING',
  SENT: 'SENT',
  SKIPPED: 'SKIPPED',
} as const;

export type KeyDateReminderStatus =
  typeof KeyDateReminderStatus[keyof typeof KeyDateReminderStatus];
