import { create } from './axios';
import codes from './codes';
import Account from './public/account';
import Activities from './public/activities';
import Addresses from './public/addresses';
import Auth from './public/auth';
import Brokers from './public/brokers';
import BuyerProspects from './public/buyer-prospects';
import Clauses from './public/clauses';
import Comms from './public/comms';
import Contacts from './public/contacts';
import Documents from './public/documents';
import Envelopes from './public/envelopes';
import ExternalAuth from './public/external-auth';
import Flows from './public/flows';
import FormInitiationPages from './public/form-initiation-pages';
import Forms from './public/forms';
import Growth from './public/growth';
import Hubspot from './public/hubspot';
import Integrations from './public/integrations';
import Orgs from './public/orgs';
import Packages from './public/packages';
import Packets from './public/packets';
import RequestPacketAccess from './public/request-packet-access';
import SharedPacket from './public/shared-packet';
import TemplateChecklists from './public/template-checklists';
import TransactionTemplates from './public/transaction-templates';
import Transactions from './public/transactions';
import Vendors from './public/vendors';

const axios = create('/api/');

const api = {
  auth: new Auth(axios),
  account: new Account(axios),
  buyerProspects: new BuyerProspects(axios),
  brokers: new Brokers(axios),
  templateChecklists: new TemplateChecklists(axios),
  flows: new Flows(axios),
  addresses: new Addresses(axios),
  transactions: new Transactions(axios),
  transactionTemplates: new TransactionTemplates(axios),
  contacts: new Contacts(axios),
  integrations: new Integrations(axios),
  formInitiationPages: new FormInitiationPages(axios),
  documents: new Documents(axios),
  packages: new Packages(axios),
  packets: new Packets(axios),
  sharedPacket: new SharedPacket(axios),
  requestPacketAccess: new RequestPacketAccess(axios),
  orgs: new Orgs(axios),
  activities: new Activities(axios),
  growth: new Growth(axios),
  vendors: new Vendors(axios),
  hubspot: new Hubspot(),
  comms: new Comms(axios),
  clauses: new Clauses(axios),
  forms: new Forms(axios),
  envelopes: new Envelopes(axios),
  externalAuth: new ExternalAuth(axios),
  ...codes,
} as const;

export default api;
