
import get from 'lodash/get';
import {
  ARCHIVE,
  DELETE,
  PEAD_CONTINUE,
  PEAD_RESEND_SIGNATURES,
  PEAD_SIGN,
  VIEW_TASK,
} from '../actions/kinds';

const getActionsAndMessage = (task) => {
  const pead = task.members.find((m) => m.kind === 'PEAD') || {
    status: 'NOT_STARTED',
  };
  const getActions = (...actions) => {
    const baseActions = !task.isArchived
      ? [VIEW_TASK].concat(actions || [])
      : [];
    return baseActions.concat(
      [ARCHIVE, task.isArchived ? DELETE : null].filter((a) => Boolean(a))
    );
  };

  return {
    STARTED: {
      message: 'PEADs were started but are not yet completed.',
      actions: getActions(PEAD_CONTINUE),
    },
    READY_TO_GENERATE: {
      message: 'PEADs are ready to be generated.',
      actions: getActions(PEAD_CONTINUE),
    },
    GENERATED: {
      message: 'PEADs were correctly generated.',
      actions: getActions(PEAD_SIGN),
    },
    OUT_FOR_SIGNATURE: {
      message: 'You sent PEADs out for signatures.',
      actions: getActions(PEAD_RESEND_SIGNATURES),
    },
    SIGNED: {
      message: 'PEADs are signed.',
      actions: getActions(),
    },
    UPLOADED_FORMS: {
      message: '',
      actions: [],
    },
  }[get(pead, 'status')];
};

export default {
  actions: (task) => getActionsAndMessage(task).actions,
  spotIcon: () => import('src/images/spot-icons/workflows/spot-pead.svg'),
  getStatusMeta: (task) => {
    const { message, actions } = getActionsAndMessage(task) || {};
    return {
      message,
      actions: (actions || []).filter((a) => a !== VIEW_TASK),
      primaryActions: [],
      viewProps: {
        back: true,
        label: 'Open',
      },
    };
  },
};
