import get from 'lodash/get';
import { computed, makeObservable } from 'mobx';
import { ValueOf } from 'type-fest';
import inboxIcon from 'src/images/spot-icons/documents/inbox-folder.svg';
import defaultFolderIcon from 'src/images/spot-icons/documents/spot-default-folder.svg';
import generalFolderIcon from 'src/images/spot-icons/documents/spot-general-folder.svg';
import trashIcon from 'src/images/spot-icons/documents/spot-trash.svg';
import {
  OFFERS_GUID,
  SELLER_DISCLOSURES_GUID,
} from 'src/models/transactions/apps';
import Item, { ItemStore, ItemJson } from 'src/models/transactions/items/item';
import {
  ItemFolder,
  ItemFolderKind,
  TransactionState,
} from 'src/types/proto/transactions';

export const OPS = {
  VIEW: 'view',
  RENAME: 'rename',
  DELETE: 'delete',
  UPLOAD: 'upload',
};
const ALL_OPS = Object.values(OPS);

type ValueOfOPS = ValueOf<typeof OPS>;

export const TITLE_COLUMN = 'title';

export const CHECKLIST_COLUMN = 'checklists';

export const MODIFIED_COLUMN = 'modified';

export const ACTIONS_COLUMN = 'actions';

export const TRASH_ACTIONS_COLUMN = 'trash-actions';

export const PRIVATE_NOTE_COLUMN = 'private-note';

export const EDIT_ORIGINAL_TD_COLUMN = 'edit-original-td';

export const DEFAULT_FOLDER_COLUMNS = [TITLE_COLUMN, ACTIONS_COLUMN];

export const getFolderColumns = (folder?: Record<string, string[]>) => {
  return folder ? folder.columns : DEFAULT_FOLDER_COLUMNS;
};

export interface FolderJson extends ItemJson<'FOLDER'> {
  folder: ItemFolder;
}

export default class Folder extends Item<ItemStore, FolderJson> {
  operationsByFolderKind = {
    UNKNOWN: ALL_OPS,
    DEFAULT: [OPS.VIEW, OPS.RENAME, OPS.UPLOAD],
    USER_CREATED: ALL_OPS,
    INBOX: [OPS.VIEW],
    TRASH: [],
    PACKET: [OPS.VIEW, OPS.UPLOAD],
    SYNC_TARGET: ALL_OPS,
    ATTACHMENTS: [],
    PACKAGE: [OPS.UPLOAD],
    BUYER_PROSPECT_PROPERTY: [OPS.VIEW, OPS.UPLOAD],
    __default: ALL_OPS,
  };

  // Some folder types access is altered for Glide
  // embedded documents experience.
  @computed
  get operationsByFolderKindEmbeddedOverries() {
    const overrides = {
      TRASH: [OPS.VIEW],
      TEMPLATE: {
        PACKET: [],
        TRASH: [],
      },
    };
    return overrides;
  }

  columnsByFolderKind = {
    INBOX: [TITLE_COLUMN, MODIFIED_COLUMN, ACTIONS_COLUMN],
    TRASH: [TITLE_COLUMN, MODIFIED_COLUMN, ACTIONS_COLUMN],
  };

  appByFolderKind = {
    PACKET: SELLER_DISCLOSURES_GUID,
    PACKAGE: OFFERS_GUID,
  };

  iconsByFolderKind = {
    DEFAULT: generalFolderIcon,
    INBOX: inboxIcon,
    TRASH: trashIcon,
  };

  constructor(store: ItemStore, json: FolderJson) {
    super(store, json);

    makeObservable(this);
  }

  @computed
  get app() {
    const appGuid =
      this.appByFolderKind[this.folderKind as keyof Folder['appByFolderKind']];
    if (!appGuid) {
      return undefined;
    }
    return this.transaction?.apps?.get(appGuid);
  }

  @computed
  get orderIndex() {
    return this.kindItem.orderIndex;
  }

  @computed
  get icon() {
    const app = this.app;
    if (app && app.icon) {
      return app.icon;
    }

    if (get(this.iconsByFolderKind, this.folderKind)) {
      return get(this.iconsByFolderKind, this.folderKind);
    }

    return defaultFolderIcon;
  }

  getAppRoute = (params: Record<string, unknown>) => {
    const app = this.app;
    if (!app) {
      return undefined;
    }

    return app.getRoute(params);
  };

  @computed
  get folderKind() {
    return this.kindItem.folderKind;
  }

  @computed
  get columns() {
    if (get(this.columnsByFolderKind, this.folderKind)) {
      return get(this.columnsByFolderKind, this.folderKind);
    }
    return [TITLE_COLUMN, CHECKLIST_COLUMN, MODIFIED_COLUMN, ACTIONS_COLUMN];
  }

  /**
   * XXX(hliu): for whatever reason, MobX observation system doesn't pick
   * this up if it is a computed property and other computed properties
   * depend on this.
   *
   * Do not turn this into a computed property.
   */
  get documents() {
    return this.documentIds
      .map((dId) => this.store.itemsById.get(dId))
      .filter((x) => x);
  }

  @computed
  get documentIds() {
    return (this.outEdges || [])
      .filter((e) => e.kind === 'FOLDER_HAS_DOCUMENT')
      .map((e) => e.item2Id);
  }

  @computed
  get isEmpty() {
    return !this.documentIds.length;
  }

  @computed
  get documentCount() {
    return this.documentIds.length;
  }

  @computed
  get documentsOrderedByTitle() {
    return this.documents.sort((tDoc1, tDoc2) =>
      tDoc1.title.toLowerCase() <= tDoc2.title.toLowerCase() ? -1 : 1
    );
  }

  can(op: ValueOfOPS) {
    const { operationsByFolderKindEmbeddedOverries, transaction, store } = this;
    type State = TransactionState &
      keyof typeof operationsByFolderKindEmbeddedOverries;
    const embeddedOverrides = store.parent.ui.isEmbedded
      ? operationsByFolderKindEmbeddedOverries[transaction?.state as State] ||
        operationsByFolderKindEmbeddedOverries
      : {};
    let disclosureOverrides = {};

    if (!transaction?.showDisclosures) {
      disclosureOverrides = {
        PACKET: [],
      };
    }
    const operationsByFolderKind = {
      ...this.operationsByFolderKind,
      ...embeddedOverrides,
      ...disclosureOverrides,
    };
    type FolderKind = ItemFolderKind & keyof typeof operationsByFolderKind;
    return (
      (operationsByFolderKind[this.folderKind as FolderKind] ||
        operationsByFolderKind.__default) as string[]
    ).includes(op);
  }

  get canView() {
    return this.can(OPS.VIEW);
  }

  get canRename() {
    return this.can(OPS.RENAME);
  }

  get canUpload() {
    return this.can(OPS.UPLOAD);
  }

  get canDelete() {
    return this.can(OPS.DELETE);
  }

  @computed
  get documentsOrderedByIndex() {
    this.documents.forEach((td) => td.index);
    return this.documents.sort((a, b) => {
      if (a.index === b.index) {
        return a.idNumber > b.idNumber ? -1 : 1;
      }
      return a.index < b.index ? -1 : 1;
    });
  }

  @computed
  get documentsOrderedByUpdatedAt() {
    return this.documents.sort((tDoc1, tDoc2) => {
      return tDoc1.updatedAtNumber >= tDoc2.updatedAtNumber ? -1 : 1;
    });
  }

  @computed
  get documentsOrderedByCreatedAt() {
    return this.documents.sort((tDoc1, tDoc2) => {
      return tDoc1.createdAtNumber >= tDoc2.createdAtNumber ? -1 : 1;
    });
  }

  @computed
  get modifiedAt() {
    return this.kindItem.modifiedAt;
  }

  @computed
  get modifiedAtNumber() {
    return Number(this.modifiedAt);
  }

  @computed
  get modifiedUserId() {
    return this.kindItem.modifiedUserId;
  }

  @computed
  get modifiedUserName() {
    return this.kindItem.modifiedUserName;
  }

  @computed
  get isDefault() {
    return this.folderKind === 'DEFAULT';
  }

  @computed
  get isTrash() {
    return this.folderKind === 'TRASH';
  }

  @computed
  get isInbox() {
    return this.folderKind === 'INBOX';
  }

  @computed
  get isFromTransactionPackage() {
    return this.inEdges.some((e) => e.kind === 'PACKAGE_HAS_FOLDER');
  }

  @computed
  get isFromBuyerProspectProperty() {
    return this.inEdges.some((e) => e.kind === 'BPP_HAS_FOLDER');
  }

  @computed
  get isFromProperty() {
    return this.inEdges.some((e) => e.kind === 'PROPERTY_HAS_FOLDER');
  }

  get isTransactionGeneralFolder() {
    return this.isDefault && this.isTransactionFolder;
  }

  get isTransactionFolder() {
    return (
      !this.isFromProperty &&
      !this.isFromTransactionPackage &&
      !this.isFromBuyerProspectProperty
    );
  }

  get isPropertyGeneralFolder() {
    return this.isDefault && this.isFromProperty;
  }

  get title() {
    return this.data.title;
  }

  get fromPropertyId() {
    const propertyInfoIds = this.inEdgeIdsByKind('PROPERTY_HAS_FOLDER');
    return propertyInfoIds[0] ?? undefined;
  }

  @computed
  get showEmailLabel() {
    return this.isDefault;
  }

  @computed
  get namespaceScopeEdgeKinds() {
    return {
      transactionPackageEdgeKind: 'PACKAGE_HAS_FOLDER',
      buyerProspectPropertyEdgeKind: 'BPP_HAS_FOLDER',
      propertyInfoEdgeKind: 'PROPERTY_HAS_FOLDER',
    };
  }
}
