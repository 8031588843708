import BaseApi from '../base-api';
import URI from 'urijs';
import type { PackageDocuments } from 'src/types/proto/packages';
import type {
  Item as Offer,
  PropertyPortalStatusResponse,
  PublicOfferSummary,
} from 'src/types/proto/transactions';

export default class Packages extends BaseApi {
  getPackagePPStatus = (actionUuid: string) => {
    return this.get<PropertyPortalStatusResponse>(
      `/packages/${actionUuid}/pp_status`
    );
  };

  downloadPackage = (actionUuid: string) => {
    return URI(`/api/packages/${actionUuid}/download`);
  };

  getPackageDocuments = (actionUuid: string) => {
    return this.get<PackageDocuments>(`/packages/${actionUuid}/pds`);
  };

  getPackageOffer = (actionUuid: string) => {
    return this.get<PublicOfferSummary>(`/packages/${actionUuid}/offer`);
  };

  recordPackageClickedLink = (actionUuid: string) => {
    return this.post<void>(`/packages/${actionUuid}/clicked-link/`);
  };

  getPreviousOfferData = (transactionPackageId: string) => {
    // transId should come from the offer.transaction
    return this.get<Offer>(`/packages/${transactionPackageId}/prev_offer/`);
  };
}
