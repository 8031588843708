import sortBy from 'lodash/sortBy';
import { computed, makeObservable } from 'mobx';
import {
  type ItemSharePacketPublication,
  ItemEdgeKind,
} from 'src/types/proto/transactions';
import Item, { ItemJson, ItemStore } from './item';

export type SharePacketPublicationJson =
  ItemJson<'SHARE_PACKET_PUBLICATION'> & {
    sharePacketPublication: ItemSharePacketPublication;
  };

export default class SharePacketPublication extends Item<
  ItemStore,
  SharePacketPublicationJson
> {
  constructor(store: ItemStore, json: SharePacketPublicationJson) {
    super(store, json);

    makeObservable(this);
  }

  get coverSheetUrl() {
    return this.kindItem.coverSheetUrl;
  }

  @computed
  get tdvIds() {
    return sortBy(
      this.outEdges.filter(
        (e) => e.kind === ItemEdgeKind.SHARE_PACKET_PUBLICATION_HAS_TDV
      ),
      (e) => e.data!.index
    ).map((e) => e.item2Id);
  }
}
