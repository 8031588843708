import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import omit from 'lodash/omit';
import type { SnakeCasedProperties, CamelCase } from 'type-fest';
import type { Provider } from 'src/entries/admin/providers/models';
import type { PaginationListResult } from 'src/types/api';
import type {
  AddTeamMembersRequest,
  TeamMembership,
  User,
} from 'src/types/proto/auth';
import type { AssociationMembership } from 'src/types/proto/brokers';
import type { Contact } from 'src/types/proto/contacts';
import type { MlsMembership } from 'src/types/proto/integrations';
import type { InviteRequest } from 'src/types/proto/invites';
import type {
  Org,
  OrgInviteComplete,
  OrgRequest,
  Office,
  OfficeRequest,
  Group,
  GroupRequest,
} from 'src/types/proto/orgs';
import type {
  ChangeAccountStatusRequest,
  CheckOrgMembershipResponse,
  GetTeamMembersRequest,
  ToggleTmsRequest,
  GetOfficesResponse,
  OrgQuery,
} from 'src/types/proto/services/org_public_service';
import type {
  GetOrganizationsRequest,
  GetOrganizationsResponse,
  GetOwnOrganizationsResponse,
  GetRecommendOrganizationsRequest,
  GetRecommendOrganizationsResponse,
} from 'src/types/proto/services/organization_public_service';
import type {
  VerificationRequest,
  VerificationRequestOrgKind as OrgKind,
} from 'src/types/proto/verifications';
import type {
  ApiRequestBody,
  ApiResponseData,
  DeepPartial,
} from 'src/types/utils';
import { formatToSnakeCase } from 'src/utils/format-data-object';
import BaseApi from '../base-api';

export type UnifiedMembership = AssociationMembership | MlsMembership;

export type OrganizationWithSnakeCaseOrgKind = Provider & {
  // eslint-disable-next-line camelcase
  org_kind: OrgKind;
};

export type VerificationRequestWithSnakeCaseOrgKind = Omit<
  VerificationRequest,
  'orgKind'
> & {
  // eslint-disable-next-line camelcase
  org_kind: OrgKind;
};
type PartialVerifyParams = Partial<
  VerificationRequest[Exclude<
    CamelCase<Lowercase<VerificationRequest['kind']>>,
    'sso'
  >]
>;

export default class Orgs extends BaseApi {
  // Teams
  createTeamSetupFlow(userId: string) {
    return this.post<string>(`/teams/${userId}/create_setup_flow`);
  }
  inviteTeamMembers(args: DeepPartial<AddTeamMembersRequest>) {
    return this.post<TeamMembership>('/teams/invite_team_members', args);
  }
  requestMembershipIntoAgentTeam(contact: Contact) {
    return this.post<InviteRequest | null>(
      '/teams/team_membership_requests',
      contact
    );
  }
  listAgentTeamMembershipRequests() {
    return this.get<InviteRequest[]>('/teams/team_membership_requests');
  }
  removeTeamMember(membershipId: string) {
    return this.post<void>('/teams/remove_team_member', {
      membershipId,
    });
  }
  listMembers(teamId: string, params: Omit<GetTeamMembersRequest, 'teamId'>) {
    return this.get<PaginationListResult<TeamMembership>>(
      `/teams/${teamId}/members`,
      {
        params,
      }
    );
  }
  fetchMember(teamId: string, memberId: string) {
    return this.get<TeamMembership>(`/teams/${teamId}/members/${memberId}`);
  }

  removeSelfFromTeam(teamId: string) {
    return this.post<void>(`/teams/${teamId}/remove_self`);
  }

  // orgs
  fetchOrg(orgId: string) {
    return this.get<Org>(`/orgs/${orgId}`);
  }
  saveOrg(orgId: string, data: DeepPartial<OrgRequest>) {
    return this.post<Org>(`/orgs/${orgId}`, data);
  }
  changeMemberAccountStatus(
    orgId: string,
    memberId: string,
    params: ApiRequestBody<ChangeAccountStatusRequest>
  ) {
    return this.post<TeamMembership>(
      `/orgs/${orgId}/members/${memberId}/change_account_status`,
      params
    );
  }
  removeMemberAccount(orgId: string, memberId: string) {
    return this.post<void>(`/orgs/${orgId}/members/${memberId}/remove_account`);
  }
  disableMemberAccount(orgId: string, memberId: string) {
    return this.post<TeamMembership | Record<string, never>>(
      `/orgs/${orgId}/members/${memberId}/disabled_account`
    );
  }
  enableMemberAccount(orgId: string, memberId: string) {
    return this.post<TeamMembership>(
      `/orgs/${orgId}/members/${memberId}/enable_account`
    );
  }
  saveOrgMember(orgId: string, memberId: string, data: DeepPartial<User>) {
    return this.post<TeamMembership>(
      `/orgs/${orgId}/members/${memberId}`,
      data
    );
  }
  inviteMember(orgId: string, member: DeepPartial<AddTeamMembersRequest>) {
    return this.post<TeamMembership[]>(`/orgs/${orgId}/invite_member`, member);
  }
  inviteComplete(orgId: string, data: DeepPartial<OrgInviteComplete>) {
    return this.post<void>(`/orgs/${orgId}/invite_complete`, data);
  }
  resendMemberInviteEmail(orgId: string, memberId: string) {
    return this.post<void>(
      `/orgs/${orgId}/resend_member_invite_email/${memberId}`
    );
  }
  checkOrgMembership(orgId: string, email: string) {
    return this.get<CheckOrgMembershipResponse>(
      `/orgs/${orgId}/check_org_membership/${email}`
    );
  }
  // TODO deprecate after TMS launch
  toggleMemberTms(orgId: string, memberId: string, enable = true) {
    return this.post<TeamMembership>(
      `/orgs/${orgId}/members/${memberId}/toggle_tms`,
      {
        enable,
      } as ApiRequestBody<ToggleTmsRequest>
    );
  }

  // groups
  listGroups(args: OrgQuery) {
    const params = { includeMembersCounts: true, ...omit(args, ['orgId']) };
    return this.get<Group[]>(`/orgs/${args.orgId}/groups`, {
      params: formatToSnakeCase(params),
    });
  }
  createGroup(orgId: string, data: DeepPartial<GroupRequest>) {
    return this.post<Group>(`/orgs/${orgId}/groups`, data);
  }
  saveGroup(orgId: string, groupId: string, data: DeepPartial<GroupRequest>) {
    return this.post<Group>(`/orgs/${orgId}/groups/${groupId}`, data);
  }
  deleteGroup(orgId: string, groupId: string) {
    return this.post<void>(`/orgs/${orgId}/groups/${groupId}/delete`);
  }

  // offices
  listOffices(args: OrgQuery) {
    const params = { includeMembersCounts: true, ...omit(args, ['orgId']) };
    return this.get<ApiResponseData<GetOfficesResponse>>(
      `/orgs/${args.orgId}/offices`,
      { params: formatToSnakeCase(params) }
    );
  }
  createOffice(orgId: string, data: DeepPartial<OfficeRequest>) {
    return this.post<Office>(`/orgs/${orgId}/offices`, data);
  }
  saveOffice(
    orgId: string,
    officeId: string,
    data: DeepPartial<OfficeRequest>
  ) {
    return this.post<Office>(`/orgs/${orgId}/offices/${officeId}`, data);
  }
  deleteOffice(orgId: string, officeId: string) {
    return this.post<void>(`/orgs/${orgId}/offices/${officeId}/delete`);
  }

  listOwnOrganizations() {
    return this.get<SnakeCasedProperties<GetOwnOrganizationsResponse>>(
      '/organizations/me'
    );
  }

  recommendOrganizations(args: GetRecommendOrganizationsRequest) {
    const params = omitBy(args, isNil);
    return this.get<
      SnakeCasedProperties<Partial<GetRecommendOrganizationsResponse>>
    >('/organizations/recommendations', { params: formatToSnakeCase(params) });
  }

  listOrganizations(args: GetOrganizationsRequest) {
    const params = omitBy(args, isNil);

    return this.get<SnakeCasedProperties<Partial<GetOrganizationsResponse>>>(
      '/organizations',
      {
        params: formatToSnakeCase(params),
      }
    );
  }

  verifyNrdsId(
    org: OrganizationWithSnakeCaseOrgKind,
    params: PartialVerifyParams = {}
  ) {
    const kind = org.verificationMethod?.kind;

    const payload: VerificationRequestWithSnakeCaseOrgKind = {
      kind: (kind?.toUpperCase() || '') as VerificationRequest['kind'],
      org_kind: org.org_kind,
      [kind?.toLowerCase() || '']: {
        orgId: org.id,
        ...params,
      },
    };

    return this.post<AssociationMembership[] | MlsMembership[]>(
      '/organizations/verify',
      payload
    );
  }

  unlinkAssociation(org: OrganizationWithSnakeCaseOrgKind) {
    const kind = org.verificationMethod?.kind || '';

    const payload: VerificationRequestWithSnakeCaseOrgKind = {
      kind: kind.toUpperCase() as VerificationRequest['kind'],
      org_kind: org.org_kind,
      [kind.toLowerCase()]: {
        orgId: org.id,
      },
    };

    return this.post<void>('/organizations/unlink', payload);
  }
}
