

import React from 'react';
import { IdcardOutlined } from '@ant-design/icons';
import { Popover, Tag, Tooltip } from 'antd';
import classNames from 'classnames';
import { formatNumber, isValidNumber } from 'libphonenumber-js';
import isString from 'lodash/isString';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import ReactUserAvatar from 'react-user-avatar';
import AppIcon from 'src/components/common/app-icon';
import ClipboardLink from 'src/components/common/clipboard-link';
import { getColor } from 'src/utils/get-color';
import getFullNameOrEmail from 'src/utils/get-full-name-or-email';

const clsPrefix = 'app-contact-avatar';

const COLORS = [
  getColor('user-avatar-bg-color1'),
  getColor('user-avatar-bg-color2'),
  getColor('user-avatar-bg-color3'),
  getColor('user-avatar-bg-color4'),
  getColor('user-avatar-bg-color5'),
  getColor('user-avatar-bg-color6'),
];

const GHOST_BG_COLOR = getColor('light-gray-color');

const GHOST_COLOR = getColor('gray-12');

const AVATAR_WRAP_STYLE = {
  display: 'inline-flex',
  alignItems: 'center',
  textOverflow: 'ellipsis',
};

const AVATAR_STYLE = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: getColor('gray-12'),
  textTransform: 'uppercase',
  fontWeight: 600,
};

const AVATAR_GHOST_STYLE = {
  color: GHOST_COLOR,
};

export const DEFAULT_SIZE = 40;

function renderTag(tag) {
  if (isString(tag)) {
    return (
      <Tag key={tag} className="no-animation">
        {tag}
      </Tag>
    );
  }
  return tag;
}

function ContactAvatar(props) {
  const {
    className,
    contact,
    showName,
    style,
    wrapStyle,
    ghost,
    subtitle,
    showEmail,
    showPhone,
    tags,
    onClick,
    tooltip,
    showAvatar,
    avatar,
    showContactPopover,
    size,
    fontSize,
    emptyNamePlaceholder,
    emptyEmailPlaceholder,
    placeholderAvatar,
    loading,
    extra,
    noMargin,
    ...nextProps
  } = props;

  const fullName =
    getFullNameOrEmail(contact) ||
    (emptyNamePlaceholder !== undefined ? emptyNamePlaceholder : '<No Name>');
  delete nextProps.user;
  const shortenAvatarName = fullName.split(' ').slice(0, 3).join(' ');

  const fontSizeObj = {
    22: '10px',
    32: '12px',
    40: '16px',
  };

  /* eslint-disable */
  const myAvatarStyle = Object.assign(
    {
      // fontSize: `${16 * (+size / DEFAULT_SIZE)}px`,
      fontSize: fontSizeObj[+size] || `${16 * (+size / DEFAULT_SIZE)}px`,
    },
    AVATAR_STYLE,
    ghost ? AVATAR_GHOST_STYLE : {},
    style
  );
  const myWrapStyle = Object.assign({}, AVATAR_WRAP_STYLE, wrapStyle);
  /* eslint-enable */

  let subtitleEl;

  if (subtitle) {
    subtitleEl = subtitle;
  } else {
    const subtitleParts = [];

    if (showPhone && contact.cellPhone) {
      if (isValidNumber(contact.cellPhone)) {
        subtitleParts.push(
          formatNumber(
            {
              country: 'US',
              phone: contact.cellPhone,
            },
            'International'
          )
        );
      } else {
        subtitleParts.push(contact.cellPhone);
      }
    }

    if (
      showEmail &&
      (contact.email || emptyEmailPlaceholder) &&
      !contact.isDummyEmail &&
      (!showName || fullName !== contact.email)
    ) {
      subtitleParts.push(contact.email || emptyEmailPlaceholder);
    }

    if (subtitleParts.length) {
      subtitleEl = (
        <div>
          {subtitleParts.map((p) => (
            <div key={p}>{p}</div>
          ))}
        </div>
      );
    }
  }

  const avatarSrc =
    (contact.firstName ? contact.avatarUrlSm : placeholderAvatar) ||
    contact.avatarUrlSm;

  return (
    <div
      className={classNames(clsPrefix, className)}
      style={myWrapStyle}
      {...(onClick
        ? {
            onClick,
            role: 'button',
          }
        : null)}
    >
      {showAvatar ? (
        <Tooltip
          title={tooltip === undefined ? fullName : tooltip}
          arrowPointAtCenter
          mouseLeaveDelay={0}
        >
          <div className={`${clsPrefix}__avatar-container`}>
            {avatar || (
              <ReactUserAvatar
                className={classNames(`${clsPrefix}__avatar`, {
                  [`${clsPrefix}__avatar--loading`]: Boolean(loading),
                })}
                style={myAvatarStyle}
                colors={ghost ? [GHOST_BG_COLOR] : COLORS}
                name={shortenAvatarName}
                src={avatarSrc}
                size={size}
                {...nextProps}
              />
            )}
            {loading && (
              <div className={`${clsPrefix}__loading-container`}>
                <AppIcon
                  className={`${clsPrefix}__loading-icon`}
                  type="antd"
                  name="loading"
                />
              </div>
            )}
          </div>
        </Tooltip>
      ) : null}
      {showName || subtitleEl ? (
        <div
          className={classNames(`${clsPrefix}__label-wrap`, {
            [`${clsPrefix}__label-wrap--no-margin`]: noMargin,
          })}
        >
          {showName && (
            <div
              className={`${clsPrefix}__name-label`}
              style={{
                fontSize,
              }}
            >
              {fullName}
              {tags ? (
                <span className={`${clsPrefix}__tags`}>
                  {tags.map(renderTag)}
                </span>
              ) : null}
            </div>
          )}
          {subtitleEl && (
            <div className={`${clsPrefix}__subtitle-label`}>{subtitleEl}</div>
          )}
          {extra &&
            extra.map((t) => <div className={`${clsPrefix}__extra`}>{t}</div>)}
        </div>
      ) : null}
      {contact.email && !contact.isDummyEmail && showContactPopover ? (
        <Popover
          title={null}
          content={
            <div className={`${clsPrefix}__contact-popover`}>
              {contact.cellPhone ? (
                <div>
                  {formatNumber(
                    {
                      country: 'US',
                      phone: contact.cellPhone,
                    },
                    'International'
                  )}
                </div>
              ) : null}
              <div>{contact.email}</div>
              <div className={`${clsPrefix}__copy-email`}>
                Click to copy email
              </div>
            </div>
          }
        >
          <div className={`${clsPrefix}__contact-icon`}>
            <ClipboardLink type="gray" text={contact.email} description="email">
              <IdcardOutlined />
            </ClipboardLink>
          </div>
        </Popover>
      ) : null}
    </div>
  );
}

ContactAvatar.propTypes = {
  className: PropTypes.string,
  contact: PropTypes.object.isRequired,
  showName: PropTypes.bool,
  size: PropTypes.number,
  style: PropTypes.object,
  wrapStyle: PropTypes.object,
  ghost: PropTypes.bool,
  subtitle: PropTypes.any,
  showEmail: PropTypes.bool,
  showPhone: PropTypes.bool,
  tags: PropTypes.array,
  extra: PropTypes.array,
  onClick: PropTypes.func,
  tooltip: PropTypes.any,
  showAvatar: PropTypes.bool,
  avatar: PropTypes.any,
  showContactPopover: PropTypes.bool,
  fontSize: PropTypes.string,
  emptyNamePlaceholder: PropTypes.string,
  emptyEmailPlaceholder: PropTypes.string,
  placeholderAvatar: PropTypes.string,
  loading: PropTypes.bool,
};

ContactAvatar.defaultProps = {
  size: DEFAULT_SIZE,
  showName: false,
  showEmail: false,
  showAvatar: true,
  fontSize: '1em',
};

export default observer(ContactAvatar);
