import difference from 'lodash/difference';
import intersection from 'lodash/intersection';
import omit from 'lodash/omit';
import uniq from 'lodash/uniq';
import type { Transaction } from './transaction';

const allRoles: string[] = [];

function defineRole(role: string) {
  allRoles.push(role);
  return role;
}

export const SELLER = defineRole('SELLER');
export const SELLER2 = defineRole('SELLER2');
export const SELLER3 = defineRole('SELLER3');
export const SELLER4 = defineRole('SELLER4');

export const BUYER = defineRole('BUYER');
export const BUYER2 = defineRole('BUYER2');
export const BUYER3 = defineRole('BUYER3');
export const BUYER4 = defineRole('BUYER4');

export const LISTING_AGENT = defineRole('LISTING_AGENT');
export const LISTING_AGENT2 = defineRole('LISTING_AGENT2');
export const BUYER_AGENT = defineRole('BUYER_AGENT');
export const BUYER_AGENT2 = defineRole('BUYER_AGENT2');
export const LISTING_TC = defineRole('LISTING_TC');
export const LISTING_TEAM = defineRole('LISTING_TEAM');
export const BUYER_TC = defineRole('BUYER_TC');
export const BUYER_TEAM = defineRole('BUYER_TEAM');
export const LISTING_BROKER = defineRole('LISTING_BROKER');
export const BUYER_BROKER = defineRole('BUYER_BROKER');
export const TITLE_AGENT = defineRole('TITLE_AGENT');
export const ESCROW_AGENT = defineRole('ESCROW_AGENT');
export const LOAN_OFFICER = defineRole('LOAN_OFFICER');
export const LOAN_PROCESSOR = defineRole('LOAN_PROCESSOR');
export const APP_ADMIN = defineRole('APP_ADMIN'); // Deprecated
export const ASSOCIATE_LISTING_AGENT = defineRole('ASSOCIATE_LISTING_AGENT');
export const ASSOCIATE_BUYER_AGENT = defineRole('ASSOCIATE_BUYER_AGENT');

export const FILE_REVIEWER = defineRole('FILE_REVIEWER');

export const OTHER = defineRole('OTHER');
export const LISTING_TC_AND_TEAM = [LISTING_TC, LISTING_TEAM];
export const BUYER_TC_AND_TEAM = [BUYER_TC, BUYER_TEAM];

export const SELLER_ROLES = [SELLER, SELLER2, SELLER3, SELLER4];
export const SECONDARY_SELLER_ROLES = SELLER_ROLES.filter((r) => r !== SELLER);

export const BUYER_ROLES = [BUYER, BUYER2, BUYER3, BUYER4];

export const LISTING_AGENT_ROLES = [
  LISTING_AGENT,
  LISTING_AGENT2,
  ASSOCIATE_LISTING_AGENT,
];

export const LISTING_TEAM_ROLES = [
  ...LISTING_AGENT_ROLES,
  ...LISTING_TC_AND_TEAM,
  LISTING_BROKER,
  FILE_REVIEWER,
];

export const BUYER_AGENT_ROLES = [
  BUYER_AGENT,
  BUYER_AGENT2,
  ASSOCIATE_BUYER_AGENT,
];

export const BUYER_TEAM_ROLES = [
  ...BUYER_AGENT_ROLES,
  ...BUYER_TC_AND_TEAM,
  BUYER_BROKER,
  FILE_REVIEWER,
];

export const BUYER_TEAM_ROLES_FOR_TXMP_PARTY_MODAL = [
  BUYER_AGENT,
  BUYER_AGENT2,
  BUYER_BROKER,
  BUYER_TEAM,
  BUYER_TC,
];

export const LISTING_TEAM_ROLES_FOR_TXMP_PARTY_MODAL = [
  LISTING_AGENT,
  LISTING_AGENT2,
  LISTING_BROKER,
  LISTING_TEAM,
  LISTING_TC,
];

export const LISTING_SIDE_ROLES = [...SELLER_ROLES, ...LISTING_TEAM_ROLES];

export const LISTING_SIDE_ROLES_FOR_TXMP_PARTY_MODAL = [
  ...SELLER_ROLES,
  ...LISTING_TEAM_ROLES_FOR_TXMP_PARTY_MODAL,
];

export const BUYER_SIDE_ROLES = [...BUYER_ROLES, ...BUYER_TEAM_ROLES];

export const BUYER_SIDE_ROLES_FOR_TXMP_PARTY_MODAL = [
  ...BUYER_ROLES,
  ...BUYER_TEAM_ROLES_FOR_TXMP_PARTY_MODAL,
];

export const PRIMARY_AGENT_ROLES = [LISTING_AGENT, BUYER_AGENT];

export const NO_SIDE_ROLES = [
  TITLE_AGENT,
  ESCROW_AGENT,
  LOAN_OFFICER,
  LOAN_PROCESSOR,
];

export const VENDOR_ROLES = NO_SIDE_ROLES;

export const THIS_SIDE_ONLY_ROLES = [FILE_REVIEWER];

export const THIS_SIDE_ONLY_ROLES_FOR_TXMP_PARTY_MODAL = [
  BUYER_TC,
  LISTING_TC,
  BUYER_TEAM,
  LISTING_TEAM,
];

export const LABEL_BY_ROLES = {
  [SELLER]: 'Seller',
  [SELLER2]: 'Seller 2',
  [SELLER3]: 'Seller 3',
  [SELLER4]: 'Seller 4',
  [BUYER]: 'Buyer',
  [BUYER2]: 'Buyer 2',
  [BUYER3]: 'Buyer 3',
  [BUYER4]: 'Buyer 4',
  [LISTING_AGENT]: 'Listing Agent',
  [LISTING_AGENT2]: 'Listing Agent 2',
  [LISTING_TC]: 'Listing TC',
  [LISTING_TEAM]: 'Listing Team',
  [LISTING_BROKER]: 'Listing Broker',
  [BUYER_AGENT]: 'Buyer Agent',
  [BUYER_AGENT2]: 'Buyer Agent 2',
  [BUYER_TC]: 'Buyer TC',
  [BUYER_TEAM]: 'Buyer Team',
  [BUYER_BROKER]: 'Buyer Broker',
  [TITLE_AGENT]: 'Title Agent',
  [ESCROW_AGENT]: 'Escrow Agent',
  [LOAN_OFFICER]: 'Loan Officer',
  [LOAN_PROCESSOR]: 'Loan Processor',
  [APP_ADMIN]: 'App Admin',
  [ASSOCIATE_LISTING_AGENT]: 'Associate Listing Agent',
  [ASSOCIATE_BUYER_AGENT]: 'Associate Buyer Agent',

  [FILE_REVIEWER]: 'File Reviewer',
  [OTHER]: 'Other',
};

export const LEASE_LABEL_BY_ROLES = {
  ...LABEL_BY_ROLES,
  [SELLER]: 'Landlord',
  [SELLER2]: 'Landlord 2',
  [SELLER3]: 'Landlord 3',
  [SELLER4]: 'Landlord 4',
  [BUYER]: 'Tenant',
  [BUYER2]: 'Tenant 2',
  [BUYER3]: 'Tenant 3',
  [BUYER4]: 'Tenant 4',
  [BUYER_AGENT]: 'Leasing Agent',
  [BUYER_AGENT2]: 'Leasing Agent 2',
  [BUYER_TC]: 'Leasing Team / TC',
  [BUYER_TEAM]: 'Leasing Team',
  [BUYER_BROKER]: 'Landlord Broker',
  [ASSOCIATE_BUYER_AGENT]: 'Associate Leasing Agent',
};

export const NON_UNIQUE_ROLES = [
  ...LISTING_TC_AND_TEAM,
  ...BUYER_TC_AND_TEAM,
  FILE_REVIEWER,
  OTHER,
];

export const ORDERED_ROLE_SETS = [
  BUYER_ROLES,
  SELLER_ROLES,
  [LISTING_AGENT, LISTING_AGENT2],
  [BUYER_AGENT, BUYER_AGENT2],
];

export const DEPRECATED_ROLES = [APP_ADMIN];

export const ALL_ROLES = difference(allRoles, DEPRECATED_ROLES);

export const CLIENT_ROLES = [...BUYER_ROLES, ...SELLER_ROLES];

export const TC_ROLES = [LISTING_TC, BUYER_TC];
export const TEAM_ROLES = [LISTING_TEAM, BUYER_TEAM];
export const TC_AND_TEAM_ROLES = [...TC_ROLES, ...TEAM_ROLES];

export const AGENT_ROLES = [...LISTING_AGENT_ROLES, ...BUYER_AGENT_ROLES];

export const BROKER_ROLES = [LISTING_BROKER, BUYER_BROKER];

export const TMS_ONLY_ROLES = [FILE_REVIEWER];

export const AGENT_BROKER_TEAM_ROLES = [...AGENT_ROLES, ...BROKER_ROLES];

export const CONTACT_ONLY_ROLES = [OTHER];

export const LABEL_BY_ROLE_HINTS = {
  CLIENT: 'Client',
  AGENT: 'Agent',
  BROKER: 'Broker',
  TC: 'TC / Assistant',
  ...omit(LABEL_BY_ROLES, [
    ...DEPRECATED_ROLES,
    ...AGENT_ROLES,
    ...CLIENT_ROLES,
    ...BROKER_ROLES,
    ...TC_AND_TEAM_ROLES,
  ]),
  OTHER: 'Other',
};

export function mapRoleToRoleHint(role: string) {
  if (AGENT_ROLES.includes(role)) {
    return 'AGENT';
  }
  if (CLIENT_ROLES.includes(role)) {
    return 'CLIENT';
  }
  if (BROKER_ROLES.includes(role)) {
    return 'BROKER';
  }
  if (TC_ROLES.includes(role)) {
    return 'TC';
  }
  if (TEAM_ROLES.includes(role)) {
    return 'TEAM';
  }
  return role;
}

export function areRolesCompatible(roles: string[]) {
  const uniqueRoles = uniq(roles);
  return !ORDERED_ROLE_SETS.some(
    (orderedRoleSet) => intersection(orderedRoleSet, uniqueRoles).length > 1
  );
}

export const TC_ROLE_LABEL_FOR_TXMP_PARTY_MODAL = 'Transaction Coordinator';

export function getRoleLabelsForTransaction(
  transaction?: Transaction,
  tmPartyModalFlag = false
) {
  if (tmPartyModalFlag && transaction?.isCompassTransaction) {
    return transaction.isLease
      ? {
          ...LEASE_LABEL_BY_ROLES,
          LISTING_TC: TC_ROLE_LABEL_FOR_TXMP_PARTY_MODAL,
          BUYER_TC: TC_ROLE_LABEL_FOR_TXMP_PARTY_MODAL,
        }
      : {
          ...LABEL_BY_ROLES,
          LISTING_TC: TC_ROLE_LABEL_FOR_TXMP_PARTY_MODAL,
          BUYER_TC: TC_ROLE_LABEL_FOR_TXMP_PARTY_MODAL,
        };
  }

  if (transaction?.isLease) {
    return LEASE_LABEL_BY_ROLES;
  }

  return LABEL_BY_ROLES;
}

export const updateRoleTitle = async () => {
  let index = BUYER_SIDE_ROLES.indexOf(BUYER_TEAM);
  BUYER_SIDE_ROLES.splice(index, 1);
  index = LISTING_SIDE_ROLES.indexOf(LISTING_TEAM);
  LISTING_SIDE_ROLES.splice(index, 1);
  LABEL_BY_ROLES[LISTING_TC] = 'Listing Team / TC';
  LABEL_BY_ROLES[BUYER_TC] = 'Buyer Team / TC';
  LEASE_LABEL_BY_ROLES[BUYER_TC] = 'Leasing Team / TC';
};
