import camelCase from 'lodash/camelCase';
import { CamelCase } from 'type-fest';
import type { SmartRecommendationConfig } from 'src/types/proto/transactions';
import { getColor } from 'src/utils/get-color';
import TaskTypeBase from './task-type-base';

type SmartRecommendationConfigMatchParams = {
  formTags?: string[];
  [key: string]: unknown;
};
const smartRecommendationKindHandlers = {
  SINGLE_TAG: (
    kindConfig: SmartRecommendationConfig['singleTag'],
    { formTags }: SmartRecommendationConfigMatchParams
  ) => (formTags || []).includes(kindConfig?.tag as string),
};

function smartRecommendationConfigMatches(
  config: SmartRecommendationConfig,
  params: SmartRecommendationConfigMatchParams
) {
  const handler =
    smartRecommendationKindHandlers[
      config.kind as keyof typeof smartRecommendationKindHandlers
    ];
  return (
    !!handler &&
    handler(
      config[
        camelCase(config.kind) as CamelCase<
          Extract<SmartRecommendationConfig['kind'], 'SINGLE_TAG'>
        >
      ],
      params
    )
  );
}

export default class checklistitem extends TaskTypeBase {
  get required() {
    return this.typeItem.required;
  }

  get isRequired() {
    return this.required === 'REQUIRED';
  }

  get requiredColor() {
    return this.isRequired ? '#e53d41' : getColor('gray-color');
  }

  get description() {
    return this.typeItem.description;
  }

  get isExempt() {
    return Boolean(this.typeItem.isExempt);
  }

  get relatedSeriesIds() {
    return this.typeItem.relatedSeriesIds || [];
  }

  get srConfigs() {
    return this.typeItem.srConfigs || [];
  }

  get appliedChecklistIds() {
    return this.typeItem.appliedChecklistIds || [];
  }

  get message() {
    return this.typeItem.message;
  }

  get label() {
    return this.typeItem.label;
  }

  get lastActivityId() {
    return this.typeItem.lastActivityId;
  }

  get tdAssignmentsChangedAt() {
    return this.typeItem.tdAssignmentsChangedAt;
  }

  get reviewStatus() {
    return this.typeItem.reviewStatus;
  }

  shouldSmartRecommend = (params: SmartRecommendationConfigMatchParams) => {
    return this.srConfigs.some((config) =>
      smartRecommendationConfigMatches(config, params)
    );
  };
}
