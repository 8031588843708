import { computed, makeObservable } from 'mobx';
import { ItemKind, ItemEdgeKind } from 'src/types/proto/transactions';
import {
  formatFirstLine,
  formatFullAddress,
  formatSecondLine,
} from 'src/utils/addresses';
import Item, { type ItemJson, type ItemStore } from './item';

const { BUYER_PROSPECT_PROPERTY_NAMESPACE } = window.Glide.CONSTANTS;

export type BuyerProspectPropertyJson = ItemJson<'BUYER_PROSPECT_PROPERTY'>;

export default class BuyerProspectProperty extends Item<
  ItemStore,
  BuyerProspectPropertyJson
> {
  constructor(store: ItemStore, json: BuyerProspectPropertyJson) {
    super(store, json);

    makeObservable(this);
  }

  @computed
  get namespace() {
    return BUYER_PROSPECT_PROPERTY_NAMESPACE;
  }

  @computed
  get hasAddress() {
    return Boolean(this.address?.id);
  }

  getFieldIsUnlinked() {
    return false;
  }

  @computed
  get listingAgent() {
    return this.toJS().buyerProspectProperty!.listingAgent;
  }

  @computed
  get images() {
    return this.toJS().buyerProspectProperty!.propertyInfo!.images;
  }

  @computed
  get address() {
    return this.toJS().buyerProspectProperty!.propertyInfo!.address;
  }

  @computed
  get shortAddress() {
    return this.firstAddressLine;
  }

  @computed
  get firstAddressLine() {
    return formatFirstLine(this.address);
  }

  @computed
  get secondAddressLine() {
    return formatSecondLine(this.address);
  }

  @computed
  get fullAddress() {
    return formatFullAddress(this.address);
  }

  @computed
  get folderIds() {
    return this.outEdges
      .filter((e) => e.kind === ItemEdgeKind.BPP_HAS_FOLDER)
      .map((e) => e.item2Id);
  }

  @computed
  get folder() {
    return this.folderIds.length
      ? this.store.itemsById.get(this.folderIds[0])
      : null;
  }

  @computed
  get tdIds() {
    return this.folder ? this.folder.documentIds : [];
  }

  @computed
  get peadIds() {
    return this.toJS().buyerProspectProperty!.peadIds || [];
  }

  @computed
  get partyIds() {
    return this.outEdges
      .filter((e) => e.kind === ItemEdgeKind.BPP_HAS_PARTY)
      .map((e) => e.item2Id);
  }

  getOrFetchParties = async () => {
    return this.store.getOrFetchItemMulti(
      this.transaction.id,
      ItemKind.PARTY,
      this.partyIds
    );
  };

  @computed
  get parties() {
    return this.partyIds
      .map((partyId) => this.store.itemsById.get(partyId))
      .filter(Boolean);
  }
}
