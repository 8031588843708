import isArray from 'lodash/isArray';
import mergeWith from 'lodash/mergeWith';
import { action, makeObservable, observable, toJS } from 'mobx';

export type ModelJson = object;

export default abstract class Model<T extends ModelJson> {
  @observable private _data = {} as unknown as T;
  @observable private _staged: T | undefined = undefined;
  @observable _stageVers = 0;

  constructor(json?: T) {
    makeObservable(this);
    if (json) {
      this.data = json;
      this.updateFromJson(json);
    }
  }

  get data() {
    if (this._staged) {
      return this._staged;
    }
    return this._data;
  }

  set data(value) {
    this._data = value;
  }

  @action
  updateFromJson(json: T) {
    this.data = json;
  }

  @action
  stage(data = this.data) {
    const staged = {
      ...toJS(this.data),
    };

    mergeWith(staged, data, (objValue, srcValue) => {
      if (isArray(srcValue)) {
        return srcValue.slice();
      }
      if (isArray(objValue)) {
        return srcValue;
      }
      return undefined;
    });

    this._staged = staged;
    this._stageVers += 1;
    const myStageVers = this._stageVers;

    return () => {
      if (this._stageVers !== myStageVers) {
        return;
      }
      this.unstage();
    };
  }

  @action
  unstage() {
    this._staged = undefined;
  }

  toJS = () => {
    return toJS(this.data);
  };
}
