import axios from 'axios';

export interface HubspotContact {
  firstName: string;
  lastName: string;
  email: string;
}

export type HubspotFormContext = Record<string, string>;

class Hubspot {
  REFERRAL_FORM_URL =
    'https://api.hsforms.com/submissions/v3/integration/submit/6895008/af7b8ff3-3140-4aaa-934d-f05227d8582b';

  // Reference: https://legacydocs.hubspot.com/docs/methods/forms/submit_form
  submitReferralValues(
    values: HubspotContact,
    context: HubspotFormContext,
    senderEmailAddress: string
  ) {
    const { firstName, lastName, email } = values;
    const data = {
      submittedAt: Date.now(),
      fields: [
        {
          name: 'firstname',
          value: firstName,
        },
        {
          name: 'lastname',
          value: lastName,
        },
        {
          name: 'email',
          value: email,
        },
        {
          name: 'sender_email_address',
          value: senderEmailAddress,
        },
      ],
      context,
    };
    return axios.post(this.REFERRAL_FORM_URL, data);
  }
}

export default Hubspot;
