import PropTypes from 'prop-types';
import React, { Component } from 'react';
import TaskAction from './action';
import { inject, observer } from 'mobx-react';

export const viewDocusign = (item, embedded) => {
  let signatureRequest;
  if (item.kind === 'SIGNATURE_REQUEST') {
    signatureRequest = item;
  } else {
    const { task } = item;
    signatureRequest = task.members.find((i) => i.kind === 'SIGNATURE_REQUEST');
  }
  const isEmbedded = !embedded && item.ui ? item.ui.isEmbedded : embedded;
  let sessionToken = '';
  if (isEmbedded) {
    sessionToken = window.Glide?.authBearer ?? '';
  };
  const viewType = signatureRequest.status === 'DRAFT' ? 'sender' : 'console';
  const next = `/static/docusign_close_popup.html?transactionId=${
    signatureRequest.transactionId
  }`;
  window.open(
    '/integrations/docusign/envelopes/' +
      `${signatureRequest.envelopeId}/view/?sub=${
        signatureRequest.dsSub
      }&next=${encodeURIComponent(next)}&type=${
        viewType
      }&embeddedSessionToken=${sessionToken}&embedded=${isEmbedded}`
  );
};

@inject('ui')
@observer
export default class ViewDocusign extends Component {
  static propTypes = {
    task: PropTypes.object,
    transactions: PropTypes.object.isRequired,
  };

  handleClick = () => {
    const { task, ui } = this.props;
    return viewDocusign({
      task,
    }, ui.isEmbedded);
  };

  render() {
    return (
      <TaskAction {...this.props} onClick={this.handleClick}>
        View in DocuSign
      </TaskAction>
    );
  }
}
