import get from 'lodash/get';
import omit from 'lodash/omit';
import startCase from 'lodash/startCase';

const getTxnFetchOptions = (state) => ({
  force: Boolean(get(state, 'params.refetch')),
});

const getRedirectTo = (routeName) => {
  return (store, state) => {
    return {
      ...state,
      name: routeName,
    };
  };
};

const getBuyerProspectRedirect = (redirectTo, isBuyerProspect = true) => {
  return async (store, state) => {
    const { params } = state;
    if (
      params &&
      params.transactionId &&
      isBuyerProspect ===
        Boolean(
          (
            await store.transactions.getOrFetchTransaction(
              params.transactionId,
              getTxnFetchOptions(state)
            )
          ).isBuyerProspect
        )
    ) {
      return redirectTo(store, state);
    }

    return null;
  };
};

function getOfferPrepRoute({ create = false } = {}) {
  return {
    name: create ? 'create' : 'prepare',
    path: create ? 'create/?td' : 'prepare/?td',
    component: () => import('./transactions/transaction/offers/offer/prepare'),
    titleFunction: ({ model, transaction }) => {
      const offer = model?.offer;
      const actionType = offer?.package?.pendingAction?.actionType;
      const verb = create ? 'Create' : 'Prepare';
      const prepTitle = actionType
        ? `${verb} ${startCase(actionType.toLowerCase())}`
        : verb;
      return (
        model &&
        transaction &&
        offer &&
        `${transaction.title} – Offers - ${offer.title} - ${prepTitle} – Glide`
      );
    },
    redirect: async (store, route) => {
      const {
        params: { transactionId, offerId },
      } = route;
      const [transaction, offerPackages] = await Promise.all([
        store.transactions.getOrFetchTransaction(transactionId, {
          includeData: true,
        }),
        store.transactions.getFetchItems.getOrFetch({
          transactionId,
          kind: 'TRANSACTION_PACKAGE',
          filter: 'offer',
        }),
      ]);

      const offerPackage = offerPackages.find(
        (tp) =>
          (!offerId &&
            transaction.isPurchase &&
            tp.id === transaction.apps.offersApp?.delegate?.offerPackageId) ||
          tp.offer.id === offerId
      );

      if (!offerPackage?.pendingAction) {
        return {
          ...route,
          name: route.name.split('.').slice(0, -1).join('.'),
        };
      }

      if (create && !offerPackage.isDraft) {
        return {
          ...route,
          name: route.name.replace('.create', '.prepare'),
        };
      }
      if (!create && offerPackage.isDraft) {
        return {
          ...route,
          name: route.name.replace('.prepare', '.create'),
        };
      }

      return null;
    },
  };
}

function getOfferPrepRoutes() {
  return [
    getOfferPrepRoute({
      create: false,
    }),
    getOfferPrepRoute({
      create: true,
    }),
  ];
}

// If transaction is buyer prospect, redirect to overview
// If offer is on draft status, always redirect to prepare route
// If transaction is listing with no access to offers mgmt, then redirect to offers root
// (to get paywall)
async function offerRedirect(store, route) {
  const {
    params: { transactionId, offerId },
  } = route;
  const [transaction, offerPackages] = await Promise.all([
    store.transactions.getOrFetchTransaction(transactionId, {
      force: store.account.hasCrmlsLinkedAccount,
      includeData: true,
    }),
    store.transactions.getFetchItems.getOrFetch({
      transactionId,
      kind: 'TRANSACTION_PACKAGE',
      filter: 'offer',
    }),
  ]);

  if (transaction?.isBuyerProspect) {
    // Temp redirect for buyer prospect offers
    store.ui.toast({
      message: 'Offers not available for Buyer Prospects.',
      type: 'warning',
      duration: 10,
    });
    return getRedirectTo('transactions.transaction')(store, route);
  }

  if (
    transaction?.isListing &&
    !transaction?.features?.premiumOffersManagement &&
    route.name !== 'transactions.transaction.offers'
  ) {
    return {
      ...route,
      name: 'transactions.transaction.offers',
      params: omit(route.params, 'offerId'),
    };
  }

  const offerPackage = offerPackages.find(
    (tp) =>
      (!offerId &&
        transaction.isPurchase &&
        tp.id === transaction.apps.offersApp?.delegate?.offerPackageId) ||
      tp.offer.id === offerId
  );

  if (offerPackage?.isDraft) {
    return {
      ...route,
      name: `${route.name}.create`,
    };
  }

  return null;
}

function getItemRedirect(route) {
  return async (store, state) => {
    let packet;
    try {
      packet = (
        await store.transactions.getOrFetchItemMulti(
          state.params.transactionId,
          null,
          [state.params.taskId]
        )
      )[0];
    } catch (e) {
      return null;
    }
    if (packet && packet.kind !== 'PACKET') {
      return null;
    }
    if (packet.child.kind === 'GUIDED_FORM_PACKET') {
      return {
        ...state,
        name: route,
        params: {
          transactionId: state.params.transactionId,
          taskId: packet.child.taskId,
        },
      };
    }
    return null;
  };
}

export default [
  {
    name: 'home',
    path: '/',
    redirect: async (store, state) => {
      return {
        ...state,
        name: 'pipelines',
        params: {},
      };
    },
  },
  {
    name: 'pspdfkit-viewer',
    path: '/pspdfkit-viewer/:formId',
    component: () =>
      import('./transactions/transaction/documents/pspdfkit-viewer'),
  },
  {
    name: 'esign',
    path: '/esign/:formId',
    component: () => import('./transactions/transaction/documents/esign'),
  },
  {
    name: 'pipelines',
    path: '/pipelines/?view',
    children: [
      {
        name: 'buyer-prospects',
        path: 'buyer-prospects/?view&p&filters&order&q',
        component: () => import('./pipelines/buyer-prospects'),
        titleFunction: () => {
          return 'Buyer Prospects – Glide';
        },
      },
      {
        name: 'transactions',
        path: 'transactions/?view&p&v&filters&order&q',
        component: () => import('./pipelines/transactions'),
        titleFunction: () => {
          return 'Transactions – Glide';
        },
      },
      {
        name: 'forms',
        path: 'forms/?view&p&filter&qs',
        redirect: async (store, state) => {
          return {
            ...state,
            name: 'pipelines.workflows',
          };
        },
      },
      {
        name: 'workflows',
        path: 'workflows/?view&p&filter&qs',
        component: () => import('./pipelines/workflows'),
        titleFunction: () => {
          return 'Workflows – Glide';
        },
      },
      {
        name: 'archived',
        path: 'archived/?view&p&v&filters&order&q',
        component: () => import('./pipelines/archived'),
        titleFunction: () => {
          return 'Archived Transactions – Glide';
        },
      },
      {
        name: 'file-review',
        path: 'file-review/?view&p&filters&order&q&back',
        component: () => import('./pipelines/file-review'),
        titleFunction: () => {
          return 'File Review – Glide';
        },
      },
    ],
  },
  {
    // Deprecated
    name: 'dash',
    path: '/dash/',
    redirect: getRedirectTo('pipelines'),
    children: [
      {
        name: 'transactions',
        path: 'transactions/?category&q&view',
        redirect: getRedirectTo('pipelines'),
        titleFunction: () => {
          return 'Transactions – Glide';
        },
      },
      {
        name: 'forms',
        path: 'forms/?:q',
        redirect: getRedirectTo('pipelines'),
        titleFunction: () => {
          return 'Forms – Glide';
        },
      },
    ],
  },
  {
    name: 'packets',
    path: '/packets/',
    children: [
      {
        name: 'gfpfPreview',
        path: 'gfpf-preview/?transactionId&ffcId',
        component: () => import('./packets/gfpf-preview'),
      },
    ],
    redirect: getRedirectTo('pipelines'),
  },
  {
    name: 'transactions',
    path: '/transactions/',
    redirect: (store, state) => {
      return {
        ...state,
        name: 'dash',
        params: {},
      };
    },
    children: [
      {
        name: 'transaction',
        path: ':transactionId/?refetch',
        titleFunction: ({ model }) => {
          return (
            model &&
            model.transaction &&
            `${model.transaction.title} – Overview – Glide`
          );
        },
        component: () => import('./transactions/transaction'),
        indexComponent: () => import('./transactions/transaction/overview'),
        children: [
          // TODO this is here to prevent old links from breaking.
          // Remove after a prudential amount of time passed
          {
            name: 'disclosure',
            path: 'disclosure/?fromTaskId&pt&tab&from',
            redirect: (store, state) => ({
              ...state,
              name: 'transactions.transaction.disclosurePackage',
              params: state.params,
            }),
          },
          {
            name: 'disclosurePackage',
            path: 'disclosure-package/?fromTaskId&pt&tab&from',
            requiredTxnFields: ['property/address'],
            titleFunction: ({ model }) => {
              return (
                model &&
                model.transaction &&
                `${model.transaction.title} – ${
                  model.transaction.isSale ? 'Disclosures' : 'Purchase'
                } – Glide`
              );
            },
            component: () =>
              import('./transactions/transaction/disclosure-package'),
            children: [
              {
                name: 'preview',
                path: 'preview/',
                requiredTxnFields: ['property/address'],
                component: () =>
                  import(
                    './transactions/transaction/disclosure-package-preview'
                  ),
                titleFunction: ({ model }) => {
                  return (
                    model &&
                    model.transaction &&
                    `${model.transaction.title} – Preview`
                  );
                },
              },
            ],
          },
          {
            name: 'offers',
            path: 'offers/?view&state',
            requiredTxnFields: ['property/address'],
            component: () => import('./transactions/transaction/offers'),
            titleFunction: ({ transaction }) => {
              return transaction && `${transaction.title} – Offers – Glide`;
            },
            redirect: offerRedirect,
            children: [
              {
                name: 'offer',
                path: ':offerId/',
                component: () =>
                  import('./transactions/transaction/offers/offer'),
                redirect: offerRedirect,
                titleFunction: ({ model, transaction }) => {
                  return (
                    model &&
                    transaction &&
                    model.offer &&
                    `${transaction.title} – Offers - ${model.offer.title} – Glide`
                  );
                },
                children: getOfferPrepRoutes(),
              },
              ...getOfferPrepRoutes(),
            ],
          },
          {
            name: 'properties',
            path: 'properties/',
            component: () => import('./transactions/transaction/properties'),
            titleFunction: ({ model }) => {
              return (
                model &&
                model.transaction &&
                `${model.transaction.title} – Properties – Glide`
              );
            },
            children: [
              {
                name: 'start',
                path: 'start',
                component: () =>
                  import(
                    './transactions/transaction/properties/embedded-empty-state'
                  ),
                redirect: (store, route) => {
                  if (!store.ui.isEmbedded) {
                    return {
                      ...route,
                      name: 'transactions.transaction.properties',
                    };
                  }
                  return null;
                },
              },
              {
                name: 'property',
                path: ':propertyId/',
                component: () =>
                  import('./transactions/transaction/properties/detail'),
                titleFunction: ({ model, transaction }) => {
                  return (
                    transaction &&
                    model?.property &&
                    `${transaction.title} – Properties - ${model.property.shortAddress} – Glide`
                  );
                },
              },
            ],
          },
          {
            name: 'pead',
            path: 'pead/',
            component: () => import('./transactions/transaction/pead'),
            titleFunction: ({ model }) => {
              return (
                model &&
                model.transaction &&
                `${model.transaction.title} – PEAD – Glide`
              );
            },
          },
          {
            name: 'visitors',
            path: 'visitors/?tab',
            requiredTxnFields: ['property/address'],
            component: () => import('./transactions/transaction/visitors'),
            titleFunction: ({ model }) => {
              return (
                model &&
                model.transaction &&
                `${model.transaction.title} – Visitors – Glide`
              );
            },
          },
          {
            name: 'comments',
            path: 'comments/',
            titleFunction: ({ model }) => {
              return (
                model &&
                model.transaction &&
                `${model.transaction.title} – Comments and flags – Glide`
              );
            },
            component: () => import('./transactions/transaction/comments'),
          },
          {
            name: 'checklists',
            path: 'checklists/?:mode',
            titleFunction: ({ model }) => {
              return (
                model &&
                model.transaction &&
                `${model.transaction.title} – Checklists – Glide`
              );
            },
            component: () => import('./transactions/transaction/checklists'),
            redirect: getBuyerProspectRedirect((store, state) => ({
              ...state,
              name: 'transactions.transaction',
            })),
            children: [
              {
                name: 'file-review',
                path: 'file-review/?:cli&:td',
                component: () =>
                  import('./transactions/transaction/checklists/file-review'),
              },
            ],
          },
          {
            name: 'documents',
            path: 'documents/',
            titleFunction: ({ model }) => {
              return (
                model.transaction &&
                `${model.transaction.title} – Documents – Glide`
              );
            },
            component: () => import('./transactions/transaction/documents'),
            children: [
              {
                titleFunction: ({ model }) => {
                  return (
                    model.transaction &&
                    `${model.transaction.title} – Deleted Documents – Glide`
                  );
                },
                name: 'deleted',
                path: 'deleted/?:back',
                component: () =>
                  import('./transactions/transaction/documents/deleted'),
              },
              {
                name: 'document',
                titleFunction: ({ model }) => {
                  return (
                    model.transaction &&
                    model.transactionDocument &&
                    `${model.transaction.title} – Documents – ${model.transactionDocument.title} – Glide`
                  );
                },
                path: ':documentId/?:back&:readonly&:cli&:forceLoad&:tpid&:fid',
                scrollToTop: false,
                component: () =>
                  import('./transactions/transaction/documents/document'),
                children: [
                  {
                    name: 'split',
                    path: 'split',
                    titleFunction: ({ model }) => {
                      return (
                        model.transaction &&
                        model.transactionDocument &&
                        `${model.transaction.title} – Documents – Split – ${model.transactionDocument.title} – Glide`
                      );
                    },
                    component: () =>
                      import(
                        './transactions/transaction/documents/document/split'
                      ),
                  },
                  {
                    name: 'edit',
                    path: 'edit',
                    titleFunction: ({ model }) => {
                      return (
                        model.transaction &&
                        model.transactionDocument &&
                        `${model.transaction.title} – Documents – Edit – ${model.transactionDocument.title} – Glide`
                      );
                    },
                    component: () =>
                      import(
                        './transactions/transaction/documents/document/edit'
                      ),
                  },
                  {
                    name: 'annotate',
                    path: 'annotate',
                    titleFunction: ({ model }) => {
                      return (
                        model.transaction &&
                        model.transactionDocument &&
                        `${model.transaction.title} – Documents – Annotate – ${model.transactionDocument.title} – Glide`
                      );
                    },
                    component: () =>
                      import(
                        './transactions/transaction/documents/document/annotate'
                      ),
                  },
                ],
              },
              {
                name: 'task',
                path: 'tasks/:taskId/?back',
                titleFunction: ({ model }) => {
                  return (
                    model.transaction &&
                    `${model.transaction.title} – Document Task – Glide`
                  );
                },
                component: () =>
                  import('./transactions/transaction/items/item'),
                redirect: getItemRedirect(
                  'transactions.transaction.documents.task'
                ),
              },
            ],
          },
          {
            name: 'parties',
            path: 'parties/?:item',
            component: () =>
              import('./transactions/transaction/transaction-details'),
            titleFunction: ({ model }) => {
              return (
                model.transaction &&
                `${model.transaction.title} – Parties – Glide`
              );
            },
            // Old parties-only screen (no txn details) only enabled for Buyer Prospects
            redirect: (store, state) => ({
              ...state,
              name: 'transactions.transaction.details',
            }),
            children: [
              {
                name: 'party',
                path: ':partyId/',
                titleFunction: ({ model }) => {
                  return (
                    model.transaction &&
                    `${model.transaction.title} – Parties – Glide`
                  );
                },
                redirect: (store, state) => {
                  const { params } = state;
                  return {
                    ...state,
                    params: {
                      ...params,
                    },
                    name: 'transactions.transaction.details.party',
                  };
                },
              },
            ],
          },
          {
            name: 'disclosures',
            path: 'disclosures/',
            component: () => import('./transactions/transaction/disclosures'),
            children: [
              {
                name: 'packet',
                path: ':packetId/',
                component: () =>
                  import('./transactions/transaction/disclosures/packet'),
                children: [
                  {
                    name: 'preview',
                    path: 'preview',
                  },
                ],
              },
            ],
          },
          {
            name: 'packets',
            path: 'packets/?envelopeId&event',
            redirect: (store, state) => {
              const { params } = state;
              return {
                ...state,
                params: {
                  ...params,
                  mode: 'items',
                },
                name: 'transactions.transaction',
              };
            },
            children: [
              {
                name: 'packet',
                path: ':packetId/',
                titleFunction: ({ model }) => {
                  return (
                    model.transaction &&
                    model.packet &&
                    `${model.transaction.title} – ${model.packet.title} – Glide`
                  );
                },
                redirect: async (store, state) => {
                  const packet = await store.transactions.getOrFetchItem(
                    state.params.transactionId,
                    'PACKET',
                    state.params.packetId
                  );
                  if (packet.child.kind === 'GUIDED_FORM_PACKET') {
                    const task = await store.transactions.getOrFetchItem(
                      state.params.transactionId,
                      'TASK',
                      packet.child.taskId
                    );
                    if (
                      task.isClientVisibleItem &&
                      task.can('actAsClient') &&
                      !task.can('actAsAdmin') &&
                      task.type === 'GFP'
                    ) {
                      window.location.assign(
                        `/client/transactions/${task.transactionId}/packets/${state.params.packetId}/`
                      );
                      return new Promise(() => null);
                    }
                    return {
                      ...state,
                      name: 'transactions.transaction.items.item',
                      params: {
                        transactionId: state.params.transactionId,
                        taskId: packet.child.taskId,
                      },
                    };
                  }
                  return null;
                },
                children: [
                  {
                    name: 'gfpf',
                    path: 'gfpf/:gfpfId/',
                    redirect: async (store, state) => {
                      const packet = await store.transactions.getOrFetchItem(
                        state.params.transactionId,
                        'PACKET',
                        state.params.packetId
                      );
                      if (packet.child.kind === 'GUIDED_FORM_PACKET') {
                        return {
                          ...state,
                          name: 'transactions.transaction.items.item.gfpf',
                          params: {
                            transactionId: state.params.transactionId,
                            taskId: packet.child.taskId,
                            gfpfId: state.params.gfpfId,
                          },
                        };
                      }
                      return null;
                    },
                  },
                ],
              },
            ],
          },
          {
            name: 'items',
            path: 'items/',
            redirect: (store, state) => {
              return {
                ...state,
                name: 'transactions.transaction',
              };
            },
            children: [
              {
                name: 'item',
                path: ':taskId/?back',
                titleFunction: ({ model }) => {
                  return (
                    model.transaction &&
                    model.task &&
                    `${model.transaction.title} – ${model.task.data.title} – Glide`
                  );
                },
                component: () =>
                  import('./transactions/transaction/items/item'),
                children: [
                  {
                    name: 'gfpf',
                    path: 'gfpf/:gfpfId/?:f',
                    titleFunction: ({ model }) => {
                      return (
                        model.transaction &&
                        model.gfp &&
                        model.gfp.data &&
                        `${model.transaction.title} – ${model.gfp.data.title} – Glide`
                      );
                    },
                    component: () =>
                      import('./transactions/transaction/items/item/gfpf'),
                  },
                ],
                redirect: getItemRedirect(
                  'transactions.transaction.documents.items.item'
                ),
              },
            ],
          },
          {
            name: 'details',
            path: 'details/?expand',
            titleFunction: ({ model }) => {
              return (
                model.transaction &&
                `${model.transaction.title} – Transaction Details – Glide`
              );
            },
            component: () =>
              import('./transactions/transaction/transaction-details'),
            children: [
              {
                name: 'party',
                path: 'party/:partyId/',
              },
            ],
          },
          {
            name: 'timeline',
            path: 'timeline/',
            component: () => import('./transactions/transaction/timeline'),
            titleFunction: ({ model }) => {
              return (
                model.transaction && `${model.transaction.title} – Timeline`
              );
            },
          },
          {
            name: 'activity',
            path: 'activity/',
            titleFunction: ({ model }) => {
              return (
                model.transaction &&
                `${model.transaction.title} – Activity Log – Glide`
              );
            },
            component: () => import('./transactions/transaction/activity'),
          },
          {
            name: 'signature-requests',
            path: 'signature-requests/',
            titleFunction: ({ model }) => {
              return (
                model.transaction &&
                `${model.transaction.title} – Signature Requests – Glide`
              );
            },
            component: () =>
              import('./transactions/transaction/signature-requests'),
            children: [
              {
                name: 'detail',
                path: ':signatureRequestId',
                component: () =>
                  import(
                    './transactions/transaction/signature-requests/detail'
                  ),
              },
            ],
          },
          {
            name: 'chores',
            path: 'chores/',
            component: () => import('./transactions/transaction/chores'),
          },
        ],
      },
    ],
  },
  {
    // Deprecated
    name: 'tickets',
    path: '/tickets/',
    redirect: getRedirectTo('pipelines'),
  },
  {
    name: 'account',
    path: '/account/?section',
    component: () => import('./account'),
    titleFunction: () => 'Account Settings',
    redirect: (store, state) => {
      return {
        ...state,
        name: 'account.profile',
      };
    },
    children: [
      {
        name: 'profile',
        path: 'profile/',
        component: () => import('./account/profile'),
        titleFunction: () => 'Profile Info',
      },
      {
        name: 'preferences',
        path: 'preferences/?section',
        component: () => import('./account/preferences'),
        titleFunction: () => 'Account Preferences',
      },
      {
        name: 'notifications',
        path: 'notifications/',
        component: () => import('./account/notifications'),
        titleFunction: () => 'Notifications and Reminders',
      },
      {
        name: 'integrations',
        path: 'integrations/',
        component: () => import('./account/integrations'),
        titleFunction: () => 'Memberships and Integrations',
      },
      {
        name: 'addressBook',
        path: 'address-book/',
        component: () => import('./account/address-book'),
        titleFunction: () => 'Address Book',
      },
      {
        name: 'verifyMembership',
        path: 'verify-membership/?redirect&next',
        component: () => import('./account/verify-membership'),
        titleFunction: () => 'Verify Membership',
      },
      {
        name: 'compass-alias-email-cta',
        path: 'compass-alias-email-cta',
        component: () => import('./account/compass-alias-email-cta'),
      },
      {
        name: 'compass-alias-email-cta2',
        path: 'compass-alias-email-cta2',
        component: () => import('./account/compass-alias-email-cta2'),
      },
      {
        name: 'update',
        path: 'update/',
        component: () => import('./account/update'),
        titleFunction: () => 'Update',
      },
    ],
  },
  {
    name: 'upgrade',
    path: '/upgrade/?:next&:plan',
    component: () => import('./upgrade'),
    titleFunction: () => 'Pricing and Plans',
  },
  {
    name: 'billing',
    path: '/billing/',
    component: () => import('./billing'),
    titleFunction: () => 'Billing',
  },
  {
    name: 'invite',
    path: '/invites/:inviteId/',
    component: () => import('./invites/invite'),
  },
  {
    name: 'vendors',
    path: '/vendors/:code/',
    component: () => import('./vendors'),
  },
  {
    name: 'zipformViewTransaction',
    path: '/zipform/view-transaction/:zfTxnId/?:sign&:transactionId',
    component: () => import('./zipform/view-zf-transaction'),
  },
  {
    name: 'docusignViewEnvelope',
    path: '/docusign/view-envelope/?:next&:email',
    component: () => import('./docusign/view-envelope'),
  },
  {
    name: 'flow',
    path: '/flow/:flowId/',
    redirect: (store, state) => {
      return {
        ...state,
        name: 'flow.page',
        params: {
          ...state.params,
          path: 'root',
        },
      };
    },
    children: [
      {
        name: 'page',
        path: '*path',
        component: () => import('src/components/flows/flow'),
      },
    ],
  },
  {
    name: 'sflow',
    path: '/sflow/:sflowId/?:fromFlowId',
    component: () => import('src/components/flows/super-flow'),
  },
  {
    name: 'orgs',
    path: '/org/:orgId/',
    component: () => import('./orgs'),
    redirect: (store, state) => {
      return {
        ...state,
        name: 'orgs.members',
        params: {
          ...state.params,
        },
      };
    },
    children: [
      {
        name: 'invite',
        path: 'invite/:inviteId/',
        component: () => import('./orgs/invite'),
      },
      {
        name: 'members',
        path: 'members/',
        component: () => import('./orgs/members'),
        titleFunction: () => 'Members – Glide',
      },
      {
        name: 'groups',
        path: 'groups/',
        component: () => import('./orgs/groups'),
        titleFunction: () => 'Groups – Glide',
      },
      {
        name: 'checklists',
        path: 'checklists/',
        component: () => import('./orgs/checklists'),
        titleFunction: () => 'Checklists – Glide',
        children: [
          {
            name: 'edit',
            path: ':checklistId/?new',
            component: () => import('./orgs/checklists/edit'),
            titleFunction: () => 'Edit Checklists – Glide',
          },
        ],
      },
      {
        name: 'offices',
        path: 'offices/',
        component: () => import('./orgs/offices'),
        titleFunction: () => 'Offices – Glide',
      },
      {
        name: 'settings',
        path: 'settings/',
        component: () => import('./orgs/settings'),
        titleFunction: () => 'Settings – Glide',
      },
    ],
  },
  {
    name: 'transactionTemplates',
    path: '/transaction-templates/?cursor',
    component: () => import('./transaction-templates'),
    titleFunction: () => 'Transaction Templates – Glide',
    children: [
      {
        name: 'edit',
        path: ':transactionId/?t',
        component: () => import('./transaction-templates/template'),
        titleFunction: ({ model: { template } }) =>
          `${template.title} - Transaction Templates – Glide`,
        children: [
          {
            name: 'party',
            path: '/party/:partyId/?t',
          },
        ],
      },
    ],
  },
  {
    name: 'clauses',
    path: '/clause-manager/',
    component: () => import('./clauses'),
    titleFunction: () => 'Clause Manager – Glide',
  },
  {
    name: 'compass',
    path: '/compass/',
    component: () => import('./compass'),
    titleFunction: () => 'Compass Embedded – Glide',
  },
  {
    name: 'route',
    path: '/route/?r',
    redirect: (store, state) => {
      const { r: routeJson } = state.params;
      try {
        const { name, params } = JSON.parse(routeJson);
        return {
          ...state,
          name,
          params,
        };
      } catch (unusedErr) {
        store.ui.wentWrong(unusedErr);
        return {
          ...state,
          name: 'home',
          params: {},
        };
      }
    },
  },
  // {
  //   name: 'scratch',
  //   path: '/scratch/',
  //   component: () => import('./scratch'),
  // },
  // {
  //   name: 'colors',
  //   path: '/colors/',
  //   component: () => import('./colors'),
  // },
];

/*
 * isRoot: routes that are at the root of the app entry point
 * isTxnRoot: routes that are direct children of the `transactions.transaction` route.
 *    (ie `transactions.transaction.some-route`)
 * group: an id to identify group of routes. Routes with the same group id will render
 *   within the same webview. Navigating to a route with a group id of undefined or
 *   different from the current group id will trigger a new webview in the mobile app
 *   (including the fancy animation).
 */
export const iosAllowedRoutes = {
  home: {
    isRoot: true,
    group: 1,
  },
  pipelines: {
    isRoot: true,
    group: 1,
  },
  dash: {
    isRoot: true,
    group: 1,
  },
  transactions: {
    isRoot: true,
    group: 1,
  },
  'pipelines.transactions': {
    isRoot: true,
    group: 1,
  },
  'pipelines.archived': {
    isRoot: true,
    group: 1,
  },
  'pipelines.workflows': {
    isRoot: true,
    group: 1,
  },
  'pipelines.buyer-prospects': {
    isRoot: true,
    group: 1,
  },
  'transactions.transaction': {
    isTxnRoot: true,
  },
  'transactions.transaction.disclosure': {
    isTxnRoot: true,
    group: 2,
  },
  'transactions.transaction.disclosurePackage': {
    isTxnRoot: true,
    group: 2,
  },
  'transactions.transaction.documents': {
    isTxnRoot: true,
  },
  'transactions.transaction.documents.document': {},
  'transactions.transaction.documents.task': {
    group: 5,
  },
  'transactions.transaction.documents.deleted': {},
  'transactions.transaction.details': {
    isTxnRoot: true,
    group: 3,
  },
  'transactions.transaction.details.party': {
    isTxnRoot: true,
    group: 3,
  },
  'transactions.transaction.parties': {
    isTxnRoot: true,
    group: 3,
  },
  'transactions.transaction.parties.party': {
    isTxnRoot: true,
    group: 3,
  },
  'transactions.transaction.items.item': {
    group: 5,
  },
  'transactions.transaction.packets.packet': {
    group: 5,
  },
  'transactions.transaction.items.item.gfpf': {
    group: 6,
  },
  'transactions.transaction.packets.packet.gfpf': {
    group: 6,
  },
  'transactions.transaction.signature-requests': {
    isTxnRoot: true,
  },
  'transactions.transaction.signature-requests.detail': {},
  'transactions.transaction.pead': {
    isTxnRoot: true,
  },
  'transactions.transaction.properties': {
    isTxnRoot: true,
  },
  'transactions.transaction.properties.property': {},
  'transactions.transaction.disclosures.packet': {},
  'transactions.transaction.chores': {},
  'transactions.transaction.disclosures.packet.preview': {},
  'transactions.transaction.offers': {
    isTxnRoot: true,
  },
  'transactions.transaction.offers.offer': {
    group: 7,
  },
  'transactions.transaction.offers.prepare': {
    group: 7,
  },
  'transactions.transaction.offers.offer.prepare': {
    group: 7,
  },
  'transactions.transaction.offers.create': {
    group: 7,
  },
  'transactions.transaction.offers.offer.create': {
    group: 7,
  },
  'transactions.transaction.timeline': {},
  'account.profile': {
    isTxnRoot: true,
  },
  'account.preferences': {
    isTxnRoot: true,
  },
  'account.notifications': {
    isTxnRoot: true,
  },
  'account.integrations': {
    isTxnRoot: true,
  },
  'account.addressBook': {
    isTxnRoot: true,
  },
  'account.verifyMembership': {
    isTxnRoot: true,
  },
  'account.compass-alias-email-cta': {
    isTxnRoot: true,
  },
  'account.compass-alias-email-cta2': {
    isTxnRoot: true,
  },
  compassEmailBlock: {
    isTxnRoot: true,
  },
  account: {},
  'orgs.invite': {},
  sflow: {
    isFullScreenModal: true,
  },
  flow: {
    isFullScreenModal: true,
    group: 4,
  },
  'flow.page': {
    isFullScreenModal: true,
    group: 4,
  },
  orgs: {
    isTxnRoot: true,
    isOrgRoot: true,
    group: 8,
  },
  'orgs.members': {
    isTxnRoot: true,
    isOrgRoot: true,
    group: 8,
  },
  'orgs.groups': {
    isTxnRoot: true,
    isOrgRoot: true,
  },
  'orgs.checklists': {
    isTxnRoot: true,
    isOrgRoot: true,
  },
  'orgs.checklists.edit': {},
  'orgs.offices': {
    isTxnRoot: true,
    isOrgRoot: true,
  },
  'orgs.settings': {
    isTxnRoot: true,
    isOrgRoot: true,
  },
};
