const wordsList = (values_?: string[], andForLastValue = true) => {
  const values = values_ || [];
  if (!values || !values.length) {
    return '';
  }

  if (values.length === 1) {
    return values[0];
  }

  if (andForLastValue) {
    return `${values.slice(0, -1).join(', ')} and ${values.slice(-1)[0]}`;
  }

  return values.join(', ');
};

export default wordsList;
