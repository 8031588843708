import invariant from 'invariant';
import camelCase from 'lodash/camelCase';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import mergeWith from 'lodash/mergeWith';
import pick from 'lodash/pick';
import { runInAction, toJS } from 'mobx';
import moment from 'moment';
import { PURCHASE_STATUSES } from 'src/models/transactions/constants';
import isArray from 'src/utils/is-array';

const INTENTS = window.Glide.CONSTANTS.INTENTS;
const NON_PARTY_INTENTS = window.Glide.CONSTANTS.NON_PARTY_INTENTS;

export default INTENTS;
export { NON_PARTY_INTENTS };

function updateClonedItemJS(itemModel, diff) {
  // Returns a clone of the JS of the item updated with the
  // diff. The diff is merged using mergeWith() except it overwrites
  // arrays instead of merging them.

  const clone = cloneDeep(itemModel.toJS());
  return mergeWith(clone, diff, (objValue, srcValue) => {
    if (isArray(objValue)) {
      return srcValue;
    }
    return undefined;
  });
}

function intent(kind, data, rest) {
  return {
    kind,
    [camelCase(get(INTENTS, kind))]: data,
    ...rest,
  };
}
intent.INTENTS = INTENTS;

export function startChore({ choreId }) {
  return intent(intent.INTENTS.START_CHORE, {
    choreId,
  });
}

export function hideChore({ choreId, userId }) {
  return intent(intent.INTENTS.HIDE_CHORE, {
    choreId,
    userId,
  });
}

export function createTasks(data) {
  return intent(intent.INTENTS.CREATE_TASKS, data);
}

export function reorderTasks({ reorderings }) {
  const unstageArr = [];
  return intent(
    intent.INTENTS.REORDER_TASKS,
    {
      reorderings,
    },
    {
      optimistic: {
        stage: (transaction) => {
          reorderings.forEach(({ taskId, orderIndex, parentId }) => {
            const task = transaction.store.itemsById.get(taskId);
            if (!task) {
              return;
            }

            const oldParent = task.parent;
            const newParent = transaction.store.itemsById.get(parentId);
            const changedParents = oldParent.id !== newParent.id;
            if (changedParents) {
              unstageArr.push(
                oldParent.stage({
                  outEdges: oldParent.outEdges.filter(
                    (e) => e.item2Id !== task.id
                  ),
                })
              );
              unstageArr.push(
                newParent.stage({
                  outEdges: newParent.outEdges.concat([
                    {
                      kind: 'TASK_HAS_TASK',
                      item1Id: newParent.id,
                      item2Id: task.id,
                    },
                  ]),
                })
              );
            }

            unstageArr.push(
              task.stage({
                task: {
                  orderIndex,
                  inEdges: changedParents
                    ? task.inEdges
                        .filter((e) => e.item1Id !== oldParent.id)
                        .concat([
                          {
                            kind: 'TASK_HAS_TASK',
                            item1Id: newParent.id,
                            item2Id: task.id,
                          },
                        ])
                    : task.inEdges,
                },
              })
            );
          });
        },
        unstage: () => {
          unstageArr.forEach((cb) => cb());
        },
      },
    }
  );
}

export function addCalendarAttendees({ itemIds }) {
  return intent(intent.INTENTS.ADD_CALENDAR_ATTENDEES, {
    attendees: itemIds.map((itemId) => ({
      itemId,
    })),
  });
}

export function createLinkZfFlow({ back }) {
  return intent(intent.INTENTS.CREATE_LINK_ZF_FLOW, {
    back,
  });
}

export function getOrCreateImportZfDocFlow({ taskId }) {
  return intent(intent.INTENTS.GET_OR_CREATE_ZF_IMPORT_DOC_FLOW, {
    taskId,
  });
}

export function getOrCreateOrderNhdFlow({ taskId }) {
  return intent(intent.INTENTS.GET_OR_CREATE_ORDER_NHD_FLOW, {
    taskId,
  });
}

export function declineNhdOrder({ taskId }) {
  return intent(intent.INTENTS.DECLINE_NHD_ORDER, {
    taskId,
  });
}

export function cancelNhdOrder() {
  return intent(intent.INTENTS.CANCEL_NHD_ORDER, {});
}

export function resetNhdOrder(nhdId) {
  return intent(intent.INTENTS.RESET_NHD_ORDER, {
    nhdId,
  });
}

export function skipTask({ taskId }) {
  return intent(intent.INTENTS.SKIP_TASK, {
    taskId,
  });
}

export function updateTransactionFields({ fieldValuesByNamespace }) {
  return intent(intent.INTENTS.UPDATE_TRANSACTION_FIELDS, {
    fieldValuesByNamespace,
  });
}

export function propertySetAsPrimary({
  propertyInfoId = '',
  transactionDocumentsToDemote = [],
  updateToState = undefined,
}) {
  const optimistic = {
    stage: updateToState
      ? (transaction) => {
          const meta = {
            state: updateToState,
            ...(!propertyInfoId
              ? {
                  address: null,
                }
              : {}),
          };
          transaction.stage({
            meta,
          });
        }
      : undefined,
    unstage: (transaction) => {
      transaction.unstage();
    },
  };
  return intent(
    intent.INTENTS.PROPERTY_SET_AS_PRIMARY,
    {
      propertyInfoId,
      transactionDocumentsToDemote,
      updateToState,
    },
    {
      optimistic,
    }
  );
}

export function createAvidGenerationFlow({ taskId }) {
  return intent(intent.INTENTS.CREATE_AVID_GENERATION_FLOW, {
    taskId,
  });
}

export function inviteAgentToCompleteAvid({ taskId }) {
  return intent(intent.INTENTS.INVITE_AGENT_TO_COMPLETE_AVID, {
    taskId,
  });
}

export function editReminder({ reminder, taskId, taskReminderId }) {
  return intent(intent.INTENTS.EDIT_REMINDER, {
    reminder,
    taskId,
    taskReminderId,
  });
}

export function updateTaskMeta(data) {
  let unstageArr = [];
  return intent(intent.INTENTS.UPDATE_TASK_META, data, {
    optimistic: {
      stage: (transaction) => {
        runInAction(() => {
          unstageArr = data.updates
            .map((u) => {
              const task = transaction.store.itemsById.get(u.id);
              if (task) {
                return task.stage({
                  ...u,
                });
              }
              return null;
            })
            .filter((cb) => cb);
        });
      },
      unstage: () => {
        unstageArr.forEach((cb) => cb());
      },
    },
  });
}

export function gfpStartGenerateFlow(gfpId, allowIncomplete) {
  return {
    kind: INTENTS.GFP_START_GENERATE_FLOW,
    gfpStartGenerateFlow: {
      gfpId,
      allowIncomplete,
    },
  };
}

export function gfpStartTutorial(gfpId) {
  return intent(INTENTS.GFP_START_TUTORIAL, {
    gfpId,
  });
}

export function getOrCreateOffer({ offerId, formIds, tdIds, propertyInfoId }) {
  return intent(INTENTS.GET_OR_CREATE_OFFER, {
    offerId,
    formIds,
    tdIds,
    propertyInfoId,
  });
}

export function previewFlowPdf(flowId, isAgent = false) {
  return {
    kind: INTENTS.PREVIEW_FLOW_PDF,
    previewFlowPdf: {
      flowId,
      isAgent,
    },
  };
}

export function setGfpfAsReviewed(gfpfId) {
  return {
    kind: INTENTS.SET_GFPF_AS_REVIEWED,
    setGfpfAsReviewed: {
      gfpfId,
    },
  };
}
export function setGfpfPreviewPdf(gfpfId) {
  return {
    kind: INTENTS.SET_GFPF_PREVIEW_PDF,
    setGfpfPreviewPdf: {
      gfpfId,
    },
  };
}

export function completeTask(taskId) {
  return {
    kind: INTENTS.COMPLETE_TASK,
    completeTask: {
      taskId,
    },
  };
}

export const updateTdTaskAssignments = (assignments, optimistic = true) => {
  const unstageArr = [];
  return intent(
    intent.INTENTS.UPDATE_TD_TASK_ASSIGNMENTS,
    {
      assignments,
    },
    optimistic
      ? {
          optimistic: {
            stage: (transaction) => {
              assignments.forEach(({ taskId, addTdsIds, removeTdsIds }) => {
                const task = transaction.store.itemsById.get(taskId);
                const addTds = (addTdsIds || [])
                  .map((tdId) => transaction.store.itemsById.get(tdId))
                  .filter((td) => !!td);
                const removeTds = (removeTdsIds || [])
                  .map((tdId) => transaction.store.itemsById.get(tdId))
                  .filter((td) => !!td);

                if (!task || (!addTds.length && !removeTds.length)) {
                  return;
                }

                const TASK_ASSIGNED_TD_EDGE_KIND = 'TASK_ASSIGNED_TD';

                const getAddedTdEdge = (addedTd) => ({
                  kind: TASK_ASSIGNED_TD_EDGE_KIND,
                  item1Id: task.id,
                  item2Id: addedTd.id,
                  createdAt: moment().format('x'),
                });

                unstageArr.push(
                  task.stage({
                    outEdges: task.outEdges
                      .concat(addTds.map((addedTd) => getAddedTdEdge(addedTd)))
                      .filter(
                        (edge) =>
                          edge.kind !== TASK_ASSIGNED_TD_EDGE_KIND ||
                          !removeTds.find(
                            (removedTd) => removedTd.id === edge.item2Id
                          )
                      ),
                  })
                );
                addTds.forEach((addedTd) => {
                  unstageArr.push(
                    addedTd.stage({
                      inEdges: addedTd.inEdges.concat(getAddedTdEdge(addedTd)),
                    })
                  );
                });
                removeTds.forEach((removedTd) => {
                  unstageArr.push(
                    removedTd.stage({
                      inEdges: removedTd.inEdges.filter(
                        (edge) =>
                          edge.kind !== TASK_ASSIGNED_TD_EDGE_KIND ||
                          edge.item1Id !== task.id
                      ),
                    })
                  );
                });
              });
            },
            unstage: () => {
              unstageArr.forEach((cb) => cb());
            },
          },
        }
      : null
  );
};

export function updateTaskAssignedParty({ taskId, partyId, teamMembershipId }) {
  return intent(intent.INTENTS.UPDATE_TASK_ASSIGNED_PARTY, {
    taskId,
    partyId,
    teamMembershipId,
  });
}

export function load(data) {
  return intent(intent.INTENTS.LOAD, data);
}

export function mailTds(data) {
  return intent(intent.INTENTS.MAIL_TDS, data);
}

export function create({
  addressId,
  creatorRoles,
  fromTransactionId,
  isTest,
  state,
  title,
  addTeamMembers,
  groupId,
}) {
  return {
    kind: INTENTS.CREATE,
    create: {
      addressId,
      creatorRoles,
      fromTransactionId,
      isTest,
      state,
      title,
      addTeamMembers,
      groupId,
    },
  };
}

export const voidTransaction = (data) => {
  return intent(intent.INTENTS.VOID_TRANSACTION, data);
};

export const unVoidTransaction = (data) => {
  return intent(intent.INTENTS.UNVOID_TRANSACTION, data);
};

export function assignTransactionDocumentsToFolderIntent(
  assignments,
  optimistic = true,
  allowNamespaceChange = false
) {
  const unstageArr = [];
  return {
    kind: INTENTS.ASSIGN_TRANSACTION_DOCUMENTS_TO_FOLDER,
    assignTransactionDocumentsToFolder: {
      assignments,
      allowNamespaceChange,
    },
    optimistic: optimistic
      ? {
          stage: (transaction) => {
            runInAction(() => {
              assignments.forEach((a) => {
                const store = transaction.store;
                const td = store.itemsById.get(a.tdId);
                const foldersByDocId = store.transactionDocuments.folderByDocId(
                  transaction.id
                );
                const foldersById = store.transactionDocuments.foldersById(
                  transaction.id
                );
                const oldFolder = foldersByDocId[td.id];

                if (oldFolder.id === a.folderId) {
                  unstageArr.push(
                    td.stage({
                      [camelCase(td.kind)]: {
                        index: a.index,
                      },
                    })
                  );
                  return;
                }

                const newFolder = foldersById[a.folderId];

                const newTdInEdges = td.inEdges.filter(
                  (e) => e.kind !== 'FOLDER_HAS_DOCUMENT'
                );
                newTdInEdges.push({
                  kind: 'FOLDER_HAS_DOCUMENT',
                  item1Id: newFolder.id,
                  item2Id: td.id,
                });

                unstageArr.push(
                  td.stage({
                    [camelCase(td.kind)]: {
                      index: a.index,
                    },
                    inEdges: newTdInEdges,
                  })
                );

                unstageArr.push(
                  oldFolder.stage({
                    outEdges: oldFolder.outEdges.filter(
                      (e) =>
                        !(
                          e.kind === 'FOLDER_HAS_DOCUMENT' &&
                          e.item2Id === td.id
                        )
                    ),
                  })
                );

                if (
                  !newFolder.outEdges.some(
                    (e) =>
                      e.kind === 'FOLDER_HAS_DOCUMENT' && e.item2Id === td.id
                  )
                ) {
                  unstageArr.push(
                    newFolder.stage({
                      outEdges: newFolder.outEdges.concat([
                        {
                          kind: 'FOLDER_HAS_DOCUMENT',
                          item1Id: newFolder.id,
                          item2Id: td.id,
                        },
                      ]),
                    })
                  );
                }
              });
            });
          },
          unstage: () => {
            unstageArr.forEach((cb) => cb());
          },
        }
      : null,
  };
}

export function invitePartiesIntent(invites) {
  return {
    kind: INTENTS.INVITE_PARTIES,
    inviteParties: {
      invites,
    },
  };
}

export function addAgentTeamMembers(partyId) {
  return {
    kind: INTENTS.ADD_AGENT_TEAM_MEMBERS,
    addAgentTeamMembers: {
      partyId,
    },
  };
}

function getContactWithGlideUserContactSource(contact, userContactId) {
  return contact
    ? {
        ...contact,
        contactSource: userContactId
          ? {
              origin: 'GLIDE',
              id: userContactId,
            }
          : contact.contactSource,
      }
    : contact;
}

export function partyInviteRequest({
  partyId,
  roles,
  userContactId,
  partyContact,
  invite,
  unbound,
  linkedItems,
  subject,
  body,
}) {
  let req;

  if (partyId) {
    req = {
      ...req,
      partyId,
      subject,
      body,
      invite,
    };
  } else if (partyContact) {
    if (!roles || !roles.length) {
      throw new Error('Missing roles.');
    }

    req = {
      invite: !unbound && Boolean(invite),
      unbound: Boolean(unbound),
      linkedItems,
      subject,
      body,
      roles,
      contact: getContactWithGlideUserContactSource(
        partyContact,
        userContactId
      ),
    };
  } else {
    throw new Error('Requires partyId, or partyContact.');
  }
  return req;
}

// this intent will be only used for inviting a new party to be the primary agent when the tmPartyModalFlag enabled
export function invitePartyToPrimaryAgent({
  roles,
  userContactId,
  partyContact,
  invite,
  unbound,
  linkedItems,
  subject,
  body,
  promoteToPrimaryAgent,
}) {
  if (partyContact) {
    if (!roles || !roles.length) {
      throw new Error('Missing roles.');
    }

    const req = {
      invite: !unbound && Boolean(invite),
      unbound: Boolean(unbound),
      linkedItems,
      subject,
      body,
      roles,
      contact: getContactWithGlideUserContactSource(
        partyContact,
        userContactId
      ),
      promoteToPrimaryAgent,
    };
    return req;
  }
  throw new Error('Requires partyId or partyContact.');
}

export function updatePartyContactDetails(party) {
  return {
    kind: INTENTS.UPDATE_PARTY_CONTACT_DETAILS,
    updatePartyContactDetails: {
      partyId: party.id,
      contact: toJS(party.contact),
      roles: toJS(party.roles),
    },
  };
}

// this intent will be only used for making an existing party to be the primary agent when the tmPartyModalFlag enabled
export function updatePartyToPrimaryAgent(party) {
  return {
    kind: INTENTS.UPDATE_PARTY_CONTACT_DETAILS,
    updatePartyContactDetails: {
      partyId: party.id,
      contact: toJS(party.contact),
      roles: toJS(party.roles),
      promoteToPrimaryAgent: party.promoteToPrimaryAgent,
    },
  };
}

export function partyRemove(party) {
  return {
    kind: INTENTS.PARTY_REMOVES,
    partyRemoves: {
      removes: [
        {
          partyId: party.id,
        },
      ],
    },
  };
}

export function partyInviteContactRequest(partyId, subject, body) {
  if (!partyId) {
    throw new Error('Missing party.');
  }

  if (!subject) {
    throw new Error('Missing subject.');
  }

  if (!body) {
    throw new Error('Missing body.');
  }

  return {
    partyId,
    subject,
    body,
  };
}

export function replacePrimaryAgent(partyId) {
  return {
    kind: INTENTS.REPLACE_PRIMARY_AGENT,
    replacePrimaryAgent: {
      partyId,
    },
  };
}

export function uploadNewDocumentsIntent({
  folderId,
  files,
  tdvId,
  isFromDocumentsPage = true,
  taskId,
}) {
  return {
    createTransactionDocumentVersions: {
      newVersions: files.map((file) => ({
        ...(!tdvId
          ? {
              newTransactionDocument: {
                title: file.filename,
                folderId,
                taskId,
              },
            }
          : {
              transactionDocumentId: tdvId,
            }),
        source: {
          kind: 'UPLOAD',
          upload: {
            url: file.url,
            filename: file.filename,
            byteSize: file.byteSize,
          },
        },
      })),
      isFromDocumentsPage,
    },
    kind: INTENTS.CREATE_TRANSACTION_DOCUMENT_VERSIONS,
  };
}

export function copyDocumentsIntent({ tdvIds, folderId }) {
  return {
    createTransactionDocumentVersions: {
      newVersions: tdvIds.map((transactionDocumentVersionId) => ({
        newTransactionDocument: {
          folderId,
        },
        source: {
          kind: 'COPY',
          copy: {
            transactionDocumentVersionId,
          },
        },
      })),
    },
    kind: INTENTS.CREATE_TRANSACTION_DOCUMENT_VERSIONS,
  };
}

export function createTDV({ versions }) {
  return {
    createTransactionDocumentVersions: {
      newVersions: versions,
    },
    kind: INTENTS.CREATE_TRANSACTION_DOCUMENT_VERSIONS,
  };
}

export function mergeTransactionDocumentsIntent({
  transactionDocumentVersionIds,
  filename,
  folderId,
  deleteOriginalDocuments,
}) {
  const source = {
    kind: 'MERGE',
    merge: {
      transactionDocumentVersionIds,
      filename,
    },
  };
  return {
    splitOrMergeTdvs: {
      newVersions: [
        {
          source,
          newTransactionDocument: {
            title: filename,
            folderId,
          },
        },
      ],
      deleteOriginalDocuments,
    },
    async: true,
    kind: INTENTS.SPLIT_OR_MERGE_TDVS,
  };
}

export function splitTransactionDocumentVersionIntent({
  transactionDocumentVersionId,
  deleteOriginalDocuments,
  splits,
}) {
  const newVersions = splits.map((split) => {
    const td = {
      newTransactionDocument: {
        folderId: split.folderId,
        title: split.title,
        taskId: split.taskId,
      },
    };
    const source = {
      kind: 'SPLIT',
      split: {
        transactionDocumentVersionId,
        startPage: split.startPage,
        endPage: split.endPage,
        filename: split.title,
      },
    };
    return {
      source,
      ...td,
    };
  });
  return {
    splitOrMergeTdvs: {
      newVersions,
      deleteOriginalDocuments,
    },
    async: true,
    kind: INTENTS.SPLIT_OR_MERGE_TDVS,
  };
}

export function recombineTransactionDocuments({
  order,
  rotationByPage,
  transactionDocumentId,
  transactionDocumentVersionId,
}) {
  const source = {
    kind: 'RECOMBINE',
    recombine: {
      transactionDocumentVersionId,
      splits: order.map((o) => ({
        startPage: o - 1,
        endPage: o - 1,
        ...(rotationByPage[o] ? { rotation: rotationByPage[o] } : null),
      })),
    },
  };
  return {
    createTransactionDocumentVersions: {
      newVersions: [
        {
          source,
          transactionDocumentId,
        },
      ],
    },
    kind: INTENTS.CREATE_TRANSACTION_DOCUMENT_VERSIONS,
  };
}

export const fillDocuments = ({ tdvs, forms }, folderId, fieldValues = {}) => {
  const getNewVersion = (tdv, form) => ({
    ...(form
      ? {
          newTransactionDocument: {
            folderId,
          },
        }
      : {}),
    ...(tdv
      ? {
          transactionDocumentId: tdv.tdId,
        }
      : {}),
    source: {
      kind: 'FILLED_FORM',
      filledForm: {
        ...(tdv
          ? {
              transactionDocumentVersionId: tdv.id,
            }
          : {}),
        ...(form
          ? {
              formId: form.id,
            }
          : {}),
        // fieldValues: Object.entries(fieldValues || {}).reduce((fv, [ k, v ]) => ({
        //   ...fv,
        // case number to string with one decimal
        //   [k]: isNaN(parseFloat(v)) ? v.toString() : parseFloat(v).toFixed(1),
        // }), {}),
        fieldValues,
      },
    },
  });
  const getTdvsNewVersion = (tdv) => getNewVersion(tdv);
  const getFormsNewVersion = (form) => getNewVersion(null, form);

  return {
    createTransactionDocumentVersions: {
      newVersions: (tdvs || [])
        .map((tdv) => getTdvsNewVersion(tdv))
        .concat((forms || []).map((form) => getFormsNewVersion(form))),
    },
    kind: INTENTS.CREATE_TRANSACTION_DOCUMENT_VERSIONS,
    async: true,
  };
};

export const fillTdvs = (
  transactionDocumentVersionId,
  fieldValuesByNamespace = {},
  fieldIdsToUnlink = [],
  fieldIdsToLink = [],
  debounceActivities = false
) =>
  intent(INTENTS.FILL_TRANSACTION_DOCUMENT_FORM, {
    transactionDocumentVersionId,
    fieldValuesByNamespace,
    fieldIdsToUnlink,
    fieldIdsToLink,
    debounceActivities,
  });

export const annotateTdv = (tdvId, referenceId = null) =>
  intent(INTENTS.ANNOTATE_TDV, {
    tdvId,
    referenceId,
  });

export const tdUploads = (uploads) =>
  intent(INTENTS.TRANSACTION_DOCUMENT_UPLOADS, {
    uploads,
  });

export const getOrCreateFormOutlineFlow = (tdvId) =>
  intent(INTENTS.GET_OR_CREATE_FORM_OUTLINE_FLOW, {
    tdvId,
  });

export const importForms = (imports, folderId, taskId = null) =>
  intent(INTENTS.IMPORT_FORMS, {
    imports,
    folderId,
    taskId,
  });

export function createSignatureRequestFlow({
  transactionDocumentsIds,
  tdvIds,
  recipients,
  back,
  callbackPath = '',
  destFolderId = '',
  transactionPackageId = '',
}) {
  return intent(INTENTS.CREATE_SIGNATURE_REQUEST_FLOW, {
    transactionDocumentsIds,
    tdvIds,
    recipients,
    back,
    callbackPath,
    destFolderId,
    transactionPackageId,
  });
}

export function createSignatureRequest({
  transactionDocumentIds,
  recipients,
  recipientsInfo,
  body,
  subject,
  callbackPath,
  tokenId,
  autoTab,
  dsDefault,
  moveOriginalToTrash,
  destFolderId,
  disableEvents,
  provider,
  skipPreparation,
  signatureRequestId,
  transactionPackage,
  embedded,
}) {
  return intent(INTENTS.CREATE_SIGNATURE_REQUEST, {
    transactionDocumentIds,
    recipients,
    recipientsInfo,
    tokenId,
    body,
    subject,
    autoTab,
    dsDefault,
    callbackPath,
    moveOriginalToTrash,
    skipPreparation,
    // Use this field if you'd like all signed docs to go into this folder
    destFolderId,
    disableEvents,
    provider,
    signatureRequestId,
    transactionPackage,
    embedded,
  });
}

export function reviseSignatureRequest({
  transactionDocumentIds,
  recipients,
  recipientsInfo,
  body,
  subject,
  signatureRequestId,
}) {
  return intent(INTENTS.REVISE_SIGNATURE_REQUEST, {
    transactionDocumentIds,
    recipients,
    recipientsInfo,
    body,
    subject,
    signatureRequestId,
  });
}

export function createScheduleMeetingFlow({ meetingType, taskId }) {
  return intent(INTENTS.CREATE_SCHEDULE_MEETING_FLOW, {
    meetingType,
    taskId,
  });
}

export function deleteItems(ids) {
  invariant(ids && ids.length, 'ids must be non empty.');
  return {
    deleteItems: {
      ids,
    },
    kind: INTENTS.DELETE_ITEMS,
  };
}

export function deletePackets(ids) {
  return deleteItems(ids);
}

export function updatePacketCoverSheet({ coverSheet, escrow, brokerLogoUrl }) {
  return {
    updatePacketCoverSheet: {
      coverSheet,
      escrow,
      brokerLogoUrl,
    },
    kind: INTENTS.UPDATE_PACKET_COVER_SHEET,
  };
}

export function refreshSignatureRequest(signatureRequestId) {
  invariant(signatureRequestId, 'signatureRequestId is required');
  return {
    refreshSignatureRequest: {
      signatureRequestId,
    },
    kind: INTENTS.REFRESH_SIGNATURE_REQUEST,
  };
}

export function duplicateSignatureRequest(signatureRequestId) {
  invariant(signatureRequestId, 'signatureRequestId is required');
  return {
    duplicateSignatureRequest: {
      signatureRequestId,
    },
    kind: INTENTS.DUPLICATE_SIGNATURE_REQUEST,
  };
}

export function signatureRequestStartRevision(signatureRequestId) {
  invariant(signatureRequestId, 'signatureRequestId is required');
  return {
    signatureRequestStartRevision: {
      signatureRequestId,
    },
    kind: INTENTS.SIGNATURE_REQUEST_START_REVISION,
  };
}

export function signatureRequestCancelRevision(signatureRequestId) {
  invariant(signatureRequestId, 'signatureRequestId is required');
  return {
    signatureRequestCancelRevision: {
      signatureRequestId,
    },
    kind: INTENTS.SIGNATURE_REQUEST_CANCEL_REVISION,
  };
}

export function voidSignatureRequest(signatureRequestId, voidReason) {
  invariant(signatureRequestId, 'signatureRequestId is required');
  invariant(voidReason, 'voidReason is required');
  return {
    voidSignatureRequest: {
      signatureRequestId,
      voidReason,
    },
    kind: INTENTS.VOID_SIGNATURE_REQUEST,
  };
}

export function resendSignatureRequest(signatureRequestId) {
  invariant(signatureRequestId, 'signatureRequestId is required');
  return {
    resendSignatureRequest: {
      signatureRequestId,
    },
    kind: INTENTS.RESEND_SIGNATURE_REQUEST,
  };
}

export function archiveSignatureRequest(updates) {
  return {
    updateSignatureRequestsArchivalStatus: {
      updates,
    },
    kind: INTENTS.UPDATE_SIGNATURE_REQUESTS_ARCHIVAL_STATUS,
  };
}

export const createFolder = (title, aggregateItemId) =>
  intent(INTENTS.FOLDER_CREATES, {
    creates: [
      {
        title,
        aggregateItemId,
      },
    ],
  });

export function renameFolderIntent({ folderId, title }) {
  return {
    folderRenames: {
      renames: [
        {
          folderId,
          title,
        },
      ],
    },
    kind: INTENTS.FOLDER_RENAMES,
  };
}

export function deleteFolders(folderIds) {
  return deleteItems(folderIds);
}

export function cleanTransactionDocuments(transactionDocumentIds) {
  return {
    cleanTransactionDocuments: {
      transactionDocumentIds,
    },
    kind: INTENTS.CLEAN_TRANSACTION_DOCUMENTS,
  };
}

export function reorderFolders(folders) {
  let unstageArr = [];

  return {
    reorderFolders: {
      folders,
    },
    kind: INTENTS.REORDER_FOLDERS,
    optimistic: {
      stage: (transaction) => {
        unstageArr = folders
          .map(({ folderId, orderIndex }) => {
            const folder = transaction.store.itemsById.get(folderId);
            if (!folder) {
              return null;
            }

            return folder.stage({
              [camelCase(folder.kind)]: {
                orderIndex,
              },
            });
          })
          .filter((cb) => cb);
      },
      unstage: () => {
        unstageArr.forEach((cb) => cb());
      },
    },
  };
}

export const restoreDocuments = (restores) =>
  intent(INTENTS.RESTORE_TRANSACTION_DOCUMENTS, {
    restores,
  });

export const seeTransactionDocuments = (tdvIds) =>
  intent(INTENTS.SEE_TRANSACTION_DOCUMENTS, {
    tdvIds,
  });

export const seeTransactionDocumentsLatestVersions = (tds) =>
  seeTransactionDocuments(tds.map((td) => td.latestVersionId));

export function renameTransactionDocuments(requestedUpdates) {
  return {
    renameTransactionDocuments: {
      requestedUpdates,
    },
    kind: INTENTS.RENAME_TRANSACTION_DOCUMENTS,
  };
}

export function updateTransactionInfo({
  propertyInfo,
  listingInfo,
  purchaseInfo,
  diff,
}) {
  const payload = {};

  if (propertyInfo && diff.propertyInfo) {
    payload.propertyInfo = updateClonedItemJS(
      propertyInfo,
      pick(diff, 'propertyInfo')
    );
  }

  if (listingInfo && diff.listingInfo) {
    payload.listingInfo = updateClonedItemJS(
      listingInfo,
      pick(diff, 'listingInfo')
    );
  }

  if (purchaseInfo && diff.purchaseInfo) {
    payload.purchaseInfo = updateClonedItemJS(
      purchaseInfo,
      pick(diff, 'purchaseInfo')
    );
  }

  return {
    updateTransactionInfo: payload,
    kind: INTENTS.UPDATE_TRANSACTION_INFO,
  };
}

export function updateTimelineDates({ dates, deleteOthers }) {
  return {
    updateTimelineDates: {
      dates,
      deleteOthers,
    },
    kind: INTENTS.UPDATE_TIMELINE_DATES,
  };
}

export function updateKeyDateCompletionStatus({ uuid, isComplete }) {
  return {
    updateKeyDateCompletionStatus: {
      uuid,
      isComplete,
    },
    kind: INTENTS.UPDATE_KEY_DATE_COMPLETION_STATUS,
  };
}

export function splitTimelineDocuments({ splits }) {
  return {
    kind: INTENTS.SPLIT_TIMELINE_DOCUMENTS,
    splitTimelineDocuments: {
      splits,
    },
  };
}

export function updateTimelineSetup(setup) {
  return {
    updateTimelineSetup: {
      setup,
    },
    kind: INTENTS.UPDATE_TIMELINE_SETUP,
  };
}

export function updateTimelineTeam(team) {
  return {
    updateTimelineTeam: {
      team,
    },
    kind: INTENTS.UPDATE_TIMELINE_TEAM,
  };
}

export function removeTimelineDate({ uuid }) {
  return {
    removeTimelineDate: {
      uuid,
    },
    kind: INTENTS.REMOVE_TIMELINE_DATE,
  };
}

export function detectTimelineForms() {
  return {
    kind: INTENTS.DETECT_TIMELINE_FORMS,
  };
}

export function detectPackageForms({ packageId, flowId }) {
  return {
    detectPackageForms: {
      packageId,
      flowId,
    },
    kind: INTENTS.DETECT_PACKAGE_FORMS,
  };
}

export function updatePackageOcrStatus({ packageId, ocrStatus }) {
  return {
    updatePackageOcrStatus: {
      packageId,
      ocrStatus,
    },
    kind: INTENTS.UPDATE_PACKAGE_OCR_STATUS,
  };
}

export function updateCoverPhoto(url) {
  return {
    updateCoverPhoto: {
      url,
    },
    kind: INTENTS.UPDATE_COVER_PHOTO,
  };
}

export function prepareTimelineSetup() {
  return {
    prepareTimelineSetup: {},
    kind: INTENTS.PREPARE_TIMELINE_SETUP,
  };
}

export function updateArchivalStatus(archived) {
  return {
    kind: INTENTS.UPDATE_ARCHIVAL_STATUS,
    updateArchivalStatus: {
      archived,
    },
    optimistic: {
      stage: (transaction) => {
        transaction.stage({
          meta: {
            archived,
          },
        });
      },
      fail: (err, transaction) => {
        transaction.unstage();
      },
    },
  };
}

export function updateTransactionState(state, options) {
  const { cancellationMeta, optimistic } = {
    optimistic: true,
    ...options,
  };
  return {
    kind: INTENTS.UPDATE_TRANSACTION_STATE,
    updateTransactionState: {
      state,
      cancellationMeta,
    },
    optimistic: optimistic
      ? {
          stage: (transaction) => {
            transaction.stage({
              meta: {
                state,
              },
            });
          },
          fail: (err, transaction) => {
            transaction.unstage();
          },
        }
      : undefined,
  };
}

export function uploadPropertyImage({ url, propertyItemId }) {
  return {
    uploadPropertyImage: {
      url,
      propertyItemId,
    },
    kind: INTENTS.UPLOAD_PROPERTY_IMAGE,
  };
}

export function approveCancellation() {
  return updateTransactionState('CANCELLED');
}

export function reactivate(transaction) {
  invariant(
    transaction.cancellationMeta.prevStatus,
    'Must have previous status'
  );

  /*
  For purchase transactions with a primary address that were cancelled
  in a state earlier than "Under contract" before the addressless feature
  was enabled, we are patching the status to be set to UNDER_CONTRACT
  to prevent issues or confusion. If users want to set state back to the
  real one the transaction was in prior to cancellation, they'll need to
  deal with the property demotion UX explicitly before doing so.
  */
  let newStatus = transaction.cancellationMeta.prevStatus;
  const UNDER_CONTRACT = 'UNDER_CONTRACT';
  if (
    transaction.isPurchase &&
    transaction.hasAddress &&
    PURCHASE_STATUSES.indexOf(newStatus) <
      PURCHASE_STATUSES.indexOf(UNDER_CONTRACT)
  ) {
    newStatus = UNDER_CONTRACT;
  }

  return updateTransactionState(newStatus);
}

export function updateTemplateSetting(status, title) {
  return {
    kind: INTENTS.UPDATE_TEMPLATE_SETTING,
    updateTemplateSetting: {
      status,
      title,
    },
  };
}

export function updateTransactionDocumentsArchivalStatus(updates) {
  return {
    kind: INTENTS.UPDATE_TRANSACTION_DOCUMENTS_ARCHIVAL_STATUS,
    updateTransactionDocumentsArchivalStatus: {
      updates,
    },
  };
}

export function updateTransactionDocumentsNotes(updates) {
  return {
    kind: INTENTS.UPDATE_TRANSACTION_DOCUMENTS_NOTES,
    updateTransactionDocumentsNotes: {
      updates,
    },
  };
}

export function createGfpCreationFlow({
  taskId,
  ffcIds,
  redirectToRoute,
} = {}) {
  return {
    kind: INTENTS.CREATE_GFP_CREATION_FLOW,
    createGfpCreationFlow: {
      taskId,
      ffcIds,
      redirectToRoute,
    },
  };
}

export function getOrCreateInitialAvidFlow({ taskId } = {}) {
  return {
    kind: INTENTS.GET_OR_CREATE_INITIAL_AVID_FLOW,
    getOrCreateInitialAvidFlow: {
      taskId,
    },
  };
}

export function createUpdateAvidFillPartyFlow({ taskId } = {}) {
  return {
    kind: INTENTS.CREATE_UPDATE_AVID_FILL_PARTY_FLOW,
    createUpdateAvidFillPartyFlow: {
      taskId,
    },
  };
}

export function gfpCreateAddFormsFlow(
  gfpId,
  ffcIds = [],
  redirectToRoute = undefined
) {
  return {
    kind: INTENTS.GFP_CREATE_ADD_FORMS_FLOW,
    gfpCreateAddFormsFlow: {
      gfpId,
      ffcIds,
      redirectToRoute,
    },
  };
}

export function getOrCreateTimelineSetupFlow(mode) {
  return {
    kind: INTENTS.GET_OR_CREATE_TIMELINE_SETUP_FLOW,
    getOrCreateTimelineSetupFlow: {
      mode,
    },
  };
}

export function updateTimelineDate(keyDates) {
  return {
    kind: INTENTS.UPDATE_TIMELINE_DATE,
    updateTimelineDate: {
      keyDates,
    },
  };
}

export function gfpAccessInPerson(gfpId) {
  return {
    kind: INTENTS.GFP_ACCESS_IN_PERSON,
    gfpAccessInPerson: {
      gfpId,
    },
  };
}

export function gfpGetSubmitFlow(gfpId) {
  return intent(INTENTS.GFP_GET_SUBMIT_FLOW, {
    gfpId,
  });
}

export function gfpPremiumUpgrade(
  gfpId,
  stripeSourceId,
  inFlow,
  trigger,
  payLater
) {
  return intent(INTENTS.GFP_PREMIUM_UPGRADE, {
    gfpId,
    stripeSourceId,
    inFlow,
    trigger,
    payLater,
  });
}

export function createSharePacket() {
  return {
    kind: INTENTS.CREATE_SHARE_PACKET,
    createSharePacket: {},
  };
}

export function createTimelineTask() {
  return {
    kind: INTENTS.CREATE_TIMELINE_TASK,
    createTimelineTask: {},
  };
}

export function updateSharePacketLinkShareMode({
  sharePacketId,
  linkShareMode,
  notifyVisitorAccess,
}) {
  return intent(
    INTENTS.UPDATE_SHARE_PACKET_LINK_SHARE_MODE,
    {
      sharePacketId,
      linkShareMode,
      notifyVisitorAccess,
    },
    {
      optimistic: {
        stage: (transaction) => {
          const sharePacket = transaction.store.itemsById.get(sharePacketId);
          if (sharePacket) {
            sharePacket.stage({
              [camelCase(sharePacket.kind)]: {
                linkShareMode,
              },
            });
          }
        },
        unstage: (transaction) => {
          const sharePacket = transaction.store.itemsById.get(sharePacketId);
          if (sharePacket) {
            sharePacket.unstage();
          }
        },
      },
    }
  );
}

export function rejectSharedItemRequest({ requestId }) {
  return {
    kind: INTENTS.REJECT_SHARED_ITEM_REQUEST,
    rejectSharedItemRequest: {
      requestId,
    },
  };
}

export function revokeSharedItemAccess({ sharedItemIds }) {
  return {
    kind: INTENTS.REVOKE_SHARED_ITEM_ACCESS,
    revokeSharedItemAccess: {
      sharedItemIds,
    },
  };
}

export function approveSharedItemRequest({ requestId }) {
  return {
    kind: INTENTS.APPROVE_SHARED_ITEM_REQUEST,
    approveSharedItemRequest: {
      requestId,
    },
  };
}

export function inviteToSharePacket({
  recipients,
  packetId,
  message,
  canForward,
}) {
  return {
    kind: INTENTS.INVITE_TO_SHARE_PACKET,
    inviteToSharePacket: {
      packetId,
      recipients,
      message,
      accessType: canForward ? 'SHARE_ADMIN' : 'SHARE_VIEWER',
    },
  };
}

export function publishSharePacket({ notify, sharePacketId, message }) {
  return {
    kind: INTENTS.PUBLISH_SHARE_PACKET,
    publishSharePacket: {
      sharePacketId,
      notifyParties: notify,
      message,
    },
  };
}

export function createGfpPreview({ formFlowConfigId }) {
  return {
    kind: INTENTS.CREATE_GUIDED_FORM_PREVIEW,
    createGuidedFormPreview: {
      formFlowConfigId,
    },
  };
}

export function resendGfpInvite(gfpId, partyId) {
  return {
    kind: INTENTS.RESEND_GFP_INVITE,
    resendGfpInvite: {
      gfpId,
      partyId,
    },
  };
}

export function sendSharePacketMessage({
  subject,
  body,
  sharedItemIds,
  sharePacketId,
  fromPartyId,
}) {
  return {
    kind: INTENTS.SEND_SHARE_PACKET_MESSAGE,
    sendSharePacketMessage: {
      subject,
      body,
      sharedItemIds,
      sharePacketId,
      fromPartyId,
    },
  };
}

export function updateSharePacketAccessStatus(sharePacketId, accessStatus) {
  return intent(INTENTS.UPDATE_SHARE_PACKET_ACCESS_STATUS, {
    sharePacketId,
    accessStatus,
  });
}

export function updateGfpCc(gfpId, cc) {
  return {
    kind: INTENTS.UPDATE_GFP_CC,
    updateGfpCc: {
      gfpId,
      cc,
    },
  };
}

export function gfpSubmit(gfpId, skipEmail, skipChore, allowAuto) {
  return {
    kind: INTENTS.GFP_SUBMIT,
    gfpSubmit: {
      gfpId,
      skipEmail,
      skipChore,
      allowAuto,
    },
  };
}

export function gfpRecordComment({ gfpId, notify }) {
  return {
    kind: INTENTS.GFP_RECORD_COMMENT,
    gfpRecordComment: {
      notify,
      gfpId,
      clear: true,
    },
  };
}

export function gfpRemoveGuidedFormPacketFlow({ gfpfId }) {
  return {
    kind: INTENTS.GFP_REMOVE_GUIDED_FORM_PACKET_FLOW,
    gfpRemoveGuidedFormPacketFlow: {
      gfpfId,
    },
  };
}

export function gfpSetLocked(gfpId, locked) {
  return {
    kind: INTENTS.GFP_SET_LOCKED,
    gfpSetLocked: {
      gfpId,
      locked,
    },
  };
}

export function createLabel({ label, color }) {
  return intent(INTENTS.CREATE_LABELS, {
    labels: [
      {
        label,
        color,
      },
    ],
  });
}

export function updateLabel(labelItem, { label, color }) {
  labelItem.stage();
  labelItem.label = label;
  labelItem.color = color;

  return {
    kind: INTENTS.UPDATE_LABELS,
    updateLabels: {
      labels: [toJS(labelItem.data)],
    },
    optimistic: {
      unstage: () => {
        labelItem.unstage();
      },
    },
  };
}

export function gfpUploadFlowAttachments({ newAttachments, gfpfId, fieldId }) {
  return {
    kind: INTENTS.GFP_UPLOAD_FLOW_ATTACHMENTS,
    gfpUploadFlowAttachments: {
      newAttachments: newAttachments.map(
        ({ filename, url, byteSize, thumbnailUrl }) => ({
          filename,
          url,
          byteSize,
          thumbnailUrl,
        })
      ),
      gfpfId,
      fieldId,
    },
  };
}

export function applyLabels(updates) {
  return {
    kind: INTENTS.APPLY_LABELS,
    applyLabels: {
      updates,
    },
    optimistic: {
      stage: (transaction) => {
        updates.forEach((up) => {
          const item = transaction.store.itemsById.get(up.itemId);
          if (!item) {
            return;
          }

          const existingLabelsIds = item.labels.map((l) => l.id);

          const addLabelsIds = up.addLabelsIds || [];

          const addEdges = addLabelsIds
            .filter((labelId) => !existingLabelsIds.includes(labelId))
            .map((labelId) => transaction.store.itemsById.get(labelId))
            .filter((label) => label)
            .map((label) => ({
              kind: `${item.kind}_HAS_LABEL`,
              item1Id: item.id,
              item2Id: label.id,
            }));

          const removeLabelsIds = up.removeLabelsIds || [];
          const newOutEdges = item.outEdges
            .filter((e) => !removeLabelsIds.includes(e.item2Id))
            .concat(addEdges);
          item.stage({
            outEdges: newOutEdges,
          });
        });
      },
      unstage: (transaction) => {
        updates.forEach((up) => {
          const item = transaction.store.itemsById.get(up.itemId);
          if (!item) {
            return;
          }
          item.unstage();
        });
      },
    },
  };
}

export function deleteLabels(ids) {
  return deleteItems(ids);
}

export function createInvoice(invoice) {
  return intent(intent.INTENTS.CREATE_INVOICE, {
    invoice,
  });
}

export function updateInvoice(invoiceItem, fields) {
  let invoice = invoiceItem.toJS();
  invoice = {
    ...invoice,
    invoice: {
      ...invoice.invoice,
      ...fields,
    },
  };

  return intent(intent.INTENTS.UPDATE_INVOICE, {
    invoice,
  });
}

export function stampTdv(stamps) {
  return intent(intent.INTENTS.STAMP_TDV, {
    stamps,
  });
}

export const setFieldValue = () => {
  return {};
};

export const setZfLink = (tokenId, txnId) =>
  intent(intent.INTENTS.SET_ZF_LINK, {
    tokenId,
    txnId,
  });

export const sellerDiscAppSetCurrStep = (currentStepId) =>
  intent(intent.INTENTS.SELLER_DISC_APP_SET_CURR_STEP, {
    currentStepId,
  });

export const sellerDiscAppCreateWfSf = () =>
  intent(intent.INTENTS.SELLER_DISC_APP_CREATE_WF_SF);

export const toggleChecklists = (
  toggles,
  addRelatedForms = false,
  async = false
) => {
  return intent(
    intent.INTENTS.TOGGLE_CHECKLISTS,
    {
      toggles,
      addRelatedForms,
    },
    {
      async: !!async || !!addRelatedForms, // always run async if there are forms to assign
    }
  );
};

export const copyChecklistTemplates = (ids) =>
  intent(intent.INTENTS.COPY_CHECKLIST_TEMPLATES, {
    ids,
  });

export const recordComment = ({
  itemId,
  message,
  clientId,
  inviteSubject,
  inviteBody,
}) =>
  intent(intent.INTENTS.RECORD_COMMENT, {
    itemId,
    message,
    clientId,
    inviteSubject,
    inviteBody,
  });

export const updateCliStatusExempt = (updates) =>
  intent(intent.INTENTS.CHECKLIST_ITEM_UPDATE_IS_EXEMPT, {
    updates,
  });

export const updateCliStatusRequired = (updates) =>
  intent(intent.INTENTS.CHECKLIST_ITEM_UPDATE_REQUIRED, {
    updates,
  });

export const updateFileReview = ({
  fileReviewId,
  reviewedChecklistItems,
  materializeChanges = true,
  finishReview = false,
}) =>
  intent(intent.INTENTS.UPDATE_FILE_REVIEW, {
    fileReviewId,
    reviewedChecklistItems,
    materializeChanges,
    finishReview,
  });

export const finishFileReview = (
  fileReviewId,
  setFullyApprovedReviewState = false,
  fileReviewComment = ''
) =>
  intent(intent.INTENTS.FINISH_FILE_REVIEW, {
    fileReviewId,
    setFullyApprovedReviewState,
    fileReviewComment,
  });

export const cancelFileReview = (fileReviewId, revertChanges = true) =>
  intent(intent.INTENTS.CANCEL_FILE_REVIEW, {
    fileReviewId,
    revertChanges,
  });

export const setFullyApprovedReviewState = (undo = false) =>
  intent(intent.INTENTS.SET_FULLY_APPROVED_REVIEW_STATE, {
    undo,
  });

export const getOrCreateCommissionAdvanceFlow = (taskId) =>
  intent(intent.INTENTS.GET_OR_CREATE_COMMISSION_ADVANCE_FLOW, {
    taskId,
  });

export const applyTemplates = (templateIds, propertiesIds = []) =>
  intent(intent.INTENTS.APPLY_TEMPLATES, {
    templateIds,
    targetPropertyInfoIds: propertiesIds,
  });

export const createTodaysPeadFlow = (addresses) =>
  intent(intent.INTENTS.CREATE_TODAYS_PEAD_FLOW, {
    addresses,
  });

export const createPeadEsignFlow = (pead, options) =>
  intent(intent.INTENTS.CREATE_PEAD_ESIGN_FLOW, {
    ...options,
    peadId: pead.id || pead,
  });

export const createCommsDocument = ({ taskId, allParties, partyIds }) =>
  intent(intent.INTENTS.CREATE_COMMS_DOCUMENT, {
    taskId,
    allParties,
    partyIds,
  });

export const sendSsoReminder = ({ provider }) =>
  intent(intent.INTENTS.SEND_SSO_REMINDER, {
    provider,
  });

export const sendEmail = (data) => intent(intent.INTENTS.SEND_EMAIL, data);

export const updateOfferArchivalStatus = (offerId, archived) =>
  intent(intent.INTENTS.UPDATE_OFFER_ARCHIVAL_STATUS, {
    offerId,
    archived: Boolean(archived),
  });

export const updateOfferFavoriteStatus = (
  offerId,
  favorite,
  optimistic = true
) => {
  let unstage;
  return intent(
    intent.INTENTS.UPDATE_OFFER_FAVORITE_STATUS,
    {
      offerId,
      favorite: Boolean(favorite),
    },
    optimistic
      ? {
          optimistic: {
            stage: (transaction) => {
              const offer = transaction.store.itemsById.get(offerId);
              unstage = () => offer.unstage();
              offer.stage({
                offer: {
                  favorite: Boolean(favorite),
                },
              });
            },
            unstage: () => {
              if (unstage) {
                unstage();
              }
            },
          },
        }
      : null
  );
};

export const setOfferNotes = (offerId, { publicNotes, privateNotes }) =>
  intent(intent.INTENTS.SET_OFFER_NOTES, {
    offerId,
    publicNotes: publicNotes || '',
    privateNotes: privateNotes || '',
  });

export const setOffersSortIndices = (offersSortIndices, optimistic = true) => {
  const unstageItems = [];
  return intent(
    intent.INTENTS.SET_OFFERS_SORT_INDICES,
    {
      offersSortIndices,
    },
    optimistic
      ? {
          optimistic: {
            stage: (transaction) => {
              (offersSortIndices || []).forEach(({ offerId, sortIndex }) => {
                const offer = transaction.store.itemsById.get(offerId);
                unstageItems.push(() => offer.unstage());
                offer.stage({
                  offer: {
                    sortIndex: parseInt(sortIndex, 10),
                  },
                });
              });
            },
            unstage: () => {
              unstageItems.forEach((unstage) => unstage());
            },
          },
        }
      : null
  );
};

export const updateOfferSubmissionPreferences = (submissionPreferences) =>
  intent(intent.INTENTS.UPDATE_OFFER_SUBMISSION_PREFERENCES, {
    submissionPreferences,
  });

export const saveOffersClientWorkspaceConfig = ({
  enabled,
  hideBuyersInfo,
  sendInviteToExistingSellers,
  newSellers,
  message,
}) =>
  intent(intent.INTENTS.UPDATE_OFFERS_CLIENT_WORKSPACE_CONFIG, {
    enabled,
    hideBuyersInfo,
    sendInviteToExistingSellers,
    newSellers,
    message: enabled ? message : undefined,
  });

export const setClientOffersSeen = (offerIds) =>
  intent(intent.INTENTS.SET_CLIENT_OFFERS_SEEN, {
    offerIds,
  });

export const voidOffer = (offerPackageId, reason, optimistic = true) => {
  let unstage;
  return intent(
    intent.INTENTS.VOID_OFFER_PACKAGE,
    {
      offerPackageId,
      reason,
    },
    optimistic
      ? {
          optimistic: {
            stage: (transaction) => {
              const offerPckg = transaction.store.itemsById.get(offerPackageId);
              unstage = () => offerPckg.unstage();
              offerPckg.stage({
                offer: {
                  status: 'VOIDED',
                },
              });
            },
            unstage: () => {
              if (unstage) {
                unstage();
              }
            },
          },
        }
      : null
  );
};

export const addTimelineDocuments = ({ fileUploads, tdvIds, dsDocs, dsSub }) =>
  intent(intent.INTENTS.ADD_TIMELINE_DOCUMENTS, {
    fileUploads,
    tdvIds,
    dsDocs,
    dsSub,
  });

export const removeTimelineDocuments = (tdvId) =>
  intent(intent.INTENTS.REMOVE_TIMELINE_DOCUMENTS, {
    tdvId,
  });

export const sendTimelineMessages = (message) =>
  intent(intent.INTENTS.SEND_TIMELINE_MESSAGES, {
    message,
  });

export const startPackageActionFlow = (
  packageId,
  actionType,
  skipWarnings = false,
  revision = false,
  skipNotifications = false,
  keyTermsOnly = false,
  ocrFields = {}
) =>
  intent(intent.INTENTS.START_PACKAGE_ACTION_FLOW, {
    packageId,
    actionType,
    skipWarnings,
    revision,
    skipNotifications,
    keyTermsOnly,
    ocrFields,
  });

export const updateBuyerProspectPropertyFields = ({
  fieldValues,
  itemIdsByNamespace,
  buyerProspectPropertyId,
}) =>
  intent(intent.INTENTS.UPDATE_BUYER_PROSPECT_PROPERTY_FIELDS, {
    fieldValues,
    itemIdsByNamespace,
    buyerProspectPropertyId,
  });

export function propertyUpdateFields({ fieldValuesByNamespace, propertyId }) {
  return intent(intent.INTENTS.PROPERTY_UPDATE_FIELDS, {
    fieldValuesByNamespace,
    propertyId,
  });
}

export function recordPackageAccessed(transactionPackageIds) {
  return intent(intent.INTENTS.RECORD_PACKAGE_ACCESSED, {
    transactionPackageIds,
  });
}

export function cancelPackagePendingAction(transactionPackageId) {
  return intent(intent.INTENTS.CANCEL_PACKAGE_PENDING_ACTION, {
    transactionPackageId,
  });
}

export function createPackagePendingAction(
  transactionPackageId,
  actionType,
  detached,
  revision = false
) {
  return intent(intent.INTENTS.CREATE_PACKAGE_PENDING_ACTION, {
    transactionPackageId,
    actionType,
    detached,
    revision,
  });
}

export function restoreOriginalTds(restores) {
  return intent(intent.INTENTS.RESTORE_ORIGINAL_TDS, {
    restores: restores.map((restore) => {
      return {
        tdId: isArray(restore) ? restore[0] : restore.toString(),
        keepCurrent: isArray(restore) ? Boolean(restore[1]) : false,
      };
    }),
  });
}

export function copyTds(sourceTdIds, { targetFolderId, targetNewFolder }) {
  return intent(intent.INTENTS.COPY_TDS, {
    transactionDocuments: {
      ids: sourceTdIds,
    },
    targetFolderId,
    targetNewFolder,
  });
}

export function copyFolder(
  sourceFolderId,
  { targetFolderId, targetNewFolder }
) {
  return intent(intent.INTENTS.COPY_TDS, {
    folderId: sourceFolderId,
    targetFolderId,
    targetNewFolder,
  });
}

export function updateTransactionMeta(data) {
  // Need to pass in values for the fields that should not be changed too
  return intent(intent.INTENTS.UPDATE_TRANSACTION_META, {
    data,
  });
}

export function getOrCreateFormBuilderFlow(tdId) {
  return intent(intent.INTENTS.GET_OR_CREATE_FORM_BUILDER_FLOW, {
    tdId,
  });
}

export function esignDetectionProvideUserFeedback(documentId, zoneFeedbacks) {
  invariant(documentId, 'documentId is required');
  invariant(zoneFeedbacks, 'zoneFeedbacks is required');
  return intent(intent.INTENTS.ESIGN_DETECTION_PROVIDE_USER_FEEDBACK, {
    documentId,
    zoneFeedbacks,
  });
}

export function updateOfferKeyTermsConfig(keyTerms) {
  return intent(intent.INTENTS.UPDATE_OFFER_KEY_TERMS_CONFIG, {
    keyTerms,
  });
}

export function clearOrgDetails() {
  return intent(intent.INTENTS.CLEAR_ORG_DETAILS, {});
}
