export const ARCHIVE = 'ARCHIVE';
export const DELETE = 'DELETE';
export const DOWNLOAD_GFP = 'DOWNLOAD_GFP';
export const DOWNLOAD_ATTACHMENTS = 'DOWNLOAD_ATTACHMENTS';
export const DOWNLOAD_AVID = 'DOWNLOAD_AVID';
export const SIGN_ATTACHMENTS = 'SIGN_ATTACHMENTS';
export const EMAIL_ATTACHMENTS = 'EMAIL_ATTACHMENTS';
export const ATTACH_DOCUMENTS = 'ATTACH_DOCUMENTS';
export const SCHEDULE_MEETING = 'SCHEDULE_MEETING';
export const COMPLETE_GFP = 'COMPLETE_GFP';
export const VIEW_DOCUSIGN = 'VIEW_DOCUSIGN';
export const VIEW_GFP = 'VIEW_GFP';
export const VIEW_GFP_AS_CLIENT = 'VIEW_GFP_AS_CLIENT';
export const VIEW_AVID = 'VIEW_AVID';
export const RESEND_GFP_INVITES = 'RESEND_GFP_INVITES';
export const SIGN_GFP = 'SIGN_GFP';
export const LOCK_GFP = 'LOCK_GFP';
export const SIGN_AVID = 'SIGN_AVID';
export const VIEW_SHARE_PACKET = 'VIEW_SHARE_PACKET';
export const EDIT_AVID_FLOW = 'EDIT_AVID_FLOW';
export const GENERATE_GFP = 'GENERATE_GFP';
export const EDIT_TRANSACTION_DETAILS = 'EDIT_TRANSACTION_DETAILS';
export const EDIT_TRANSACTION_PARTIES = 'EDIT_TRANSACTION_PARTIES';
export const AVID_INVITE = 'AVID_INVITE';
export const GFP_PREVIEW_PDFS = 'GFP_PREVIEW_PDFS';
export const GFP_ADD_FORMS = 'GFP_ADD_FORMS';
export const GFP_ACCESS_IN_PERSON = 'GFP_ACCESS_IN_PERSON';
export const EDIT_REMINDERS = 'EDIT_REMINDERS';
export const EDIT_COVER_SHEET = 'EDIT_COVER_SHEET';
export const IMPORT_ZIPFORM_DOCS = 'IMPORT_ZIPFORM_DOCS';
export const START_SIGNATURE_WIZARD = 'START_SIGNATURE_WIZARD';
export const OPEN_SHARE_PACKET_MODEL = 'OPEN_SHARE_PACKET_MODEL';
export const COMPLETE_TASK = 'COMPLETE_TASK';
export const GENERATE_AVID = 'GENERATE_AVID';
export const START_ORDER_NHD_WIZARD = 'START_ORDER_NHD_WIZARD';
export const VIEW_TASK = 'VIEW_TASK';
export const CANCEL_NHD_ORDER = 'CANCEL_NHD_ORDER';
export const REORDER_NHD_WIZARD = 'REORDER_NHD_WIZARD';
export const PEAD_CONTINUE = 'PEAD_CONTINUE';
export const PEAD_RESEND_SIGNATURES = 'PEAD_RESEND_SIGNATURES';
export const PEAD_SIGN = 'PEAD_SIGN';

export default [
  ARCHIVE,
  ATTACH_DOCUMENTS,
  AVID_INVITE,
  CANCEL_NHD_ORDER,
  COMPLETE_GFP,
  COMPLETE_TASK,
  DELETE,
  DOWNLOAD_ATTACHMENTS,
  DOWNLOAD_AVID,
  DOWNLOAD_GFP,
  EDIT_AVID_FLOW,
  EDIT_REMINDERS,
  EMAIL_ATTACHMENTS,
  GENERATE_AVID,
  GENERATE_GFP,
  GFP_ACCESS_IN_PERSON,
  GFP_ADD_FORMS,
  GFP_PREVIEW_PDFS,
  IMPORT_ZIPFORM_DOCS,
  LOCK_GFP,
  REORDER_NHD_WIZARD,
  OPEN_SHARE_PACKET_MODEL,
  PEAD_CONTINUE,
  PEAD_RESEND_SIGNATURES,
  PEAD_SIGN,
  RESEND_GFP_INVITES,
  SIGN_ATTACHMENTS,
  SIGN_AVID,
  SIGN_GFP,
  START_ORDER_NHD_WIZARD,
  START_SIGNATURE_WIZARD,
  VIEW_AVID,
  VIEW_DOCUSIGN,
  VIEW_GFP,
  VIEW_GFP_AS_CLIENT,
  VIEW_SHARE_PACKET,
  VIEW_SHARE_PACKET,
  VIEW_TASK,
];
