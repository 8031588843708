export default function navigateToFlow(router, flowId, options = {}) {
  const route = options.route || router.route;
  return router.navigatePromise(
    'flow.page',
    {
      flowId,
      path: options.path || 'root',
      back: options.noBack
        ? undefined
        : JSON.stringify({
            name: route.name,
            params: route.params,
          }),
      ffc_ids: options.ffcIds,
    },
    {
      replace: options.replace,
    }
  );
}

export function flowRoute(router, flowId, options = {}) {
  const route = options.route || router.route;
  return {
    name: 'flow.page',
    params: {
      flowId,
      path: options.path || 'root',
      back: options.noBack
        ? undefined
        : JSON.stringify({
            name: route.name,
            params: route.params,
          }),
      f: options.f,
    },
  };
}
