import { computed, makeObservable } from 'mobx';
import { getNamespaceScope, getNamespaceScopeKey } from './utils';

export default class ItemNamespace {
  item = undefined;

  constructor(item) {
    makeObservable(this);
    this.item = item;
  }

  @computed
  get hasNamespaceScopeEdgeKinds() {
    return (
      Object.entries(this.item.namespaceScopeEdgeKinds || {}).filter(
        ([k, v]) => k && v
      ).length > 0
    );
  }

  findEdgeItemIdByKind(kind) {
    return this.item.inEdges.find((e) => e.kind === kind)?.item1Id;
  }

  getNamespaceSourceItemId(kind, edgeKindsKey) {
    if (this.item.kind === kind) {
      return this.item.id;
    }

    if (!this.hasNamespaceScopeEdgeKinds) {
      return undefined;
    }
    return this.findEdgeItemIdByKind(
      this.item.namespaceScopeEdgeKinds[edgeKindsKey]
    );
  }

  @computed
  get transactionPackageId() {
    return this.getNamespaceSourceItemId(
      'TRANSACTION_PACKAGE',
      'transactionPackageEdgeKind'
    );
  }

  @computed
  get buyerProspectPropertyId() {
    return this.getNamespaceSourceItemId(
      'BUYER_PROSPECT_PROPERTY',
      'buyerProspectPropertyEdgeKind'
    );
  }

  @computed
  get propertyInfoId() {
    return this.getNamespaceSourceItemId(
      'PROPERTY_INFO',
      'propertyInfoEdgeKind'
    );
  }

  @computed
  get namespaceScopeParams() {
    return {
      transactionPackageId: this.transactionPackageId,
      buyerProspectPropertyId: this.buyerProspectPropertyId,
      propertyInfoId: this.propertyInfoId,
    };
  }

  @computed
  get namespaceScope() {
    return getNamespaceScope(this.namespaceScopeParams);
  }

  @computed
  get namespaceScopeKey() {
    return getNamespaceScopeKey(this.namespaceScopeParams);
  }
}
