import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import type { DocumentSplitRequestList } from 'src/types/proto/growth';
import type { SplitRequestsListRequest } from 'src/types/proto/services/growth_admin_service';
import Growth from '../public/growth';

export default class AdminGrowth extends Growth {
  listSplitRequests({ cursor, limit }: SplitRequestsListRequest) {
    const params = omitBy(
      {
        cursor,
        limit,
      },
      isNil
    );

    return this.get<DocumentSplitRequestList>('/documents/split_requests', {
      params,
    });
  }
}
