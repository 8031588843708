

import React from 'react';
import { Button } from 'antd';
import type { ButtonProps, ButtonType } from 'antd/lib/button';
import type { SizeType } from 'antd/lib/config-provider/SizeContext';
import classNames from 'classnames';
import invariant from 'invariant';
import { Tooltip, TooltipProps } from 'antd';

const clsPrefix = 'app-btn';

// TODO: default is not an AntD sizeType
const STANDARD_SIZES = ['small', 'default', 'large'];

const STANDARD_TYPES = [
  'default',
  'primary',
  'dashed',
  'danger',
  'link',
] as const;

export type AppButtonSize = ButtonProps['size'] | 'default' | 'xlarge';

export type AppButtonType =
  | ButtonProps['type']
  | typeof STANDARD_TYPES[number]
  | 'secondary'
  | 'purple'
  | 'red'
  | 'tertiary'
  | 'outline'
  | 'primary-outline'
  | 'secondary-outline';

export interface AppButtonProps extends Omit<ButtonProps, 'type' | 'size'> {
  size?: AppButtonSize;
  type?: AppButtonType;
  borderless?: boolean;
  innerRef?: React.Ref<HTMLElement>;
  disabledStyle?: boolean;
  tooltip?: TooltipProps;
}

const AppButton = ({
  className,
  size = 'default',
  type = 'default',
  ghost,
  shape,
  borderless,
  innerRef,
  disabledStyle,
  tooltip,
  ...otherProps
}: AppButtonProps) => {
  const isCustomSize = !STANDARD_SIZES.includes(size);
  const isCustomType = !STANDARD_TYPES.includes(
    type as typeof STANDARD_TYPES[number]
  );
  const isCustom = isCustomSize || isCustomType;
  invariant(
    (isCustom && !shape) || !isCustom,
    "Custom buttons don't support shapes."
  );
  invariant(
    (isCustom && !ghost) || !isCustom,
    "Custom buttons don't support ghost."
  );

  const fixedSize = size === 'default' ? 'middle' : size;

  const btn = (
    <Button
      {...otherProps}
      ref={innerRef}
      shape={shape}
      ghost={ghost}
      size={isCustomSize ? 'middle' : (fixedSize as SizeType)}
      type={(isCustomType ? 'default' : type) as ButtonType}
      className={classNames(
        clsPrefix,
        `${clsPrefix}--type-${type}`,
        `${clsPrefix}--size-${size}`,
        className,
        {
          [`${clsPrefix}--borderless`]: !!borderless,
          [`${clsPrefix}--disabled`]: !!disabledStyle,
        }
      )}
    />
  );

  return tooltip ? <Tooltip {...tooltip}>{btn}</Tooltip> : btn;
};

/**
 * Fix disabled button with tooltip
 * @see https://github.com/ant-design/ant-design/blob/master/components/tooltip/index.tsx#L85
 */
AppButton.__ANT_BUTTON = true;

export const sizes = [...STANDARD_SIZES, 'xlarge'];

export const types = [
  ...STANDARD_TYPES,
  'secondary',
  'purple',
  'red',
  'tertiary',
  'outline',
  'primary-outline',
  'secondary-outline',
];

export default AppButton;
