import invariant from 'invariant';
import type { AsyncGenerate } from 'src/types/proto/docusign';
import type { CarPetition } from 'src/types/proto/growth';
import type {
  CreateZipFormTransactionRequest,
  ExternalDocumentNode,
  ExternalDocumentsResponse,
  LinkedAccount,
  LinkedAccountConfig,
  LinkedAccountDetails,
  SharedToken,
  SharedTokenResponse,
  VerifyCarRstIdRequest,
  VerifyNrdsIdRequest,
  ZfAsyncGenerate,
  AuthResponse,
  IntegrationAccount,
  OauthToken,
} from 'src/types/proto/integrations';
import type { ZfTransaction } from 'src/types/proto/pyzipforms';
import type {
  GetDocusignEnvelopeDocumentsRequest,
  GetDocusignEnvelopeDocumentsResponse,
  GetDocusignEnvelopesRequest,
  GetDocusignEnvelopesResponse,
  AddZipFormTransactionFormRequest,
  ConnectZipFormRequest,
  DisconnectZipFormDuplicatesRequest,
  DisconnectZipFormRequest,
  GetZipFormDuplicatesRequest,
  GetZipFormDuplicatesResponse,
  GetZipFormTeamTokensRequest,
  GetZipFormTransactionLibrariesResponse,
  GetZipFormTransactionLibraryFormsResponse,
  GetZipFormTransactionRequest,
  GetZipFormTransactionsRequest,
  GetZipFormTransactionsResponse,
  SetZipFormLinkContinueRequest,
  GetQuickbooksCustomersRequest,
  GetQuickbooksCustomersResponse,
  ExchangeAuthCodeForLoginRequest,
  GetAccountAuthorizeUrlRequest,
  GetIntegrationsResponse,
  GetCarPetitionsResponse,
  GetMlsListingsResponse,
} from 'src/types/proto/services/integration_service';
import type {
  ApiRequestBody,
  ApiResponseData,
  DeepPartial,
} from 'src/types/utils';
import { formatToSnakeCase } from 'src/utils/format-data-object';
import BaseApi from '../base-api';

export default class Integrations extends BaseApi {
  getAccount(app: string, next?: string) {
    invariant(app, '`app` parameter not defined.');

    return this.get<IntegrationAccount>(
      `/integrations/${encodeURIComponent(app)}/account`,
      next
        ? {
            params: {
              next,
            },
          }
        : undefined
    );
  }

  getAccountById(app: string, tokenId: string, next?: string) {
    return this.get<IntegrationAccount>(
      `/integrations/${encodeURIComponent(app)}/${encodeURIComponent(tokenId)}`,
      next
        ? {
            params: {
              next,
            },
          }
        : undefined
    );
  }

  getAccountAuthorizeUrl(
    app: string,
    next: string,
    params: Omit<GetAccountAuthorizeUrlRequest, 'app'> = {}
  ) {
    invariant(app, '`app` parameter not defined.');

    if (next) {
      params.next = next;
    }

    return this.get<string>(
      `/integrations/${encodeURIComponent(app)}/authorize_url`,
      {
        params,
      }
    );
  }

  fetchAll(userId: string) {
    return this.get<ApiResponseData<GetIntegrationsResponse>>(
      '/integrations',
      userId
        ? {
            params: {
              user_id: userId,
            },
          }
        : undefined
    );
  }

  exchangeAuthCodeForLogin(
    app: string,
    {
      code,
      registerCode,
      inviteUuid,
      redirectUri,
    }: ApiRequestBody<ExchangeAuthCodeForLoginRequest>
  ) {
    return this.post<AuthResponse>(
      `/integrations/${encodeURIComponent(app)}/exchange_auth_code`,
      {
        code,
        register_code: registerCode,
        invite_uuid: inviteUuid,
        redirect_uri: redirectUri,
      }
    );
  }

  refresh(id: string) {
    return this.post<OauthToken>(
      `/integrations/tokens/${encodeURIComponent(id)}/refresh`,
      {}
    );
  }

  getDocusignEnvelopes({ sub }: GetDocusignEnvelopesRequest) {
    return this.get<GetDocusignEnvelopesResponse>(
      '/integrations/docusign/envelopes/',
      {
        params: {
          sub,
        },
      }
    );
  }

  getDocusignEnvelopeDocuments({
    sub,
    envelopeId,
  }: GetDocusignEnvelopeDocumentsRequest) {
    return this.get<ApiResponseData<GetDocusignEnvelopeDocumentsResponse>>(
      `/integrations/docusign/envelopes/${envelopeId}/documents`,
      {
        params: {
          sub,
        },
      }
    );
  }

  generateDocuSignDocs(transactionId: string, data: AsyncGenerate) {
    return this.post<void>(
      `/integrations/docusign/${transactionId}/generate`,
      data
    );
  }

  zipformConnect(userId: string, externalId: string) {
    return this.post<SharedToken>(
      `/integrations/zipform/connect/${userId}`,
      formatToSnakeCase({
        externalId,
      } as ApiRequestBody<ConnectZipFormRequest>)
    );
  }

  zipformDisconnect(userId: string, tokenId: string) {
    return this.post<void>(
      `/integrations/zipform/disconnect/${userId}`,
      formatToSnakeCase({
        tokenId,
      } as ApiRequestBody<DisconnectZipFormRequest>)
    );
  }

  zipformTeamTokens = ({ transactionId }: GetZipFormTeamTokensRequest = {}) => {
    return this.get<SharedTokenResponse>('/integrations/zipform/team_tokens', {
      params: formatToSnakeCase({
        transactionId,
      }),
    });
  };

  zipformGetZfTransaction = ({
    tokenId,
    transactionId,
  }: GetZipFormTransactionRequest) => {
    return this.get<ZfTransaction>(
      `/integrations/zipform/zf_transactions/${transactionId}`,
      {
        params: formatToSnakeCase({
          tokenId,
        }),
      }
    );
  };

  zipformZfTransactionsList = ({
    tokenId,
    transactionType,
    filterBy,
  }: GetZipFormTransactionsRequest) => {
    return this.get<ApiResponseData<GetZipFormTransactionsResponse>>(
      '/integrations/zipform/zf_transactions',
      {
        params: formatToSnakeCase({
          tokenId,
          transactionType,
          filterBy,
        }),
      }
    );
  };

  zipformZfTransactionsCreate = (
    data: DeepPartial<CreateZipFormTransactionRequest>
  ) => {
    return this.post<ZfTransaction>(
      '/integrations/zipform/zf_transactions/create',
      data
    );
  };

  zipformLinkContinue = (path = window.location.pathname, state = '') => {
    return this.post<void>('/integrations/zipform/link_continue', {
      path,
      state,
    } as SetZipFormLinkContinueRequest);
  };

  zipformLibraries = (transactionId: string) => {
    return this.get<ApiResponseData<GetZipFormTransactionLibrariesResponse>>(
      `/integrations/zipform/${transactionId}/libraries`
    );
  };

  zipformLibraryForms = (
    transactionId: string,
    libraryId: string,
    libraryName: string,
    libraryVersion: string
  ) => {
    return this.get<ApiResponseData<GetZipFormTransactionLibraryFormsResponse>>(
      `/integrations/zipform/${transactionId}/libraries/${libraryId}/${libraryName}/${libraryVersion}`
    );
  };

  zipformAddForm = (transactionId: string, formId: string) => {
    return this.post<ExternalDocumentNode>(
      `/integrations/zipform/${transactionId}/add_form`,
      formatToSnakeCase({
        formId,
      } as ApiRequestBody<AddZipFormTransactionFormRequest>)
    );
  };

  zipformListDuplicates = (tokenId: string) => {
    return this.post<ApiResponseData<GetZipFormDuplicatesResponse>>(
      '/integrations/zipform/duplicates',
      formatToSnakeCase({
        tokenId,
      } as GetZipFormDuplicatesRequest)
    );
  };

  zipformDisconnectDuplicates = (tokenId: string) => {
    return this.post<void>(
      '/integrations/zipform/duplicates/disconnect',
      formatToSnakeCase({
        tokenId,
      } as DisconnectZipFormDuplicatesRequest)
    );
  };

  generateZfDocs(transactionId: string, data: DeepPartial<ZfAsyncGenerate>) {
    return this.post<void>(
      `/integrations/zipform/${transactionId}/generate`,
      data
    );
  }

  zfDocs(transactionId: string) {
    return this.get<ExternalDocumentsResponse>(
      `/integrations/zipform/${transactionId}/documents`
    );
  }

  quickbooksSearchCustomers = (query: string) => {
    return this.get<ApiResponseData<GetQuickbooksCustomersResponse>>(
      '/integrations/quickbooks/search_customers',
      {
        params: {
          query,
        } as GetQuickbooksCustomersRequest,
      }
    );
  };

  linkedAccount = (id: string) => {
    return this.get<LinkedAccountDetails>(
      `/integrations/linked_account/${id}/`
    );
  };

  userHasAccessToGlide = (id: string) => {
    return this.get<boolean>(`/integrations/user_has_access_to_glide/${id}/`);
  };

  deleteLinkedAccount = (id: string) => {
    return this.post<void>(`/integrations/delete_linked_account/${id}/`);
  };

  deleteOauthAccount = (id: string) => {
    return this.post<void>(`/integrations/delete_oauth_account/${id}/`);
  };

  updateLinkedAccountConfig = (
    id: string,
    data: DeepPartial<LinkedAccountConfig>
  ) => {
    return this.post<LinkedAccount>(
      `/integrations/accounts/${id}/config/`,
      data
    );
  };

  searchMlsListing = (mls: string, query: string) => {
    return this.get<ApiResponseData<GetMlsListingsResponse>>(
      `/integrations/mls/${mls}/search/${query}`
    );
  };

  refreshCarSsoInfo = (id: string) => {
    return this.post<void>(`/integrations/refresh_car_sso_info/${id}/`);
  };

  verifyCarNrdsId = ({ nrdsId, lastName, userId }: VerifyNrdsIdRequest) => {
    return this.post<boolean>('/integrations/CAR/nrds_id/', {
      nrdsId,
      lastName,
      userId,
    });
  };

  verifyCarRstId = ({ rstId, userId }: VerifyCarRstIdRequest) => {
    return this.post<void>('/integrations/CAR/rst_id/', {
      rstId,
      userId,
    });
  };

  submitCarPetition = ({
    fullName,
    email,
    company,
    message,
    showName,
  }: Partial<CarPetition>) => {
    return this.post<CarPetition>('/car_petition', {
      fullName,
      email,
      company,
      message,
      showName,
    });
  };

  petitionList = () => {
    return this.get<GetCarPetitionsResponse>('/car_petitions');
  };
}
