import TransactionAppDelegate from './delegate';

export default class SellerDisclosuresAppDelegate extends TransactionAppDelegate {
  get transactionId() {
    return this.item.transactionId;
  }

  get transactions() {
    return this.item.store;
  }

  get discPacketId() {
    return this.item.typeItem.discPacketId;
  }

  getFetchDiscPacket = () => {
    return this.transactions.getOrFetchItem(
      this.transactionId,
      'SHARE_PACKET',
      this.discPacketId
    );
  };
}
