import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { getErrorAsJson } from 'src/utils/getErrorAsJson';
import { sendMessage as sendMobileBridgeMessage } from 'src/utils/mobile-bridge';
import AppFullErrorMessage from './app-full-error-message';

@inject('ui')
@observer
export default class ErrorBoundary extends Component {
  static propTypes = {
    suppress: PropTypes.bool,
    children: PropTypes.any,
  };

  state = {
    error: null,
  };

  static getDerivedStateFromError(error) {
    return {
      error,
    };
  }

  componentDidCatch(error, errorInfo) {
    sendMobileBridgeMessage({
      data: {
        event: 'unrecoverableError',
      },
      dispose: false,
    });
    if (!this.props.suppress) {
      const sentryId = '';
      window.DD_RUM?.addError(error, errorInfo);
      this.props.ui.parent.embeddedApp?.sendError('Something went wrong.', {
        fullError: getErrorAsJson(error),
        sentryId,
      });
    }
    if (process.env.NODE_ENV !== 'production') {
      // Re-throwing error is useful while testing e2es
      throw error;
    }
  }

  render() {
    if (this.state.error) {
      if (this.props.suppress) {
        return null;
      }

      return (
        <AppFullErrorMessage
          message="Something went wrong."
          fullPage={false}
          suppressLogs
        />
      );
    }

    return this.props.children;
  }
}
