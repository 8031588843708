import camelCase from 'lodash/camelCase';
import { computed, makeObservable, override } from 'mobx';
import type { CamelCase } from 'type-fest';
import Model from 'src/models/base';
import type { ItemEdge } from 'src/types/proto/transactions';

export interface AggregateItemStore<T = unknown> {
  aggregatesById: Map<string, T>;
  thinAggregatesById: Map<string, T>;
}

export interface AggregateItemJson {
  id: string;
  clientId: string;
  kind: string;
  transId: string;
  title: string;
  can: Record<string, boolean>;
  effectiveFrom: number | string;
  vers: string;
  createdAt: number | string;
  updatedAt?: number | string;
  deleted: number;
  inEdges: ItemEdge[];
  outEdges: ItemEdge[];
  [key: string]: unknown;
}

export default class Item<
  TStore extends AggregateItemStore<TAggregates>,
  TJson extends AggregateItemJson,
  TAggregates = unknown
> extends Model<TJson> {
  store: TStore;

  constructor(store: TStore, json: TJson) {
    super(json);
    makeObservable(this);
    this.store = store;
  }

  @override
  updateFromJson(json: TJson) {
    super.updateFromJson(json);
    const camelCaseKind = camelCase(this.kind) as CamelCase<TJson['kind']>;
    if (!this.data[camelCaseKind]) {
      this.data[camelCaseKind] = {} as TJson[CamelCase<TJson['kind']>];
    }
  }

  @computed
  get id() {
    return this.data.id;
  }

  @computed
  get clientId() {
    return this.data.clientId;
  }

  @computed
  get idNumber() {
    return Number(this.data.id);
  }

  @computed
  get kind() {
    return this.data.kind as TJson['kind'];
  }

  @computed
  get kindItem() {
    return this.data[camelCase(this.kind)] as TJson[CamelCase<TJson['kind']>];
  }

  @computed
  get title() {
    return this.data.title;
  }

  set title(value) {
    this.data.title = value;
  }

  @computed
  get transId() {
    return this.data.transId;
  }

  @computed
  get transactionId() {
    return this.data.transId;
  }

  @computed
  get aggregateId() {
    return this.transactionId;
  }

  @computed
  get aggregate() {
    return this.store.aggregatesById.get(this.aggregateId)!;
  }

  @computed
  get thinAggregate() {
    return this.store.thinAggregatesById.get(this.aggregateId);
  }

  @computed
  get outEdges() {
    return this.data.outEdges as TJson['outEdges'];
  }

  outEdgeIdsByKind = (kind: string) => {
    return this.outEdges.filter((e) => e.kind === kind).map((e) => e.item2Id);
  };

  @computed
  get inEdges() {
    return this.data.inEdges as TJson['inEdges'];
  }

  inEdgeIdsByKind = (kind: string) => {
    return this.inEdges.filter((e) => e.kind === kind).map((e) => e.item1Id);
  };

  @computed
  get createdAt() {
    return this.data.createdAt;
  }

  @computed
  get deleted() {
    return this.data.deleted;
  }

  @computed
  get createdAtNumber() {
    return Number(this.createdAt);
  }

  @computed
  get updatedAt() {
    return this.data.updatedAt;
  }

  @computed
  get effectiveFrom() {
    return this.data.effectiveFrom;
  }

  @computed
  get updatedAtNumber() {
    return Number(this.updatedAt);
  }

  @computed
  get effectiveFromNumber() {
    return Number(this.effectiveFrom);
  }

  // Data store version for this instance. Gets updated by the backend
  // on changes in data.
  // Can be `undefined` if this instance is not based on backend data.
  @computed
  get vers() {
    return this.data.vers;
  }

  can(op: string) {
    return this.data.can[op];
  }
}
