import type { SnakeCasedProperties } from 'type-fest';
import type {
  Analysis,
  CreateFormLibrary,
  Document,
  Form,
  FormConfigStateEnum,
  FormFlowConfig,
  FormLibrary,
  FormLibraryBulkPublishResponse,
  FormLibraryEntitlement,
  FormLibraryEntitlementEntitlementStatus,
  FormRenderAudit,
  FormRenderData,
  FormSeries,
  FormState,
  OcrTestResult,
  PublishStatusEnum,
  SuperFormTemplate,
  Tag,
  TagTagScope,
} from 'src/types/proto/documents';
import type { Flow } from 'src/types/proto/flows';
import type { FormConfig } from 'src/types/proto/reform';
import type {
  GetDocumentsTagsResponse,
  CreateFlowPreviewFromFormConfigRequest,
  GenerateUsageReportLibrariesRequest,
  GetFormFlowConfigListRequest,
  GetFormLibraryListRequest,
  GetFormSeriesListRequest,
  GetFormSeriesListWithTotalResponse,
  ServiceFormLibraryBulkScheduleRequest,
  TestLibraryFormMatchingRequest,
} from 'src/types/proto/services/document_admin_service';
import type {
  CloneFormRequest,
  FormRenderRequest,
  GetFormFixturesStatusAsyncResponse,
  GetFormLastEditResponse,
  GetFormListRequest,
  GetFormListResponse,
  GetLegalStatesFromFormConfigStateResponse,
  GetLegalStatesFromFormStateResponse,
  GetOCRTestDocumentsResponse,
  IngestFormDocumentResponse,
  NotifyFormLastEditRequest,
  SaveFormRequest,
  ServiceFormScheduleRequest,
  TransitionFormConfigToStateResponse,
  TransitionToStateResponse,
} from 'src/types/proto/services/document_form_admin_service';
import type { GetAnnotationsByFormUuidResponse } from 'src/types/proto/services/document_public_service';
import type { GetAnnotationsByFormResponse } from 'src/types/proto/services/pspdfkit_admin_service';
import type { PspdfkitDocument } from 'src/types/proto/services/pspdfkit_public_service';
import type {
  ApiRequestBody,
  ApiResponseData,
  DeepPartial,
} from 'src/types/utils';
import { formatToSnakeCase } from 'src/utils/format-data-object';
import PublicDocuments from '../public/documents';

// The return type depends on the includeTotal parameter.
// As there is no way to decide the return type based on the actual value of the flag,
// this is a hacky way of selecting the return type based only on it's existence.
type GetFormSeriesListResponse<T extends Partial<GetFormSeriesListRequest>> =
  T extends { includeTotal: boolean }
    ? GetFormSeriesListWithTotalResponse
    : FormSeries[];

export default class Documents extends PublicDocuments {
  getForm(formId: string) {
    return this.get<Form>(`/documents/forms/${encodeURIComponent(formId)}`);
  }

  getFormBySeriesVersion(formSeriesId: string, version: string) {
    return this.get<Form>(
      `/documents/form_series/${encodeURIComponent(
        formSeriesId
      )}/version/${encodeURIComponent(version)}`
    );
  }

  getFormBySeriesVersionEditMode(
    formSeriesId: string,
    version: string,
    isEditing: boolean,
    loadSync = true
  ) {
    return this.get<Form>(
      `/documents/form_series/${encodeURIComponent(
        formSeriesId
      )}/version/${encodeURIComponent(version)}/edit_mode`,
      {
        params: { is_editing: isEditing, load_sync: loadSync },
      }
    );
  }

  getFormSeries(formSeriesId: string) {
    return this.get<FormSeries>(
      `/documents/form_series/${encodeURIComponent(formSeriesId)}`
    );
  }

  getFormFlowConfig(formFlowConfigId: string) {
    return this.get<FormFlowConfig>(
      `/documents/form_flow_config/${encodeURIComponent(formFlowConfigId)}`
    );
  }

  getSuperformTemplatesBySubFormId(id: string) {
    return this.get<SuperFormTemplate[]>(
      `/documents/form_series/superform_templates_forms_and_series/${encodeURIComponent(
        id
      )}`
    );
  }

  getSuperformTemplateBySeriesId(id: string) {
    return this.get<SuperFormTemplate>(
      `/documents/form_series/superform_templates_association/${encodeURIComponent(
        id
      )}`
    );
  }

  listFormFlowConfig(params: GetFormFlowConfigListRequest) {
    return this.get<FormFlowConfig[]>('/documents/form_flow_config', {
      params: formatToSnakeCase(params),
    });
  }

  listFormSeriesFast<T extends Partial<GetFormSeriesListRequest>>(params: T) {
    return this.get<GetFormSeriesListResponse<T>>(
      '/documents/form_series_fast',
      {
        params: formatToSnakeCase(params),
      }
    );
  }

  listFormSeriesFromLibrary(libraryId: string) {
    return this.get<FormSeries[]>(
      `/documents/form_series_from_library${`?id=${libraryId}`}`
    );
  }

  setFormSeriesExcludeMatching(formSeriesId: string, excludeMatching: boolean) {
    return this.post<FormSeries>(
      `documents/form_series/${formSeriesId}/set_exclude`,
      {
        excludeMatching,
      }
    );
  }

  createForm(data: DeepPartial<Form>) {
    return this.post<Form>('/documents/forms', data);
  }

  createFormMulti(data) {
    return this.post('/documents/forms/multi', data);
  }

  cloneForm(formId: string, data: ApiRequestBody<CloneFormRequest>) {
    return this.post<Form>(
      `/documents/forms/${encodeURIComponent(formId)}/clone`,
      data
    );
  }

  getFormLastEdit(formId: string) {
    return this.get<ApiResponseData<GetFormLastEditResponse>>(
      `/documents/forms/${encodeURIComponent(formId)}/edit`
    );
  }

  notifyFormEdit(
    formId: string,
    data: ApiRequestBody<NotifyFormLastEditRequest>
  ) {
    return this.post<void>(
      `/documents/forms/${encodeURIComponent(formId)}/edit`,
      data
    );
  }

  saveForm(formId: string, data: DeepPartial<ApiRequestBody<SaveFormRequest>>) {
    return this.post<Form>(
      `/documents/forms/${encodeURIComponent(formId)}`,
      data
    );
  }

  deleteForm(formId: string) {
    return this.delete<void>(`/documents/forms/${encodeURIComponent(formId)}`);
  }

  getFormRenderAudit(formRenderAuditId: string) {
    return this.get<FormRenderAudit>(
      `/documents/form_render_audits/${encodeURIComponent(formRenderAuditId)}`
    );
  }

  formHasSuperForms(formId: string) {
    return this.get<boolean>(
      `/documents/forms/${encodeURIComponent(formId)}/has_superforms`
    );
  }

  /**
   *
   * @param {*} formId
   * @param {*} active
   * @param {*} toggleDraftPublishedState whether to also toggle the form state. Applies only to superforms.
   * @returns
   */
  activateForm(
    formId: string,
    active = true,
    toggleDraftPublishedState = false
  ) {
    return this.post<Form>(
      `/documents/forms/${encodeURIComponent(formId)}/activate`,
      {
        active,
        toggleDraftPublishedState,
      }
    );
  }

  getStandardForms = (options: Partial<GetFormListRequest>) => {
    const { activeOnly = true, libraries, scheduledOnly } = options || {};
    return this.get<ApiResponseData<GetFormListResponse>>('/documents/forms', {
      params: {
        active_only: activeOnly ? '1' : '0',
        scheduled_only: scheduledOnly ? '1' : '0',
        ...(libraries
          ? {
              libraries,
            }
          : null),
      },
    });
  };

  publishStagedForms(libraryId: string) {
    return this.post<FormLibraryBulkPublishResponse>(
      `/documents/libraries/bulk_publish/${encodeURIComponent(libraryId)}`,
      {}
    );
  }

  createSuperformTemplate(data: DeepPartial<SuperFormTemplate>) {
    return this.post<SuperFormTemplate>('/documents/superform_templates', data);
  }

  updateSuperformTemplate(
    templateId: string,
    data: DeepPartial<SuperFormTemplate>
  ) {
    return this.post<SuperFormTemplate>(
      `/documents/superform_templates/${encodeURIComponent(templateId)}`,
      data
    );
  }

  deleteSuperformTemplate(templateId: string) {
    return this.delete(
      `/documents/superform_templates/${encodeURIComponent(templateId)}`
    );
  }

  getSuperformTemplate(templateId: string) {
    return this.get<SuperFormTemplate>(
      `/documents/superform_templates/${encodeURIComponent(templateId)}`,
      { params: { include_fs_default_form: 1 } }
    );
  }

  publishSuperformTemplate(templateId: string) {
    return this.post<SuperFormTemplate[]>(
      `/documents/superform_templates/publish/${encodeURIComponent(templateId)}`
    );
  }

  listSuperformTemplatesFast(query?: string) {
    return this.get<SuperFormTemplate[]>(
      `/documents/superform_templates_fast${query ? `?query=${query}` : ''}`
    );
  }

  addForm(formId: string, libraryId: string) {
    return this.post(
      `/documents/libraries/${encodeURIComponent(libraryId)}/add`,
      {
        formId,
      }
    );
  }

  removeForm(formId: string, libraryId: string) {
    return this.post(
      `/documents/libraries/${encodeURIComponent(libraryId)}/remove`,
      {
        formId,
      }
    );
  }
  renderForm(formId: string, data: ApiRequestBody<FormRenderRequest>) {
    return this.post<FormRenderData>(
      `/documents/forms/${encodeURIComponent(formId)}/render`,
      data
    );
  }

  getFormLibraries(options: GetFormLibraryListRequest = {}) {
    const { activeOnly = true } = options;
    return this.get<FormLibrary[]>('/documents/libraries', {
      params: {
        active_only: activeOnly ? '1' : '0',
      },
    });
  }

  getFormLibrary(libraryId: string) {
    return this.get<FormLibrary>(
      `/documents/libraries/${encodeURIComponent(libraryId)}`
    );
  }

  saveFormLibrary(library: FormLibrary) {
    return this.post<FormLibrary>(
      `/documents/libraries/${encodeURIComponent(library.id)}`,
      library
    );
  }

  createFormLibrary(data: CreateFormLibrary) {
    return this.post<FormLibrary>('/documents/libraries', data);
  }

  updateFormFixtures() {
    return this.post<void>('/documents/update_form_fixtures');
  }

  updateOneFormFixture(corpusId: string) {
    return this.post<void>('/documents/update_one_form_fixture', { corpusId });
  }

  getFormFixturesStatusAsync() {
    return this.get<ApiResponseData<GetFormFixturesStatusAsyncResponse>>(
      '/documents/form_fixtures_status'
    );
  }

  getPspdfkitDocument(formId: string) {
    return this.get<SnakeCasedProperties<PspdfkitDocument>>(
      `/pspdfkit/document/by_form/${encodeURIComponent(formId)}`
    );
  }

  getPspdfkitEditableDocument(formId: string) {
    return this.get<SnakeCasedProperties<PspdfkitDocument>>(
      `/pspdfkit/document/editable_by_form/${encodeURIComponent(formId)}`
    );
  }

  getPspdfkitDocumentByFormVers(formId: string, vers: string) {
    return this.get<SnakeCasedProperties<PspdfkitDocument>>(
      `/pspdfkit/document/by_form_vers/${encodeURIComponent(
        formId
      )}/${encodeURIComponent(vers)}`
    );
  }

  getAnnotations(formId: string, offset: number, limit: number) {
    return this.get<ApiResponseData<GetAnnotationsByFormResponse>>(
      `/pspdfkit/annotations/by_form/${encodeURIComponent(formId)}`,
      {
        params: {
          offset: parseInt(offset, 10) || null,
          limit: parseInt(limit, 10) || null,
        },
      }
    );
  }

  setFormLibraryEntitlements(userId: string, libraryIds: string[]) {
    return this.post('/documents/library_entitlements/set/', {
      userId,
      libraryIds,
    });
  }

  setFormLibraryEntitlementStatus(
    entitlementId: string,
    newStatus: FormLibraryEntitlementEntitlementStatus
  ) {
    return this.post<FormLibraryEntitlement>(
      `/documents/library_entitlements/${entitlementId}/set_status`,
      {
        status: newStatus,
      }
    );
  }

  exportAnnotationsCsv(libraryId: string) {
    return this.post(
      `/documents/libraries/${libraryId}/export_annotations_csv`
    );
  }

  exportFormsCsv(libraryId: string) {
    return this.post(`/documents/libraries/${libraryId}/export_forms_csv`);
  }

  exportAllFormsCsv() {
    return this.post(`/documents/libraries/all/export_forms_csv`);
  }

  exportFormsS3() {
    return this.post(`/documents/libraries/all/export_forms_s3`);
  }

  syncGlideProdFormsPdf() {
    return this.post(`/documents/libraries/all/sync_glide_prod_forms_pdf`);
  }

  importFormsS3() {
    return this.post(`/documents/libraries/all/import_forms_s3`);
  }

  setFillConfigQaGenerationForm(formFlowConfigId: string, formId: string) {
    return this.post<void>(
      `/documents/form_flow_config/${encodeURIComponent(
        formFlowConfigId
      )}/qa_generation_form`,
      {
        form_id: formId,
      }
    );
  }

  promoteFillConfigQaGenerationForm(formFlowConfigId: string) {
    return this.post<void>(
      `/documents/form_flow_config/${encodeURIComponent(
        formFlowConfigId
      )}/promote_qa_generation_form`
    );
  }

  setFillConfigPubStatus(
    formFlowConfigId: string,
    newStatus: PublishStatusEnum
  ) {
    return this.post<void>(
      `/documents/form_flow_config/${encodeURIComponent(
        formFlowConfigId
      )}/set_status`,
      {
        status: newStatus,
      }
    );
  }

  createFormFlowConfig(data: FormFlowConfig) {
    return this.post<FormFlowConfig>(`/documents/form_flow_config`, data);
  }

  saveFormFlowConfig(formFlowConfigId: string, data: FormFlowConfig) {
    return this.post<FormFlowConfig>(
      `/documents/form_flow_config/${encodeURIComponent(formFlowConfigId)}`,
      data
    );
  }

  ingestFormDocument(formId: string, ingest = false) {
    return this.post<ApiResponseData<IngestFormDocumentResponse>>(
      `/documents/forms/${encodeURIComponent(formId)}/ingest`,
      {
        ingest,
      }
    );
  }

  bulkUploadLibraryForms(libraryId: string, uploads: File[] = []) {
    return this.post(
      `/documents/libraries/${encodeURIComponent(libraryId)}/bulk_upload`,
      {
        uploads,
      }
    );
  }

  bulkUploadLibraryFormsAsyncAnalysis(uploads: File[] = [], pusherKey: String) {
    return this.post(`/documents/forms/upload`, {
      uploads,
      pusher_key: pusherKey,
    });
  }

  async getTags(scope?: TagTagScope) {
    const { data } = await this.get<ApiResponseData<GetDocumentsTagsResponse>>(
      `/tags${(scope && `/${scope}`) || ''}`
    );
    return data;
  }

  createTags(scope: TagTagScope, tags?: Tag[]) {
    return this.post<void>(`/tags/${scope || ''}`, {
      tags,
    });
  }

  saveFormConfig(formUuid: string, data: DeepPartial<FormConfig>) {
    return this.post<FormConfig>(
      `/forms/${encodeURIComponent(formUuid)}/form_config`,
      data
    );
  }

  createFlowPreview(
    formId: string,
    data: ApiRequestBody<CreateFlowPreviewFromFormConfigRequest>
  ) {
    return this.post<Flow>(
      `/forms/${encodeURIComponent(formId)}/form_config/flow_preview`,
      data
    );
  }

  getFormAnnotations(formUuid: string) {
    return this.get<ApiResponseData<GetAnnotationsByFormUuidResponse>>(
      `/forms/${encodeURIComponent(formUuid)}/annotations`
    );
  }

  getDocument(documentId: string) {
    return this.get<Document>(`/documents/${encodeURIComponent(documentId)}`);
  }

  addOcrTestDocument(formId: string, documentId: string) {
    return this.post<Form>(
      `/documents/forms/${encodeURIComponent(formId)}/ocr_test_docs`,
      {
        document_id: documentId,
      }
    );
  }

  getOcrTestDocuments(formId: string) {
    return this.get<GetOCRTestDocumentsResponse>(
      `/documents/forms/${encodeURIComponent(formId)}/ocr_test_docs`
    );
  }

  runOcrTest(documentId: string, reState: string) {
    return this.get<OcrTestResult>(
      `/documents/forms/ocr_test_run/${encodeURIComponent(
        reState
      )}/${encodeURIComponent(documentId)}`
    );
  }

  getOcrTerms() {
    return this.get('/ocr_terms/');
  }

  getLegalStatesFromFormState(state: FormState) {
    return this.get<ApiResponseData<GetLegalStatesFromFormStateResponse>>(
      `/documents/forms/legal_states/${state}`
    );
  }

  transitionToState(newState: FormState, formId: string) {
    return this.post<TransitionToStateResponse>(
      `/documents/forms/${formId}/state`,
      {
        new_state: newState,
      }
    );
  }

  dumpLibraryForms(
    libraryIds: string[],
    onlyActiveForms: boolean,
    includeQaActiveForms: boolean,
    includeOtherVersions = false
  ) {
    return this.post(`/documents/libraries/dump`, {
      library_ids: libraryIds,
      active_only: onlyActiveForms,
      include_qa_active: includeQaActiveForms,
      include_other_versions: includeOtherVersions,
    });
  }

  importLibraryForms(url: string) {
    return this.post(`/documents/libraries/import`, { url });
  }

  generateLibraryUsageReport(
    data: ApiRequestBody<GenerateUsageReportLibrariesRequest>
  ) {
    return this.post(`/documents/libraries/usage`, data);
  }

  testLibraryFormMatching(
    data: ApiRequestBody<TestLibraryFormMatchingRequest>
  ) {
    return this.post(`/documents/libraries/test_form_matching`, data);
  }

  getLegalStatesFromFormConfigState(state: FormConfigStateEnum) {
    return this.get<ApiResponseData<GetLegalStatesFromFormConfigStateResponse>>(
      `/documents/forms/form_config_legal_states/${state}`
    );
  }

  transitionFormConfigToState(formId: string, newState: FormConfigStateEnum) {
    return this.post<TransitionFormConfigToStateResponse>(
      `/documents/forms/${formId}/form_config_state`,
      {
        new_state: newState,
      }
    );
  }

  schedule(formId: string, data: ApiRequestBody<ServiceFormScheduleRequest>) {
    return this.post<Form>(`/documents/forms/${formId}/schedule`, data);
  }

  cancelSchedule(formId: string) {
    return this.post<Form>(`/documents/forms/${formId}/cancel_schedule`);
  }

  bulkSchedule(
    libraryId: string,
    data: ApiRequestBody<ServiceFormLibraryBulkScheduleRequest>
  ) {
    return this.post<FormLibraryBulkPublishResponse>(
      `/documents/libraries/${libraryId}/bulk_schedule`,
      data
    );
  }

  triggerAnalysisRetry(documentId: string, parts?: string[]) {
    return this.get<Analysis>(
      `/documents/trigger_analysis_retry/${documentId}`,
      {
        params: { parts },
      }
    );
  }
}
