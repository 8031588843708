import type { Empty } from 'src/types/proto/google/protobuf/empty';
import type {
  CreateBuyerProspectResponse,
  CreateBuyerProspectPropertyRequest,
} from 'src/types/proto/services/buyer_prospect_public_service';
import type { ApiResponseData } from 'src/types/utils';
import BaseApi from '../base-api';

export default class BuyerProspects extends BaseApi {
  flowsBuyerProspectCreate = (options: Empty = {}) => {
    return this.post<ApiResponseData<CreateBuyerProspectResponse>>(
      '/buyer_prospects/flows/buyer_prospect_create/',
      options
    );
  };

  flowsBuyerProspectPropertyCreate = (
    options: CreateBuyerProspectPropertyRequest
  ) => {
    return this.post<ApiResponseData<CreateBuyerProspectResponse>>(
      '/buyer_prospects/flows/buyer_prospect_property_create/',
      options
    );
  };
}
