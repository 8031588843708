import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import { computed, makeObservable } from 'mobx';
import moment from 'moment';
import { UNKNOWN } from 'src/models/auth/entity-types';
import { BUYER_AGENT_ROLES, BUYER_ROLES } from 'src/models/transactions/roles';
import { Contact } from 'src/types/proto/contacts';
import {
  ItemKind,
  ItemPeadEntrant,
  PartyRole,
  ItemPeadEntrantEntrantRole,
  ItemPeadFormType,
  ItemPeadStatus,
  ItemEdgeKind,
} from 'src/types/proto/transactions';
import getFullNameOrEmail from 'src/utils/get-full-name-or-email';
import Item, { ItemJson, ItemStore } from './item';
import Party from './party';
import TransactionDocument from './transaction-document';

export const PEAD_V = ItemPeadFormType.PEAD_V;
export const PEAD_ALL = ItemPeadFormType.PEAD_ALL;

// Role Constants
export const BUYER = ItemPeadEntrantEntrantRole.BUYER;
export const TENANT_LESSEE = ItemPeadEntrantEntrantRole.TENANT_LESSEE;
export const BROKER_AGENT = ItemPeadEntrantEntrantRole.BROKER_AGENT;
export const OTHER_ENTRANT = ItemPeadEntrantEntrantRole.OTHER_ENTRANT;
export const SELLER = ItemPeadEntrantEntrantRole.SELLER;
export const LANDLORD_LESSOR = ItemPeadEntrantEntrantRole.LANDLORD_LESSOR;
export const OCCUPANT = ItemPeadEntrantEntrantRole.OCCUPANT;
export const ENTRANT_ROLES = [
  BUYER,
  TENANT_LESSEE,
  BROKER_AGENT,
  SELLER,
  LANDLORD_LESSOR,
  OCCUPANT,
  OTHER_ENTRANT,
];
export const ENTRANT_ROLES_LABELS = {
  [BUYER]: 'Buyer',
  [TENANT_LESSEE]: 'Tenant / Lessee',
  [BROKER_AGENT]: 'Broker / Agent', // called Broker/Agent on PEAD form
  [SELLER]: 'Seller',
  [LANDLORD_LESSOR]: 'Landlord / Lessor',
  [OCCUPANT]: 'Occupant',
  [OTHER_ENTRANT]: 'Other Entrant',
  [UNKNOWN]: undefined,
};
const PARTY_ROLE_TO_ENTRANT_ROLE = [
  ...BUYER_AGENT_ROLES.map((r) => [r, BROKER_AGENT]),
  ...BUYER_ROLES.map((r) => [r, BUYER]),
];

export const getPartyEntrantRole = (party: Party) => {
  let entrantRole: ItemPeadEntrantEntrantRole = OTHER_ENTRANT; // OTHER_ENTRANT is the default
  for (let i = 0; i < PARTY_ROLE_TO_ENTRANT_ROLE.length; i++) {
    const [partyRole, er] = PARTY_ROLE_TO_ENTRANT_ROLE[i];
    if (party.roles.includes(partyRole as PartyRole)) {
      entrantRole = er as ItemPeadEntrantEntrantRole;
      break;
    }
  }
  return entrantRole;
};

export type PeadJson = ItemJson<'PEAD'>;

export default class Pead extends Item<ItemStore, PeadJson> {
  constructor(store: ItemStore, json: PeadJson) {
    super(store, json);

    makeObservable(this);
  }

  @computed
  get status() {
    return this.kindItem!.status;
  }

  @computed
  get formType() {
    return this.kindItem!.formType;
  }

  @computed
  get isPeadV() {
    return this.formType === PEAD_V;
  }

  @computed
  get isPeadAll() {
    return this.formType === PEAD_ALL;
  }

  @computed
  get addresses() {
    return this.kindItem!.addresses || [];
  }

  @computed
  get entrants() {
    return this.kindItem!.entrants || [];
  }

  @computed
  get fields() {
    return this.kindItem!.fields || {};
  }

  @computed
  get date() {
    return this.kindItem!.date;
  }

  @computed
  get dateMoment() {
    return this.date ? moment(this.date, 'YYYY-MM-DD') : undefined;
  }

  getFormattedDate = (format = 'MM/DD/YYYY', noDate = '--') =>
    this.date ? this.dateMoment!.format(format) : noDate;

  @computed
  get isReadyToGenerate() {
    return this.status === ItemPeadStatus.READY_TO_GENERATE;
  }

  @computed
  get isGenerated() {
    return (
      [
        ItemPeadStatus.GENERATED,
        ItemPeadStatus.OUT_FOR_SIGNATURE,
        ItemPeadStatus.SIGNED,
        ItemPeadStatus.UPLOADED_FORMS,
      ] as ItemPeadStatus[]
    ).includes(this.status);
  }

  @computed
  get isOutForSignature() {
    return this.status === ItemPeadStatus.OUT_FOR_SIGNATURE;
  }

  getAllTds = (includeTrashed = false) => {
    return this.addresses
      .map(({ tdIds = [], listingAgent }) =>
        sortBy(
          tdIds
            .map<TransactionDocument>(
              (tdId) =>
                this.store.getItem(
                  this.transaction.id,
                  ItemKind.TRANSACTION_DOCUMENT,
                  tdId
                ) as TransactionDocument
            )
            .filter((td) => Boolean(td) && Boolean(td.folder))
            .map((td) => [td, listingAgent] as [TransactionDocument, Contact]),
          ([td]) => +td.id
        )
      )
      .flat()
      .filter(([td]) => includeTrashed || !td.isInTrash);
  };

  @computed
  get allTds() {
    return this.getAllTds(false).map(([td]) => td);
  }

  @computed
  get allTdsWithListingAgent() {
    return this.getAllTds(false);
  }

  @computed
  get allTdsWithTrashed() {
    return this.getAllTds(true).map(([td]) => td);
  }

  @computed
  get allTdsWithTrashedWithListingAgent() {
    return this.getAllTds(false);
  }

  get allSignatureRequestsIds() {
    return Array.from(
      new Set(
        this.allTds
          .map((td) =>
            td.outEdges
              .filter((e) => e.kind === ItemEdgeKind.TD_HAS_SIGNATURE_REQUEST)
              .map((e) => e.item2Id)
          )
          .flat()
          .concat([...(this.kindItem!.signatureRequestIds || [])])
      )
    );
  }

  @computed
  get primaryAgentEntrant() {
    return this.entrants.find((e) => e.isPrimaryAgent);
  }

  getEntrantRole = (entrant: ItemPeadEntrant) =>
    ENTRANT_ROLES_LABELS[entrant.role || OTHER_ENTRANT];

  getEntrantNameOrEmail = (entrant: ItemPeadEntrant) =>
    getFullNameOrEmail(entrant.contact || {});
  getEntrantEmailInfo = (entrant: ItemPeadEntrant) => {
    if (get(entrant, 'contact.email')) {
      return entrant!.contact!.email;
    }
    if (this.primaryAgentEntrant) {
      return `Represented by ${this.getEntrantNameOrEmail(
        this.primaryAgentEntrant
      )}`;
    }
    return 'No Email';
  };

  @computed
  get entrantNamesSingleLine() {
    return this.entrants
      .map((entrant) => this.getEntrantNameOrEmail(entrant))
      .join(', ');
  }
}
