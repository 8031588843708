import { computed, makeObservable } from 'mobx';
import { ItemFileReview, ItemEdge } from 'src/types/proto/transactions';
import Item, { ItemJson, ItemStore } from './item';

export interface FileReviewJson extends ItemJson<'FILE_REVIEW'> {
  fileReview: ItemFileReview;
  outEdges: ItemEdge[];
}

export const STATUSES = {
  IN_PROGRESS: 'IN_PROGRESS',
  FINISHED: 'FINISHED',
  CANCELLED: 'CANCELLED',
};

export default class FileReview extends Item<ItemStore, FileReviewJson> {
  constructor(store: ItemStore, json: FileReviewJson) {
    super(store, json);

    makeObservable(this);
  }

  @computed
  get userId() {
    return this.kindItem.userId;
  }

  @computed
  get status() {
    return this.kindItem.status;
  }

  @computed
  get inProgress() {
    return this.status === STATUSES.IN_PROGRESS;
  }

  @computed
  get finished() {
    return this.status === STATUSES.FINISHED;
  }

  @computed
  get cancelled() {
    return this.status === STATUSES.CANCELLED;
  }

  @computed
  get hasChecklistItemEdges() {
    return this.outEdges.filter(
      (e) => e.kind === 'FILE_REVIEW_HAS_CHECKLIST_ITEM'
    );
  }

  @computed
  get reviewOps() {
    return this.hasChecklistItemEdges.filter(
      (e) => e.fileReviewHasChecklistItem?.op?.kind === 'REVIEW'
    );
  }

  @computed
  get approvedOps() {
    return this.reviewOps.filter(
      (e) => e.fileReviewHasChecklistItem?.op?.status === 'APPROVED'
    );
  }

  @computed
  get rejectedOps() {
    return this.reviewOps.filter(
      (e) => e.fileReviewHasChecklistItem?.op?.status === 'REJECTED'
    );
  }

  @computed
  get pendingOps() {
    return this.reviewOps.filter(
      (e) => e.fileReviewHasChecklistItem?.op?.status === 'PENDING'
    );
  }

  @computed
  get reviewedOps() {
    return this.reviewOps.filter(
      (e) => e.fileReviewHasChecklistItem?.op?.status === 'REVIEWED'
    );
  }

  @computed
  get readyForReviewOps() {
    return this.reviewOps.filter(
      (e) => e.fileReviewHasChecklistItem?.op?.status === 'REJECTED'
    );
  }

  @computed
  get changeStatusOps() {
    return this.hasChecklistItemEdges.filter(
      (e) => e.fileReviewHasChecklistItem?.op?.kind === 'CHANGE_STATUS'
    );
  }

  @computed
  get exemptOps() {
    return this.changeStatusOps.filter(
      (e) => e.fileReviewHasChecklistItem?.op?.filingStatus === 'EXEMPT'
    );
  }

  @computed
  get requiredOps() {
    return this.changeStatusOps.filter(
      (e) => e.fileReviewHasChecklistItem?.op?.filingStatus === 'REQUIRED'
    );
  }

  @computed
  get ifApplicableOps() {
    return this.changeStatusOps.filter(
      (e) => e.fileReviewHasChecklistItem?.op?.filingStatus === 'IF_APPLICABLE'
    );
  }

  @computed
  get opsCount() {
    return this.hasChecklistItemEdges.length;
  }

  @computed
  get checklistItemIds() {
    return this.hasChecklistItemEdges.map((e) => e.item2Id);
  }
}
