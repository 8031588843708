import { computed, makeObservable } from 'mobx';
import { type ItemCoverSheet } from 'src/types/proto/transactions';
import Item, { type ItemStore as CoverSheetStore, type ItemJson } from './item';

export interface CoverSheetJson extends ItemJson<'COVER_SHEET'> {
  coverSheet: ItemCoverSheet & {
    content: unknown;
  };
}
export default class CoverSheet extends Item<CoverSheetStore, CoverSheetJson> {
  constructor(store: CoverSheetStore, json: CoverSheetJson) {
    super(store, json);

    makeObservable(this);
  }

  @computed
  get useBrokerageEditionLogo() {
    return this.kindItem.useBrokerageEditionLogo;
  }

  @computed
  get instructions() {
    return this.kindItem.instructions;
  }

  @computed
  get signatures() {
    return this.kindItem.signatures;
  }

  @computed
  get content() {
    return this.kindItem.content;
  }

  @computed
  get includeEscrow() {
    return this.kindItem.includeEscrow;
  }

  @computed
  get includeCoverPhoto() {
    return this.kindItem.includeCoverPhoto;
  }
}
