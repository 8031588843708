import get from 'lodash/get';
import { computed, makeObservable, override } from 'mobx';
import moment from 'moment-business-days';
import AcceptanceIcon from 'src/images/timeline/acceptance.svg';
import AppraisalContingencyIcon from 'src/images/timeline/appraisal_contingency.svg';
import CloseEscrowIcon from 'src/images/timeline/close_escrow.svg';
import CustomIcon from 'src/images/timeline/custom.svg';
import InspectionContingencyIcon from 'src/images/timeline/inspection_contingency.svg';
import LoanContingencyIcon from 'src/images/timeline/loan_contingency.svg';
import MoneyDepositIcon from 'src/images/timeline/money_deposit.svg';
import SellerDisclosureReportsIcon from 'src/images/timeline/seller_disclosure_reports.svg';
import { KeyDateEnum, KeyDateReminderChannel } from 'src/types/proto/dates';
import { getColor } from 'src/utils/get-color';
import Item, { ItemJson, ItemStore } from './item';

export const DATE_ICONS: Partial<Record<KeyDateEnum, string>> = {
  [KeyDateEnum.ACCEPTANCE]: AcceptanceIcon,
  [KeyDateEnum.SELLER_DISCLOSURES]: SellerDisclosureReportsIcon,
  [KeyDateEnum.APPRAISAL_CONTINGENCY]: AppraisalContingencyIcon,
  [KeyDateEnum.INITIAL_DEPOSIT]: MoneyDepositIcon,
  [KeyDateEnum.LOAN_CONTINGENCY]: LoanContingencyIcon,
  [KeyDateEnum.INSPECTION_CONTINGENCY]: InspectionContingencyIcon,
  [KeyDateEnum.CLOSE_ESCROW]: CloseEscrowIcon,
  [KeyDateEnum.SALE_OF_BUYER_PROPERTY]: CustomIcon,
  [KeyDateEnum.PURCHASE_AGREEMENT]: CustomIcon,
  [KeyDateEnum.POSESSION_DATE]: CustomIcon,
};

export const DEFAULT_DATE_VALUES: Partial<Record<KeyDateEnum, number>> = {
  [KeyDateEnum.SELLER_DISCLOSURES]: 7,
  [KeyDateEnum.APPRAISAL_CONTINGENCY]: 17,
  [KeyDateEnum.INITIAL_DEPOSIT]: 3,
  [KeyDateEnum.LOAN_CONTINGENCY]: 21,
  [KeyDateEnum.INSPECTION_CONTINGENCY]: 17,
};

export const COLORS_BY_DATE_TYPE: Partial<Record<KeyDateEnum, string>> = {
  [KeyDateEnum.ACCEPTANCE]: '#CE3C99',
  [KeyDateEnum.SELLER_DISCLOSURES]: getColor('accept-color'),
  [KeyDateEnum.APPRAISAL_CONTINGENCY]: getColor('app-tertiary-color'),
  [KeyDateEnum.INITIAL_DEPOSIT]: '#F0AC4A',
  [KeyDateEnum.LOAN_CONTINGENCY]: '#973DAD',
  [KeyDateEnum.INSPECTION_CONTINGENCY]: '#C4C4C4',
  [KeyDateEnum.CLOSE_ESCROW]: getColor('primary-color'),
};

export const HIGHLIGHT_NAME_BY_TYPE: Partial<Record<KeyDateEnum, string>> = {
  [KeyDateEnum.ACCEPTANCE]: 'ACCEPTANCE_DATE_AREA',
  [KeyDateEnum.SELLER_DISCLOSURES]: 'SELLER_DISCLOSURES_DATE_AREA',
  [KeyDateEnum.APPRAISAL_CONTINGENCY]: 'APPRAISAL_CONTINGENCY_DATE_AREA',
  [KeyDateEnum.INITIAL_DEPOSIT]: 'INITIAL_DEPOSIT_DATE_AREA',
  [KeyDateEnum.LOAN_CONTINGENCY]: 'LOAN_CONTINGENCY_DATE_AREA',
  [KeyDateEnum.INSPECTION_CONTINGENCY]: 'INSPECTION_CONTINGENCY_DATE_AREA',
  [KeyDateEnum.CLOSE_ESCROW]: 'CLOSE_ESCROW_DATE_AREA',
};

export const ACTIONS_BY_TYPE: Partial<Record<KeyDateEnum, string>> = {
  [KeyDateEnum.INITIAL_DEPOSIT]:
    'Remind your clients to send in their Earnest Money Deposit.',
};

export type KeyDateJson = ItemJson<'KEY_DATE'>;

export default class KeyDate extends Item<ItemStore, KeyDateJson> {
  constructor(store: ItemStore, json: KeyDateJson) {
    super(store, json);

    makeObservable(this);
  }

  @computed
  get actionDetail() {
    return this.type ? ACTIONS_BY_TYPE[this.type] ?? '' : '';
  }

  @computed
  get defaultDays() {
    return this.type ? DEFAULT_DATE_VALUES[this.type] : undefined;
  }

  @computed
  get static() {
    return this.kindItem?.static;
  }

  @computed
  get icon() {
    return this.type ? DATE_ICONS[this.type] ?? CustomIcon : CustomIcon;
  }

  @computed
  get relative() {
    return this.kindItem?.relative;
  }

  @computed
  get type() {
    return this.kindItem?.type;
  }

  @computed
  get highlightZoneName() {
    return this.type ? HIGHLIGHT_NAME_BY_TYPE[this.type] : undefined;
  }

  @computed
  get color() {
    return this.type ? COLORS_BY_DATE_TYPE[this.type] ?? '#BABABA' : '#BABABA';
  }

  @computed
  get reminders() {
    return this.kindItem?.reminders || [];
  }

  reminderTexts(userId: string) {
    const reminders = this.reminders.filter((r) => r.userId === userId);
    if (reminders.length === 0) {
      return ['No reminder set'];
    }
    const ChannelDetails = {
      [KeyDateReminderChannel.EMAIL]: 'Email',
      [KeyDateReminderChannel.SMS]: 'SMS',
      [KeyDateReminderChannel.BOTH]: 'Email and SMS',
    };

    return reminders.map((r) => {
      return (
        `${
          r.channel === KeyDateReminderChannel.UNKNOWN
            ? ''
            : ChannelDetails[r.channel]
        } ` +
        `${r.scheduleCount} ` +
        `${r.scheduleType === 'DAY' ? 'day' : 'week'}` +
        `${r.scheduleCount > 1 ? 's ' : ' '}` +
        'before'
      );
    });
  }

  @computed
  get computedDate() {
    return this.kindItem?.computedDate;
  }

  @computed
  get isComplete() {
    return this.kindItem?.isComplete;
  }

  @computed
  get completedAt() {
    return this.kindItem?.completedAt;
  }

  @computed
  get dateKind() {
    return this.kindItem?.kind;
  }

  @computed
  get uuid() {
    return this.kindItem?.uuid;
  }

  @computed
  get useBusinessDays() {
    return this.kindItem?.useBusinessDays;
  }

  @computed
  get avoidHoliday() {
    return this.kindItem?.avoidHoliday;
  }

  @computed
  get holidayAdjusted() {
    return get(this.relative, 'holidayAdjusted');
  }

  @computed
  get weekendAdjusted() {
    return get(this.relative, 'weekendAdjusted');
  }

  @override
  get title() {
    return this.kindItem?.title ?? '';
  }

  @computed
  get isAcceptance() {
    return this.kindItem?.type === KeyDateEnum.ACCEPTANCE;
  }

  @computed
  get isEscrow() {
    return this.kindItem?.type === KeyDateEnum.CLOSE_ESCROW;
  }

  @computed
  get isRelativeToEscrow() {
    return (
      this.isRelative &&
      get(this.relative, 'relativeTo') === KeyDateEnum.CLOSE_ESCROW
    );
  }

  @computed
  get isRelative() {
    return this.kindItem?.kind === 'RELATIVE';
  }

  @computed
  get isMilestoneDate() {
    return this.kindItem?.type !== KeyDateEnum.CUSTOM;
  }

  @computed
  get isOcr() {
    return this.kindItem?.isOcr;
  }

  @computed
  get isWaived(): boolean {
    if (!this.isRelative) {
      return false;
    }

    return get(this.kindItem, 'relative.isWaived');
  }

  @computed
  get isActive() {
    return !this.isWaived && !!this.computedDate;
  }

  @computed
  get isTimelineDate() {
    return this.type !== KeyDateEnum.PURCHASE_AGREEMENT;
  }

  @computed
  get isFixedOnly() {
    return (
      this.type === KeyDateEnum.PURCHASE_AGREEMENT ||
      this.type === KeyDateEnum.ACCEPTANCE
    );
  }

  @computed
  get isTimelineActive() {
    return this.isActive && this.isTimelineDate;
  }

  @computed
  get isEmptyDate() {
    return !this.isWaived && !this.computedDate;
  }

  @computed
  get daysFromToday() {
    if (!this.computedDate) {
      return;
    }

    const computedDate = this.computedDate
      ? moment(this.computedDate, 'x')
      : undefined;
    const today = moment().startOf('day');

    // eslint-disable-next-line consistent-return
    return computedDate?.diff(today, 'days');
  }

  @computed
  get daysFromTodayDisplay() {
    if (this.daysFromToday === undefined) {
      return '';
    }

    if (this.daysFromToday === 0) {
      return 'is Today';
    }

    if (this.daysFromToday < 0) {
      return (
        `${Math.abs(this.daysFromToday)} Day` +
        `${this.daysFromToday !== 1 ? 's' : ''} past due`
      );
    }

    return (
      `in ${Math.abs(this.daysFromToday)} Day` +
      `${this.daysFromToday !== 1 ? 's' : ''}`
    );
  }

  @computed
  get momentDt() {
    return this.computedDate && moment(this.computedDate, 'x');
  }

  @computed
  get daysFromClosing() {
    if (!this.daysFromToday) {
      return '';
    }

    if (!this.isEscrow) {
      return '';
    }

    return (
      `${Math.abs(this.daysFromToday)} day` +
      `${this.daysFromToday !== 1 ? 's ' : ' '}` +
      `${this.daysFromToday < 0 ? 'since ' : 'until '}` +
      'Closing'
    );
  }

  daysFromAcceptanceDisplay(acceptanceDate: KeyDate) {
    if (this.isAcceptance) {
      return '';
    }

    if (!this.computedDate) {
      return '';
    }

    if (this.isWaived) {
      return '';
    }

    const computedDate = this.computedDate && moment(this.computedDate, 'x');
    const acceptanceDateMoment = moment(acceptanceDate.computedDate, 'x');
    const relativeDateType = this.isRelativeToEscrow
      ? 'Close of Escrow'
      : 'Acceptance';

    const daysFromAcceptance = this.isRelative
      ? Number(this.relative?.delta)
      : computedDate && computedDate.diff(acceptanceDateMoment, 'days');

    return (
      `${Math.abs(daysFromAcceptance)} ` +
      `${this.useBusinessDays && this.isRelative ? 'business day' : 'day'}` +
      `${daysFromAcceptance !== 1 ? 's ' : ' '}` +
      `${daysFromAcceptance < 0 ? 'before ' : 'after '}` +
      `${relativeDateType}`
    );
  }
}
