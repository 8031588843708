/* Generated file, please DO NOT edit directly */
/* See jsapp/proto2ts.sh */

/* eslint-disable */

export const protobufPackage = 'verifications';

export interface VerificationMethod {
  id: string;
  kind: VerificationMethodKind;
  sso?: VerificationMethodSsoIntegration;
  nar?: VerificationMethodNarIntegration;
  none?: VerificationMethodNoIntegration;
  custom?: VerificationMethodCustomIntegration;
  nwmls?: VerificationMethodNwmlsIntegration;
  car?: VerificationMethodCarIntegration;
  gar?: VerificationMethodGarIntegration;
  bridge?: VerificationMethodBridgeIntegration;
  selfVerification?: VerificationMethodSelfVerificationIntegration;
  providerDependent?: VerificationMethodProviderDependent;
}

export const VerificationMethodKind = {
  SSO: 'SSO',
  NAR: 'NAR',
  NONE: 'NONE',
  NWMLS: 'NWMLS',
  CAR: 'CAR',
  GAR: 'GAR',
  BRIDGE: 'BRIDGE',
  SELF_VERIFICATION: 'SELF_VERIFICATION',
  PROVIDER_DEPENDENT: 'PROVIDER_DEPENDENT',
  /** CUSTOM - More could be added later if other methods of integration are discovered */
  CUSTOM: 'CUSTOM',
} as const;

export type VerificationMethodKind =
  typeof VerificationMethodKind[keyof typeof VerificationMethodKind];

/** These are not written in stone, feel free to change them as necessary */
export interface VerificationMethodSsoIntegration {
  verifyEndpoint: string;
  /** Possibly have a separate endpoint we call for testing purposes */
  devVerifyEndpoint: string;
}

export interface VerificationMethodNarIntegration {
  /**
   * This represents credentials sent to us directly by an association.
   * These should be encrypted and stored in our DB. See how we manage
   * oauth in the integrations folder.
   */
  username: string;
  password: string;
}

export interface VerificationMethodCarIntegration {}

export interface VerificationMethodGarIntegration {}

export interface VerificationMethodBridgeIntegration {
  bridgeProvider: VerificationMethodBridgeIntegrationBridgeProvider;
}

export const VerificationMethodBridgeIntegrationBridgeProvider = {
  UNKNOWN: 'UNKNOWN',
  RHODE_ISLAND: 'RHODE_ISLAND',
} as const;

export type VerificationMethodBridgeIntegrationBridgeProvider =
  typeof VerificationMethodBridgeIntegrationBridgeProvider[keyof typeof VerificationMethodBridgeIntegrationBridgeProvider];

export interface VerificationMethodSelfVerificationIntegration {}

export interface VerificationMethodProviderDependent {
  providerList: VerificationMethodProviderDependentProviderIdKind[];
  failureMessage: string;
}

export interface VerificationMethodProviderDependentProviderIdKind {
  providerId: string;
  providerKind: VerificationRequestOrgKind;
}

export interface VerificationMethodNwmlsIntegration {
  /**
   * This represents credentials sent to us directly by an association.
   * These should be encrypted and stored in our DB. See how we manage
   * oauth in the integrations folder.
   */
  username: string;
  password: string;
}

/** We use this when no verification is required */
export interface VerificationMethodNoIntegration {}

/**
 * This is a catch-all we use when we need to handle the integration directly
 * in code. This should be a hint to the controller that we need to use
 * the handler string to map to a custom integration. We could optionally
 * not have a handler string and instead map custom integrations to the
 * id of the Association or MLS. This detail is left to the implementer.
 *
 * The idea with this handler is that we use it to handle novel or unique
 * integrations, while keeping an eye out for patterns so that we can create
 * a new Kind and handle them more generically if possible.
 */
export interface VerificationMethodCustomIntegration {
  handler: string;
}

/**
 * This is a copy of the kinds from VerificationMethod. There may be a
 * better way to share Kind data across messages. If so, please use it.
 * NONE is excluded here because we wouldn't need verificationData in that
 * case.
 */
export interface VerificationData {
  id: string;
  kind: VerificationMethodKind;
  sso?: VerificationData_SSO;
  nar?: VerificationData_NAR;
  custom?: { [key: string]: any };
  none?: VerificationDataNoIntegration;
  car?: VerificationData_CAR;
  gar?: VerificationData_GAR;
  selfVerification?: VerificationDataSelfVerification;
  bridge?: VerificationDataBridge;
}

export interface VerificationData_SSO {
  verificationToken: string;
}

export interface VerificationData_NAR {}

export interface VerificationDataNoIntegration {}

export interface VerificationData_CAR {
  linkedAccountId: string;
}

export interface VerificationData_GAR {
  garId: string;
}

export interface VerificationDataBridge {}

export interface VerificationDataSelfVerification {
  verifiedAt: number;
}

export interface VerificationRequest {
  kind: VerificationMethodKind;
  orgKind: VerificationRequestOrgKind;
  source: VerificationRequestSource;
  nar?: VerificationRequest_NAR;
  none?: VerificationRequestNoIntegration;
  nwmls?: VerificationRequestNwmlsIntegration;
  custom?: VerificationRequestCustomIntegration;
  car?: VerificationRequest_CAR;
  gar?: VerificationRequest_GAR;
  selfVerification?: VerificationRequestSelfVerification;
  providerDependent?: VerificationRequestProviderDependent;
  bridge?: VerificationRequestBridgeIntegration;
}

export const VerificationRequestOrgKind = {
  UNKNOWN: 'UNKNOWN',
  MLS: 'MLS',
  BROKER: 'BROKER',
  MUNICIPALITY: 'MUNICIPALITY',
  NWMLS: 'NWMLS',
  REAL_ESTATE_BROKERAGE: 'REAL_ESTATE_BROKERAGE',
  BRIDGE: 'BRIDGE',
} as const;

export type VerificationRequestOrgKind =
  typeof VerificationRequestOrgKind[keyof typeof VerificationRequestOrgKind];

export const VerificationRequestSource = {
  MANUAL: 'MANUAL',
  RE_VERIFICATION: 'RE_VERIFICATION',
} as const;

export type VerificationRequestSource =
  typeof VerificationRequestSource[keyof typeof VerificationRequestSource];

export interface VerificationRequest_CAR {
  orgId: string;
}

export interface VerificationRequest_NAR {
  orgId: string;
}

export interface VerificationRequestNwmlsIntegration {
  orgId: string;
}

export interface VerificationRequest_GAR {
  orgId: string;
  garId: string;
}

export interface VerificationRequestSelfVerification {
  orgId: string;
}

export interface VerificationRequestNoIntegration {
  orgId: string;
}

export interface VerificationRequestCustomIntegration {
  orgId: string;
}

export interface VerificationRequestProviderDependent {
  orgId: string;
}

export interface VerificationRequestBridgeIntegration {
  orgId: string;
  bridgeProvider: VerificationMethodBridgeIntegrationBridgeProvider;
}

export interface Modifiers {
  canCompassBypass: boolean;
  onlyCompassVisible: boolean;
}
