import { tmLoadModal } from '@uc-tm/modal-loader';
import invariant from 'invariant';
import intersection from 'lodash/intersection';
import isFunction from 'lodash/isFunction';
import noop from 'lodash/noop';
import omit from 'lodash/omit';
import orderBy from 'lodash/orderBy';
import partial from 'lodash/partial';
import pick from 'lodash/pick';
import { computed, makeObservable } from 'mobx';
import type { SetRequired } from 'type-fest';
import analytics from 'src/analytics';
import { getColorHex } from 'src/components/common/app-label';
import navigateToFlow from 'src/components/flows/navigate-to-flow';
import type UiState from 'src/components/transactions/documents/documents-ui-state';
import { getDefaultEmailBody } from 'src/components/transactions/offers/get-default-email-message';
import {
  getErrorModalProps,
  getFrontendChecksErrorModalProps,
} from 'src/components/transactions/transaction-packages/transaction-package-submit-errors';
import {
  cancelPackagePendingAction,
  createPackagePendingAction,
  recordPackageAccessed,
  startPackageActionFlow,
} from 'src/models/transactions/intents';
import { makeNewContactRecipient } from 'src/models/transactions/recipients';
import { TRANSACTION_PACKAGE_NAMESPACE } from 'src/stores/pdf-annotations-store';
import type RouterStore from 'src/stores/router-store';
import type UiStore from 'src/stores/ui-store';
import type {
  PackageStatus,
  PackageKind,
  ItemActionType,
} from 'src/types/proto/packages';
import type {
  Item as ItemJson,
  ItemTransactionPackageSubmittedActionRecipientStatus,
} from 'src/types/proto/transactions';
import isMobileUserAgent from 'src/utils/is-mobile-user-agent';
import navigateClick from 'src/utils/navigate-click';
import titleCase from 'src/utils/title-case';
import Item, { type ItemStore } from './item';
import type Offer from './offer';
import type PropertyInfo from './property-info';

export type TransactionPackageJson = SetRequired<
  ItemJson,
  'transactionPackage'
> & {
  kind: 'TRANSACTION_PACKAGE';
};

export function setPackagesViewedInTransaction(
  transactionPackages: TransactionPackage[] = []
) {
  invariant(
    new Set(transactionPackages.map((tp) => tp.transactionId)).size <= 1,
    'Cannot call setPackagesViewedInTransaction for packages of different transactions'
  );

  const transaction = transactionPackages?.[0]?.transaction;
  const isCurrentUserParty = Boolean(transaction?.parties?.me);
  if (!isCurrentUserParty) {
    // Only mark offers as seen if the current user if already
    // a party to the tranasction (this would prevent support team
    // from being added to the transactioon and log the first view
    // of the offers)
    return null;
  }

  const tpIds = (transactionPackages || [])
    .filter((tp) => tp && !tp.isDraft && !tp.accessed)
    .map((tp) => tp.id);
  if (tpIds.length) {
    return transactionPackages[0].store.dispatch(
      transaction!.id,
      recordPackageAccessed(tpIds)
    );
  }
  return null;
}

export function resendPackage(
  transactionPackage: TransactionPackage,
  { ui }: { ui: UiStore }
) {
  const userPackageKind = transactionPackage.userPackageKind;
  if (transactionPackage.canResend) {
    ui.confirm({
      title: `Resend this ${userPackageKind} package?`,
      onOk: async () => {
        await transactionPackage.resend();
        ui.toast({
          message: `${titleCase(userPackageKind)} resent`,
          type: 'success',
        });
      },
      onCancel: noop,
    });
  }
}

export const ACTION_LABELS = (
  action?: ItemActionType,
  revision?: number
): Record<'title' | 'noun' | 'past', string> => {
  if (!action) {
    return {
      title: 'Prepare',
      noun: 'Offer',
      past: 'Prepared',
    };
  }

  if (revision) {
    return {
      title: 'Revise',
      noun: 'Revision',
      past: 'Revised',
    };
  }

  switch (action) {
    case 'VOID':
      return {
        title: 'cancel',
        noun: 'Cancelled',
        past: 'Cancelled',
      };

    case 'UNDO':
      return {
        title: 'Undo',
        noun: 'Undo',
        past: 'Undone',
      };

    case 'ACCEPT':
      return {
        title: 'Accept',
        noun: 'Acceptance',
        past: 'Accepted',
      };

    case 'REJECT':
      return {
        title: 'Reject',
        noun: 'Rejection',
        past: 'Rejected',
      };

    case 'COUNTER_ACCEPT':
      return {
        title: 'Accept',
        noun: 'Acceptance',
        past: 'Accepted',
      };

    default:
      return {
        title: titleCase(action.toLowerCase()),
        noun: titleCase(action.toLowerCase()),
        past: titleCase(action.toLowerCase()).concat('ed'),
      };
  }
};

export const QUICK_ACTIONS = new Set<ItemActionType>(['REJECT', 'UNDO']);
// After UNDO, offer goes back to submit, should be revisable
export const REVISABLE_ACTION_TYPES: SetRequired<
  Partial<Record<PackageKind, Set<ItemActionType>>>,
  'OFFER'
> = {
  OFFER: new Set<ItemActionType>(['SUBMIT', 'COUNTER', 'ACCEPT', 'UNDO']),
};

type ExtraActionContext = {
  ui: UiStore;
  offerPackage: TransactionPackage;
  onPrint?: () => void;
};

// extra actions for transaction package
export const EXTRA_ACTIONS: Record<
  'REVISE' | 'RESEND' | 'PRINT' | 'UPLOAD_FINAL_CONTRACT',
  {
    condition: (params: ExtraActionContext) => boolean;
    action: (params: ExtraActionContext) => {
      onClick: () => void | Promise<void>;
      label: string;
      type: string;
    };
  }
> = {
  REVISE: {
    condition: ({ offerPackage }) => {
      const {
        offer,
        store: {
          parent: {
            ui: { isEmbedded },
          },
        },
      } = offerPackage;

      if (!offerPackage.canRevise) {
        return false;
      }

      if (offer.isAccepted && isEmbedded) {
        return false;
      }

      return true;
    },
    action: ({ offerPackage }) => ({
      onClick: offerPackage.revise,
      label: 'Revise',
      type: 'default',
    }),
  },
  RESEND: {
    condition: ({ offerPackage }) => {
      const {
        offer,
        store: {
          parent: {
            ui: { isEmbedded },
          },
        },
      } = offerPackage;

      if (!offerPackage.canResend) {
        return false;
      }

      if (offer.isInFinalState && isEmbedded) {
        return false;
      }

      return true;
    },
    action: ({ offerPackage, ui }) => ({
      onClick: () => resendPackage(offerPackage, { ui }),
      label: 'Resend',
      type: 'default',
    }),
  },
  PRINT: {
    condition: ({ ui }) => !ui?.isEmbedded && !isMobileUserAgent(),
    action: ({ onPrint }) => ({
      onClick: onPrint!,
      label: 'Print',
      type: 'default',
    }),
  },
  UPLOAD_FINAL_CONTRACT: {
    condition: ({ offerPackage }) => {
      const {
        offer,
        store: {
          parent: {
            ui: { isEmbedded },
          },
        },
      } = offerPackage;

      // when accepted with no revisions, allow final contract upload
      // after this upload increases revision count, hide button
      return (
        isEmbedded &&
        offer.isAccepted &&
        offerPackage.canRevise &&
        !offerPackage.hasRevision
      );
    },
    action: ({ offerPackage }) => ({
      // create revision (to hide upload button after acceptance)
      onClick: () =>
        offerPackage.startAcceptSetupFlow({ isObo: true, revision: true }),
      label: 'Upload final contract',
      type: 'default',
    }),
  },
};

type DetailHandler = (
  tp: TransactionPackage,
  context: {
    kind: PackageKind;
    status: PackageStatus;
  } & Parameters<GetDetails>[1]
) => string;

interface DetailResult {
  statusColor?: string;
  statusLabel?: string | DetailHandler;
  statusDescription?: string | DetailHandler;
  pendingActionDescription?: string | DetailHandler;
}

const DETAILS: SetRequired<
  Partial<
    Record<
      '__default' | PackageKind,
      { __default: DetailResult } & Partial<Record<PackageStatus, DetailResult>>
    >
  >,
  '__default' | 'OFFER'
> = {
  __default: {
    DRAFT: {},
    SUBMITTED: {},
    __default: {
      statusLabel: (_tp, { status: packageStatus }) => titleCase(packageStatus),
      statusColor: getColorHex('BLUE'),
      statusDescription: (_tp, { status: packageStatus }) =>
        `The package is ${titleCase(packageStatus)}`,
    },
  },
  OFFER: {
    __default: {
      pendingActionDescription: (tp) => {
        const actionLabels = ACTION_LABELS(
          tp.pendingActionType,
          tp.pendingAction?.revision
        );
        return `It looks like your ${actionLabels.noun} is in progress but hasn’t been submitted.`;
      },
    },
    DRAFT: {},
    SUBMITTED: {
      statusLabel: (tp) => (tp.isListing ? 'Pending' : 'Submitted'),
      statusDescription: (tp) =>
        tp.isListing
          ? 'You have received this offer and need to decide on it.'
          : 'This offer has been submitted to the Listing Agent for Review.',
    },
    COUNTERED: {
      statusLabel: (tp) =>
        tp.offer.pending && !tp.offer?.isFlexibleReviseOfferFlowEnabled
          ? 'Received Counter'
          : 'Countered',
      statusDescription: (tp) =>
        tp.offer.pending && !tp.offer?.isFlexibleReviseOfferFlowEnabled
          ? 'This offer has been countered.'
          : 'You have countered this offer.',
    },
    COUNTER_ACCEPTED: {
      statusLabel: 'Accepted (Pending)',
      statusDescription: (tp) =>
        tp.offer.pending
          ? 'This offer is awaiting your acceptance.'
          : 'This offer is awaiting acceptance from the other side.',
    },
    COMPLETED: {
      statusLabel: 'Accepted',
      statusDescription: 'Congratulations! This offer has been accepted.',
    },
    REJECTED: {
      statusLabel: 'Rejected',
      statusDescription: 'This offer has been rejected.',
    },
    VOIDED: {
      statusLabel: 'Cancelled',
      statusDescription: 'This offer has been cancelled.',
    },
  },
};

export type GetDetails = (
  transactionPackage: TransactionPackage,
  context?: Record<string, unknown>
) => Record<
  | 'statusLabel'
  | 'statusDescription'
  | 'statusColor'
  | 'pendingActionDescription',
  string
>;

export const getDetails: GetDetails = (transactionPackage, context) => {
  const [kind, packageStatus] = [
    transactionPackage.packageKind,
    transactionPackage.packageStatus,
  ];
  const kindDetails = DETAILS[kind];
  const details = {
    ...DETAILS.__default.__default,
    ...DETAILS.__default[packageStatus],
    ...kindDetails?.__default,
    ...kindDetails?.[packageStatus],
    statusColor:
      packageStatus === 'SUBMITTED'
        ? getColorHex('YELLOW')
        : getColorHex('BLACK'),
  };
  return Object.entries(details)
    .filter(([, v]) => Boolean(v))
    .reduce<ReturnType<GetDetails>>(
      (res, [k, v]) => ({
        ...res,
        [k]: isFunction(v)
          ? v(transactionPackage, {
              kind,
              status: packageStatus,
              ...context,
            })
          : v,
      }),
      {} as ReturnType<GetDetails>
    );
};

export const getPackageActionHandler =
  (
    tp: TransactionPackage,
    uiState: UiState,
    action: ItemActionType,
    detached_: boolean,
    isObo: boolean,
    isEmbedded: boolean
  ) =>
  async () => {
    const { transactionId, packageStatus } = tp;
    const { router } = tp.store.parent;
    const isOfferPackageRouter =
      router.getRoute().name === 'transactions.transaction.offers';
    const detached = Boolean(tp.detachedFromPackage || detached_);
    if (detached || isObo) {
      const res = await tp.executeAction(
        action,
        undefined,
        undefined,
        detached,
        Boolean(isObo)
      );
      Promise.resolve(res);

      if (
        action === 'UNDO' &&
        isOfferPackageRouter &&
        packageStatus === 'COMPLETED'
      ) {
        router.navigate('transactions.transaction', {
          transactionId,
        });
      }
      return null;
    }

    // If we are in the embedded experience we don't want any action to send an email/notification
    if (isEmbedded) {
      const res = await tp.executeAction(
        action,
        undefined,
        undefined,
        true,
        Boolean(isObo)
      );
      Promise.resolve(res);

      return null;
    }

    const address = tp.address;
    const defaultTo = tp.offer?.otherSidePrimaryAgent
      ? [makeNewContactRecipient(tp.offer.otherSidePrimaryAgent)]
      : [];
    const actionLabels = ACTION_LABELS(action, tp.pendingAction?.revision);
    uiState.showEmailModal([], {
      emailPreviewProps: {
        kind: 'REJECT_OFFER',
        params: { transactionPackageId: tp.id, transactionId },
      },
      defaultHtmlBody: getDefaultEmailBody('REJECT_OFFER'),
      includeAgentSignature: true,
      defaultSubject: `Offer to ${address.street} ${actionLabels.past}`,
      defaultTo,
      onOk: partial(tp.executeAction, action, {}),
      onClose: () => {
        Promise.resolve(null);
      },
      modalProps: {
        title: `${actionLabels.title} Offer`,
      },
    });

    return null;
  };

export default class TransactionPackage extends Item<
  ItemStore,
  TransactionPackageJson,
  'TRANSACTION_PACKAGE'
> {
  resolvedItems = ['members'];

  constructor(store: ItemStore, json: TransactionPackageJson) {
    super(store, json);

    makeObservable(this);
  }

  get features() {
    return this.store.parent.features;
  }

  @computed
  get namespace() {
    return TRANSACTION_PACKAGE_NAMESPACE;
  }

  getFieldIsUnlinked() {
    return false;
  }

  get isListing() {
    return Boolean(this.transaction?.isListing);
  }

  @computed
  get isPurchase() {
    return !this.isListing;
  }

  @computed
  get packageKind() {
    return this.kindItem.packageKind;
  }

  @computed
  get userPackageKind() {
    return (this.kindItem.packageKind || '').toLowerCase().replace(/_+/g, ' ');
  }

  @computed
  get userPackageKindArticle() {
    return new Set(['OFFER']).has(this.packageKind) ? 'an' : 'a';
  }

  @computed
  get userPackageKindWithArticle() {
    return `${this.userPackageKindArticle} ${this.userPackageKind}`;
  }

  @computed
  get packageStatus() {
    return this.kindItem.packageStatus || 'DRAFT';
  }

  @computed
  get accessed() {
    return Boolean(this.kindItem.accessed);
  }

  @computed
  get isDraft() {
    return this.packageStatus === 'DRAFT';
  }

  @computed
  get setupFlowId() {
    return this.kindItem.setupFlowId;
  }

  @computed
  get detachedFromPackage() {
    return Boolean(this.kindItem.detachedFromPackage);
  }

  @computed
  get propertyId() {
    const propertyInfoIds =
      this.inEdgeIdsByKind('PROPERTY_HAS_TRANSACTION_PACKAGE') || [];
    return propertyInfoIds[0];
  }

  @computed
  get propertyInfo() {
    const propertyId = this.propertyId;
    return propertyId
      ? (this.store.getItem(
          this.transactionId,
          'PROPERTY_INFO',
          this.propertyId
        ) as PropertyInfo)
      : null;
  }

  @computed
  get tdIds() {
    return this.outEdgeIdsByKind('PACKAGE_HAS_TD');
  }

  @computed
  get purchaseAgreementTdIds() {
    const kind = 'PACKAGE_HAS_TD';
    return this.outEdges
      .filter((e) => e.kind === kind && e.data?.is_purchase_agreement)
      .map((e) => e.item2Id);
  }

  @computed
  get tds() {
    return this.itemsById(this.tdIds);
  }

  @computed
  get pendingTdIds() {
    return this.outEdgeIdsByKind('PACKAGE_PENDING_ACTION_HAS_TD');
  }

  @computed
  get address() {
    return this.propertyId
      ? this.propertyInfo?.address
      : this.transaction?.address;
  }

  getPendingTds(includeInTrash = false) {
    return orderBy(
      this.itemsById(this.pendingTdIds).filter(
        (td) => includeInTrash || !td.isInTrash
      ),
      ['index', (td) => +td.id],
      ['asc', 'desc']
    );
  }

  @computed
  get pendingTds() {
    return this.getPendingTds(false);
  }

  @computed
  get activeTdIds() {
    const pendingTdIds = new Set(this.pendingTdIds);
    return this.tdIds.filter((tdId) => !pendingTdIds.has(tdId));
  }

  getActiveTds(includeInTrash = false) {
    return this.itemsById(this.activeTdIds).filter(
      (td) => includeInTrash || !td.isInTrash
    );
  }

  @computed
  get activeTds() {
    return this.getActiveTds(false);
  }

  @computed
  get partyIds() {
    return this.outEdgeIdsByKind('PACKAGE_HAS_PARTY');
  }

  getOrFetchParties = async () => {
    return this.store.getOrFetchItemMulti(
      this.transaction!.id,
      'PARTY',
      this.partyIds
    );
  };

  @computed
  get parties() {
    return this.itemsById(this.partyIds);
  }

  @computed
  get namespaceScopeEdgeKinds() {
    return {
      propertyInfoEdgeKind: 'PROPERTY_HAS_TRANSACTION_PACKAGE',
    };
  }

  getPartyByRole(role: string) {
    return this.parties.find((p) => p.roles.includes(role));
  }

  get opposingSidesAgentName() {
    const role = this.isListing ? 'BUYER_AGENT' : 'LISTING_AGENT';
    const opposingSidesAgent = this.getPartyByRole(role);
    return opposingSidesAgent
      ? opposingSidesAgent.fullName
      : role.toLowerCase().replace('_', ' ');
  }

  @computed
  get srEdges() {
    return this.outEdges.filter(
      (e) => e.kind === 'PACKAGE_HAS_SIGNATURE_REQUEST'
    );
  }

  @computed
  get srIds() {
    return this.srEdges.map((e) => e.item2Id);
  }

  @computed
  get srs() {
    return this.itemsById(this.srIds);
  }

  @computed
  get pendingSrIds() {
    return this.outEdgeIdsByKind(
      'PACKAGE_PENDING_ACTION_HAS_SIGNATURE_REQUEST'
    );
  }

  @computed
  get pendingSrs() {
    return this.itemsById(this.pendingSrIds);
  }

  @computed
  get completedPendingSrs() {
    return this.pendingSrs.filter((sr) =>
      ['COMPLETED', 'VOIDED'].includes(sr.status)
    );
  }

  @computed
  get isSubmittingFailed() {
    // If uuid on pending action is set, that means that the action started execution but
    // failed before syncing back to source transaction package's data dictionary
    const submittedAction = this.submittedAction;
    return Boolean(
      this.pendingAction?.uuid ||
        (submittedAction?.actionSubmittedFromOwnSide &&
          submittedAction.messageFailed)
    );
  }

  @computed
  get isSubmittingWaiting() {
    const submittedAction = this.submittedAction;
    return Boolean(
      submittedAction?.actionSubmittedFromOwnSide &&
        !submittedAction.messageSent &&
        !submittedAction.messageFailed
    );
  }

  @computed
  get isSubmittingAction() {
    return this.isSubmittingFailed || this.isSubmittingWaiting;
  }

  @computed
  get pendingAction() {
    const action = this.kindItem.pendingAction;
    return action?.actionType ? action : null;
  }

  @computed
  get pendingActionType() {
    return this.pendingAction?.actionType;
  }

  @computed
  get pendingActionStatus() {
    return this.pendingActionType ? `PENDING_${this.pendingActionType}` : null;
  }

  @computed
  get pendingActionUploaded() {
    return this.pendingAction?.setupType === 'UPLOAD';
  }

  @computed
  get pendingActionCreated() {
    return this.pendingAction?.setupType === 'CREATE';
  }

  @computed
  get pendingActionNoSignatures() {
    return !this.pendingActionAwaitingSignatures && !this.pendingActionComplete;
  }

  @computed
  get pendingActionAwaitingSignatures() {
    return this.pendingSrIds.length > 0 && !this.pendingActionComplete;
  }

  @computed
  get pendingActionComplete() {
    return (
      this.pendingSrIds.length > 0 &&
      this.completedPendingSrs.length === this.pendingSrIds.length
    );
  }

  @computed
  get pendingActionIsQuickAction() {
    return this.pendingActionType && QUICK_ACTIONS.has(this.pendingActionType);
  }

  @computed
  get isPendingCounter() {
    return this.pendingAction?.actionType === 'COUNTER';
  }

  @computed
  get actionSetupFlowId() {
    return this.pendingAction?.actionSetupFlowId;
  }

  @computed
  get counterKind() {
    return this.pendingAction?.counterKind;
  }

  @computed
  get submittedAction() {
    const action = this.kindItem.submittedAction;
    return action?.actionType ? action : null;
  }

  @computed
  get hasRevision() {
    return Boolean(
      (this.submittedAction && this.submittedAction.revision > 0) ||
        (this.pendingAction && this.pendingAction.revision > 0)
    );
  }

  @computed
  get deliveredWithExternalApp() {
    return this.submittedAction?.emailApp;
  }

  getRecipientsStatus(excludeThisSidesTeamRecipients = true) {
    // for excludeThisSidesTeamRecipients parties on the package have to be available in store
    const thisSideRoles = this.transaction?.parties?.thisSideRoles;
    const thisSidesTeamEmails = excludeThisSidesTeamRecipients
      ? new Set(
          this.parties
            .filter(
              (p) =>
                intersection(thisSideRoles, p.roles).length === p.roles.length
            )
            .map((p) => p.email)
        )
      : null;
    return Object.entries(this.submittedAction?.recipientsStatus || {})
      .filter(
        ([email]) =>
          !excludeThisSidesTeamRecipients || !thisSidesTeamEmails?.has(email)
      )
      .reduce<
        Record<string, ItemTransactionPackageSubmittedActionRecipientStatus>
      >(
        (all, [k, v]) => ({
          ...all,
          [k]: v,
        }),
        {}
      );
  }

  @computed
  get canRevise() {
    return Boolean(
      !this.pendingAction &&
        this.submittedAction &&
        this.submittedAction.actionSubmittedFromOwnSide &&
        REVISABLE_ACTION_TYPES[this.packageKind]?.has(
          this.submittedAction.actionType
        )
    );
  }

  revise = async () => {
    const { api, router, ui } = this.store.parent;
    // New FL counter offer flow is the same with submit(revise) flow
    if (this.offer.isFlexibleReviseOfferFlowEnabled) {
      if (this.packageStatus === 'COUNTERED') {
        this.createPendingRevision('COUNTER');
        return;
      }
      this.createPendingRevision('SUBMIT');
      return;
    }
    try {
      const {
        data: { flowId },
      } = await api.transactions.reviseTxnPckg(this.transactionId, this.id);
      await navigateToFlow(router, flowId);
    } catch (err) {
      ui.wentWrong(err);
    }
  };

  @computed
  get canResend() {
    if (
      this.packageStatus === 'COUNTERED' &&
      this.offer.isFlexibleReviseOfferFlowEnabled
    ) {
      return false;
    }
    return Boolean(
      !this.pendingAction &&
        !this.detachedFromPackage &&
        this.submittedAction?.actionSubmittedFromOwnSide &&
        (Object.keys(this.getRecipientsStatus()).length ||
          this.deliveredWithExternalApp)
    );
  }

  @computed
  get deliveredEmailStatuses() {
    return new Set(['delivered', 'opened', 'clicked'] as const);
  }

  @computed
  get failedEmailStatuses() {
    return new Set(['failed', 'reported'] as const);
  }

  isEmailStatusDelivered = (s: string) =>
    this.deliveredEmailStatuses.has(s as 'delivered' | 'opened' | 'clicked');
  isEmailStatusFailed = (s: string) =>
    this.failedEmailStatuses.has(s as 'failed' | 'reported');
  isEmailStatusPending = (s: string) =>
    !this.isEmailStatusDelivered(s) && !this.isEmailStatusFailed(s);

  @computed
  get allDelivered() {
    const statuses = Object.values(this.getRecipientsStatus());
    return (
      statuses.length > 0 &&
      statuses.every((s) => this.isEmailStatusDelivered(s.status))
    );
  }

  @computed
  get anyDelivered() {
    return Object.values(this.getRecipientsStatus()).some((s) =>
      this.isEmailStatusDelivered(s.status)
    );
  }

  @computed
  get allFailed() {
    const statuses = Object.values(this.getRecipientsStatus());
    return (
      statuses.length > 0 &&
      statuses.every((s) => this.isEmailStatusFailed(s.status))
    );
  }

  @computed
  get anyFailed() {
    return Object.values(this.getRecipientsStatus()).some((s) =>
      this.isEmailStatusFailed(s.status)
    );
  }

  @computed
  get allPending() {
    const statuses = Object.values(this.getRecipientsStatus());
    return (
      statuses.length > 0 &&
      statuses.every((s) => this.isEmailStatusPending(s.status))
    );
  }

  @computed
  get anyPending() {
    return Object.values(this.getRecipientsStatus()).some((s) =>
      this.isEmailStatusPending(s.status)
    );
  }

  @computed
  get hasDeliveryError() {
    return Boolean(this.submittedAction?.messageFailed || this.anyFailed);
  }

  @computed
  get folderId() {
    return this.outEdges.find((e) => e.kind === 'PACKAGE_HAS_FOLDER')?.item2Id;
  }

  @computed
  get folder() {
    return this.store.itemsById.get(this.folderId);
  }

  get members() {
    return (this.kindItem.membersIds || [])
      .map((mId) => this.store.itemsById.get(mId))
      .filter(Boolean);
  }

  @computed
  get isOffer() {
    return this.packageKind === 'OFFER';
  }

  // TODO refactor with delegates
  @computed
  get offer() {
    if (this.isOffer) {
      return this.members.find((m) => m.kind === 'OFFER');
    }

    return undefined;
  }

  @computed
  get hasOffer() {
    return Boolean(this.isOffer && this.offer);
  }

  // TODO refactor with delegates
  @computed
  get summaryRoute(): {
    name?: string;
    params?: {
      transactionId?: string;
      propertyId?: string;
      offerId?: string;
    };
  } {
    if (this.isOffer) {
      if (this.propertyId) {
        return {
          name: 'transactions.transaction.properties.property',
          params: {
            transactionId: this.transactionId,
            propertyId: this.propertyId,
          },
        };
      }

      return {
        name: `transactions.transaction.offers${
          this.isListing ? '.offer' : ''
        }`,
        params: {
          transactionId: this.transactionId,
          ...(this.isListing
            ? {
                offerId: this.offer.id,
              }
            : {}),
        },
      };
    }

    return {};
  }

  @computed
  get summaryRouteArgs() {
    return [this.summaryRoute.name, this.summaryRoute.params || {}] as const;
  }

  // TODO refactor with delegates
  @computed
  get prepareRoute(): {
    name?: string;
    params?: Record<'transactionId' | 'propertyId' | 'offerId', string>;
  } {
    if (this.isOffer) {
      const { transactionId, propertyId, offer, isListing } = this;
      const offerId = isListing || propertyId ? offer?.id : undefined;
      return {
        name: `transactions.transaction.offers${
          offerId ? '.offer' : ''
        }.prepare`,
        params: {
          transactionId,
          propertyId,
          offerId,
        },
      };
    }

    return {};
  }

  // TODO refactor with delegates
  @computed
  get prepareRouteArgs() {
    return [
      this.prepareRoute.name as string,
      this.prepareRoute.params || {},
    ] as const;
  }

  get isListingTxn() {
    return this.transaction?.isSale;
  }

  goToSetupFlow = async () => {
    try {
      await navigateToFlow(this.store.parent.router, this.setupFlowId);
    } catch (err) {
      this.store.parent.ui.wentWrong(err);
    }
  };

  startActionSetupFlow = async (
    actionType: ItemActionType,
    options: {
      isObo?: boolean;
      revision?: boolean;
      [key: string]: unknown;
    } = {}
  ) => {
    const api = this.store.parent.api;
    const { isObo, revision } = options || {};
    try {
      const { data: flowId } = await api.transactions.getTxnPckgActionSetupFlow(
        this.transactionId,
        this.id,
        actionType,
        Boolean(isObo),
        Boolean(revision)
      );
      await navigateToFlow(this.store.parent.router, flowId, {
        noBack: true,
      });
    } catch (err) {
      this.store.parent.ui.wentWrong(err);
    }
  };

  startAcceptSetupFlow = (
    options: Parameters<typeof this.startActionSetupFlow>[1]
  ) => this.startActionSetupFlow('ACCEPT', options);
  startCounterSetupFlow = (
    options: Parameters<typeof this.startActionSetupFlow>[1]
  ) => this.startActionSetupFlow('COUNTER', options);
  startCounterAcceptSetupFlow = (
    options: Parameters<typeof this.startActionSetupFlow>[1]
  ) => this.startActionSetupFlow('COUNTER_ACCEPT', options);

  /**
   * Load confirm modal from Compass CDN by modal-loader.
   *
   * @see https://github.com/UrbanCompass/uc-frontend/tree/master/workspaces/tm/packages/modal--confirm
   */
  showPendingSignaturesWarningModal = async (data: Record<string, any>) => {
    try {
      await tmLoadModal('tm/confirm/0', data);
    } catch (err) {
      if (!err) {
        return; // cancel
      }
      console.error('Pending signatures warning modal error', err);
    }
  };

  guardMissingSignatures = async (
    options: {
      skipWarnings?: boolean;
      getSignatures?: (...args: any[]) => void | Promise<void>;
      closeOnOk?: boolean;
      skipSignWarning?: boolean;
    } = {},
    action: (skipWarnings: boolean) => Promise<any>
  ) => {
    const {
      skipWarnings,
      getSignatures,
      closeOnOk = true,
      skipSignWarning,
    } = options;
    const { ui } = this.store.parent;

    let error;
    const awaitingSignatures = this.pendingActionAwaitingSignatures;
    // Listing txn add offer fork flow allows submitting offers without documents
    if (!this.isListingTxn && !this.pendingTds.find(Boolean)) {
      error = {
        title: 'No documents',
        description:
          'Please add at least one document in your package before submitting.',
        level: 'ERROR',
      };
    } else if (this.pendingTds.find((td) => td.isFillable)) {
      error = {
        title: awaitingSignatures
          ? 'Some documents are still out for signature'
          : 'Get signatures before submitting an offer',
        description: awaitingSignatures
          ? 'Please wait until all signature requests are complete to proceed.'
          : 'Some documents in the package still need to be signed. Glide can help you collect signatures on documents in this offer package.',
        level: awaitingSignatures ? 'ERROR' : 'WARNING',
        ok: 'Get Signatures',
        onOk: getSignatures,
      };
    } else if (awaitingSignatures) {
      error = {
        title: 'Some documents are still out for signature',
        description:
          'Are you sure you want to proceed? This will cancel all pending signature requests.',
        ok: 'Submit Anyway',
        level: 'WARNING',
        onOk: () => action(true),
      };
    } else if (
      !skipSignWarning &&
      !this.pendingAction?.detached &&
      !(this.pendingActionType === 'SUBMIT' && this.transaction?.isSale) &&
      !this.pendingSrIds.length
    ) {
      error = {
        title: 'Are all your documents signed?',
        description:
          'Please confirm you have reviewed offer documents and that they contain all signatures before submitting this package.',
        level: 'WARNING',
        onOk: () => action(true),
      };
    }

    if (error) {
      const modalProps = getFrontendChecksErrorModalProps(
        this,
        ui,
        error,
        closeOnOk
      );
      const tmModalProps = pick(modalProps, [
        'onOk',
        'okText',
        'cancelText',
        'okButtonProps',
      ]);
      this.showPendingSignaturesWarningModal({
        ...tmModalProps,
        title: error.title,
        message: error.description,
      });
      return;
    }

    await action(Boolean(skipWarnings));
  };

  startActionFlow = async (
    actionType: ItemActionType,
    options: {
      skipWarnings?: boolean;
      skipSignWarning?: boolean;
      getSignatures?: (...args: any[]) => void | Promise<void>;
      revision?: boolean;
      skipNotifications?: boolean;
      keyTermsOnly?: boolean;
      ocrFields?: Record<string, unknown>;
    } = {}
  ) => {
    const { ui } = this.store.parent;

    const submitWithSkipWarning = () =>
      this.startActionFlow(actionType, {
        ...options,
        skipWarnings: true,
      });

    const {
      skipWarnings = false,
      skipSignWarning = false,
      getSignatures,
      revision = false,
      skipNotifications = false,
      keyTermsOnly = false,
      ocrFields = {},
    } = options ?? {};

    const submit = async (skipWarnings_: boolean) => {
      try {
        // Tracking call for ad networks
        analytics().track(
          `offer_${actionType.toLowerCase()}`,
          {
            event_category: 'Custom',
            event_label: `tid:${this.transaction!.id},s:${
              this.transaction!.side
            },`,
          },
          analytics().platforms
        );

        const {
          result: { flow_id: flowId },
        } = await this.store.dispatch(
          this.transactionId,
          startPackageActionFlow(
            this.id,
            actionType,
            skipWarnings_,
            revision,
            skipNotifications,
            keyTermsOnly,
            ocrFields
          )
        );
        await navigateToFlow(this.store.parent.router, flowId);
      } catch (err) {
        if ((err as any).message?.packageErrors?.errors?.length) {
          ui.setModal(
            getErrorModalProps(
              this,
              ui,
              (err as any).message.packageErrors.errors,
              submitWithSkipWarning
            )
          );
        } else {
          ui.wentWrong(err);
        }
      }
    };

    await this.guardMissingSignatures(
      { skipWarnings, skipSignWarning, getSignatures },
      submit
    );
  };

  startSubmitFlow = (options: Parameters<typeof this.startActionFlow>[1]) =>
    this.startActionFlow('SUBMIT', options);

  startAcceptFlow = (options: Parameters<typeof this.startActionFlow>[1]) =>
    this.startActionFlow('ACCEPT', options);

  startCounterFlow = (options: Parameters<typeof this.startActionFlow>[1]) =>
    this.startActionFlow('COUNTER', options);

  startCounterAcceptFlow = (
    options: Parameters<typeof this.startActionFlow>[1]
  ) => this.startActionFlow('COUNTER_ACCEPT', options);

  startVoidFlow = (options: Parameters<typeof this.startActionFlow>[1]) =>
    this.startActionFlow('VOID', options);

  startKeyTermsOnlyFlow = (
    options: Parameters<typeof this.startActionFlow>[1],
    actionType: ItemActionType
  ) => this.startActionFlow(actionType, options);

  get pendingActionLabel() {
    if (!this.pendingActionType || this.pendingActionIsQuickAction) {
      return null;
    }
    const actionLabels = ACTION_LABELS(
      this.pendingActionType,
      this.pendingAction?.revision
    );
    return `Continue Preparing ${actionLabels.noun}`;
  }

  getCounterOfferHandler(
    ui: UiStore,
    offer: Offer,
    isObo: boolean,
    uiState: UiState,
    isEmbedded: boolean
  ) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const tp = this;

    function getHandler() {
      if (offer.isFlexibleReviseOfferFlowEnabled) {
        return getPackageActionHandler(
          tp,
          uiState,
          'COUNTER',
          isObo,
          isObo,
          isEmbedded
        );
      }
      return () =>
        tp.startCounterSetupFlow({
          isObo,
        });
    }

    if (!isObo && offer.showCounterWarning) {
      return () =>
        ui.confirm({
          title: 'Are you sure?',
          content:
            'You have an existing binding Seller Counter Offer (SCO) outstanding on this transaction.',
          onOk: getHandler(),
          okText: 'Counter',
        });
    }

    return getHandler();
  }

  // TODO refactor with delegates
  getActions = (context: {
    ui: UiStore;
    uiState: UiState;
    router: RouterStore;
    excludeObo?: boolean;
    oboOnly?: boolean;
  }) => {
    const { ui, uiState, router, excludeObo, oboOnly } = context || {};
    const embeddedApp = this.store.parent.embeddedApp;
    const isEmbedded = embeddedApp?.isEmbedded ?? false;

    if (!this.hasOffer) {
      return [];
    }

    const offer = this.offer;
    const hasAcceptedOffer = offer.allOfferPackages.some(
      (v: TransactionPackage) => v.offer?.isAccepted
    );

    if (this.pendingAction) {
      if (this.pendingActionIsQuickAction || this.isSubmittingAction) {
        return [];
      }

      return [
        {
          key: 'cancel-pending',
          label: 'Cancel',
          handler: async () => {
            try {
              // Load confirm modal from Compass CDN by modal-loader.
              await tmLoadModal('tm/confirm/0', {
                title: 'Are you sure? This action is not reversible.',
                onOk: this.cancel,
                okText: 'Ok',
                okButtonProps: { danger: true },
              });
            } catch (err) {
              if (!err) {
                return;
              }
              console.error('Cancel pending action error', err);
            }
          },
          type: 'danger',
        },
        {
          key: 'continue',
          label: this.pendingActionLabel,
          handler: (e: KeyboardEvent) => {
            try {
              if (
                this.isPendingCounter &&
                this.actionSetupFlowId &&
                !this.counterKind
              ) {
                navigateToFlow(router, this.actionSetupFlowId, {
                  noBack: true,
                });
              } else {
                navigateClick(e, router, ...this.prepareRouteArgs);
              }
            } catch (err) {
              ui.wentWrong(err as Error);
            }
          },
        },
      ];
    }

    const acceptHandler = (isObo: boolean) => {
      if (
        this.packageStatus === 'COUNTERED' &&
        this.isPurchase &&
        this.submittedAction?.counterKind === 'SELLER_MULTIPLE'
      ) {
        return () =>
          this.startCounterAcceptSetupFlow({
            isObo,
          });
      }

      if (isEmbedded) {
        return getPackageActionHandler(
          this,
          uiState,
          'ACCEPT',
          isObo,
          isObo,
          isEmbedded
        );
      }

      return () => this.startAcceptSetupFlow({ isObo });
    };

    const cancelHandler = async () => {
      await this.executeAction('VOID', {
        cancel_reason: 'Cancelled by the agent',
      });
    };

    const baseActions = (isObo: boolean) => {
      const retval: {
        key: string;
        label: string;
        type?: string;
        handler: (...args: unknown[]) => unknown;
        isObo: boolean;
        disabled?: boolean;
        tooltip?: string | null;
      }[] = [
        {
          key: 'accept',
          label: 'Accept',
          handler: acceptHandler(isObo),
          isObo,
          disabled: hasAcceptedOffer,
          tooltip: hasAcceptedOffer
            ? 'Another offer is already accepted'
            : null,
        },
        {
          key: 'counter',
          label: 'Counter',
          handler: this.getCounterOfferHandler(
            ui,
            offer,
            isObo,
            uiState,
            isEmbedded
          ),
          disabled: offer.disabledCounterMessage,
          tooltip: offer.disabledCounterMessage,
          isObo,
        },
        {
          key: 'reject',
          label: 'Reject',
          type: 'danger',
          handler: getPackageActionHandler(
            this,
            uiState,
            'REJECT',
            isObo,
            isObo,
            isEmbedded
          ),
          isObo,
        },
      ];
      // Base actions include Cancel Offer if no DMS compliance flow initiated
      if (!embeddedApp?.dmsTransactionData?.isSyncingOfferInfoLocked) {
        retval.push({
          key: 'cancel',
          label: 'Cancel Offer',
          handler: cancelHandler,
          isObo: false,
        });
      }
      return retval;
    };

    if (offer.canDecide) {
      return oboOnly ? [] : baseActions(false);
    }

    if (offer.isInFinalState) {
      // Exclude UNDO action when Glide is embedded within a BT deal with offer sent to compliance
      // Don't allow UNDO if offer is cancelled (VOIDED). Undoing a cancel leads to not being able
      // to undo the preceding accept/reject.
      const actions: {
        key: string;
        label: string;
        handler: (...args: unknown[]) => unknown;
        isObo: boolean;
      }[] =
        excludeObo ||
        embeddedApp?.dmsTransactionData?.isSyncingOfferInfoLocked ||
        offer.status === 'VOIDED'
          ? []
          : [
              {
                key: 'undo',
                label: 'Undo',
                handler: getPackageActionHandler(
                  this,
                  uiState,
                  'UNDO',
                  true,
                  true,
                  isEmbedded
                ),
                isObo: true,
              },
            ];

      if (offer.status !== 'VOIDED' && !excludeObo) {
        actions.push({
          key: 'cancel',
          label: 'Cancel Offer',
          handler: cancelHandler,
          isObo: false,
        });
      }

      return actions;
    }

    return excludeObo ? [] : baseActions(true);
  };

  getOboActions = (context: Parameters<typeof this.getActions>[0]) => {
    const actions = this.getActions({
      ...context,
      oboOnly: true,
      excludeObo: false,
    });
    if (this.hasOffer && actions.length) {
      const offer = this.offer;
      if (offer.isInFinalState) {
        return {
          title: 'Confirm Undo',
          label: 'Undo',
          description: `Confirm you want to ${offer.undoAction.toLowerCase()} this ${this.userPackageKind.toLowerCase()}`,
          ok: offer.undoAction,
          actions,
        };
      }
      return {
        title: `What was the decision made by ${this.opposingSidesAgentName}?`,
        label: 'Manually Edit Offer Status',
        actions,
      };
    }

    return null;
  };

  getExtraActions = (context: Omit<ExtraActionContext, 'offerPackage'>) => {
    if (!this.hasOffer) {
      return [];
    }
    // Received counter status should not have extra actions
    // Received counter == offer canDecide + countered packageStatus
    if (this.offer.canDecide && this.packageStatus === 'COUNTERED') {
      return [];
    }

    return Object.values(EXTRA_ACTIONS)
      .filter(({ condition }) => condition({ offerPackage: this, ...context }))
      .map(({ action }) => action({ offerPackage: this, ...context }));
  };

  executeAction = async (
    actionType: ItemActionType,
    actionData = {},
    email = {},
    detached?: boolean,
    isObo?: boolean
  ) => {
    const api = this.store.parent.api;
    const result = await api.transactions.runTxnPckgAction(
      this.transactionId,
      this.id,
      actionType,
      actionData,
      email,
      detached,
      isObo
    );

    return result;
  };

  retrySubmittedAction = (_resend: boolean) => {
    // TODO implement resending the email (ie not re-running the whole action, and also
    // avoiding the idempotence logic on the retrying of the action).

    let actionType;
    const pendingAction = this.pendingAction;
    const submittedAction = this.submittedAction;
    if (pendingAction && this.isSubmittingFailed) {
      actionType = pendingAction.actionType;
    } else if (submittedAction) {
      actionType = submittedAction.actionType;
    }

    if (!actionType) {
      return null;
    }

    return this.executeAction(actionType);
  };

  resend = () => {
    if (this.canResend) {
      return this.retrySubmittedAction(true);
    }

    return null;
  };

  // TODO refactor with delegates
  get targetCancelRouteArgs() {
    if (this.isOffer) {
      // If package is not a draft and it's a listing, then go back to offer management page
      // instead of offer summary page (two levels up instead of one, and remove unneeded
      // offerId route param).
      const routeArgs = this.summaryRouteArgs;
      if (this.transaction?.isSale && this.isDraft) {
        return [
          routeArgs[0]!.split('.').slice(0, -1).join('.'),
          omit(routeArgs[1], ['offerId']),
        ];
      }

      return routeArgs;
    }

    return null;
  }

  createPendingRevision = async (action: ItemActionType) => {
    await this.createPendingAction(action, false, true);
    // Redirect to offer prepare page
    this.store.parent.router.navigate(...this.prepareRouteArgs);
  };

  createPendingAction = async (
    actionType: ItemActionType,
    detached: boolean,
    revision: boolean
  ) => {
    await this.store.dispatch(
      this.transaction!.id,
      createPackagePendingAction(this.id, actionType, detached, revision)
    );
  };

  cancel = async () => {
    const targetRouteArgs = this.targetCancelRouteArgs;
    await this.store.dispatch(
      this.transaction!.id,
      cancelPackagePendingAction(this.id)
    );
    if (targetRouteArgs) {
      this.store.parent.router.navigate(...targetRouteArgs);
    }
  };
}
