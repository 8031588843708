import camelCase from 'lodash/camelCase';
import { computed, makeObservable } from 'mobx';
import offersIcon from 'src/images/spot-icons/apps/spot-offers.svg';
import sellerDisclosuresIcon from 'src/images/spot-icons/apps/spot-sellerdisc.svg';
import {
  OFFERS_GUID,
  SELLER_DISCLOSURES_GUID,
} from 'src/models/transactions/apps';
import Item from '../item';
import OffersAppDelegate from './offers';
import SellerDisclosuresAppDelegate from './seller-disclosures';

const _ICONS_BY_GUID = {
  [SELLER_DISCLOSURES_GUID]: sellerDisclosuresIcon,
  [OFFERS_GUID]: offersIcon,
};

const _DELEGATE_BY_GUID = {
  [SELLER_DISCLOSURES_GUID]: SellerDisclosuresAppDelegate,
  [OFFERS_GUID]: OffersAppDelegate,
};

export default class TransactionApp extends Item {
  constructor(...args) {
    super(...args);
    makeObservable(this);
    const DelegateCls = _DELEGATE_BY_GUID[this.guid];
    if (DelegateCls) {
      this.delegate = new DelegateCls(this);
    }
  }

  get status() {
    return this.kindItem.status;
  }

  @computed
  get type() {
    return this.kindItem.type;
  }

  @computed
  get guid() {
    return this.kindItem.guid;
  }

  @computed
  get version() {
    return this.kindItem.version;
  }

  get typeItem() {
    return this.kindItem[camelCase(this.type)] || {};
  }

  @computed
  get icon() {
    return _ICONS_BY_GUID[this.guid];
  }

  getRoute = ({ transaction }) => {
    if (this.guid === SELLER_DISCLOSURES_GUID && transaction) {
      return {
        routeName: 'transactions.transaction.disclosurePackage',
        routeParams: {
          transactionId: transaction.id,
        },
      };
    }
    if (this.guid === OFFERS_GUID && transaction) {
      return {
        routeName: 'transactions.transaction.offers',
        routeParams: {
          transactionId: transaction.id,
        },
      };
    }

    return undefined;
  };
}
