import invariant from 'invariant';
import difference from 'lodash/difference';
import intersection from 'lodash/intersection';
import sortBy from 'lodash/sortBy';
import union from 'lodash/union';
import uniq from 'lodash/uniq';
import { action, computed, makeObservable, observable } from 'mobx';
import {
  invitePartiesIntent,
  partyRemove as partyRemoveIntent,
  updatePartyContactDetails,
} from 'src/models/transactions/intents';
import arraysEqual from 'src/utils/arrays-equal';
import {
  BUYER,
  BUYER2,
  BUYER3,
  BUYER4,
  BUYER_AGENT,
  BUYER_AGENT2,
  BUYER_AGENT_ROLES,
  BUYER_BROKER,
  BUYER_ROLES,
  BUYER_SIDE_ROLES,
  BUYER_SIDE_ROLES_FOR_TXMP_PARTY_MODAL,
  BUYER_TC,
  BUYER_TEAM,
  BUYER_TEAM_ROLES,
  LISTING_AGENT,
  LISTING_AGENT2,
  LISTING_AGENT_ROLES,
  LISTING_BROKER,
  LISTING_SIDE_ROLES,
  LISTING_SIDE_ROLES_FOR_TXMP_PARTY_MODAL,
  LISTING_TC,
  LISTING_TEAM,
  LISTING_TEAM_ROLES,
  NON_UNIQUE_ROLES,
  SELLER,
  SELLER2,
  SELLER3,
  SELLER4,
  SELLER_ROLES,
  THIS_SIDE_ONLY_ROLES,
  THIS_SIDE_ONLY_ROLES_FOR_TXMP_PARTY_MODAL,
} from './roles';

export function getLoweredEmailsToExcludeForDuplicates(parties, roles) {
  // we allow duplicates, but only entirely within the buyers,
  // or entirely within the sellers
  return uniq(
    parties
      .map((p) => {
        const rs = [...p.roles];
        if (
          difference(union(roles, rs), BUYER_ROLES).length &&
          difference(union(roles, rs), SELLER_ROLES).length
        ) {
          return p.email && p.email.toLowerCase().trim();
        }
        return null;
      })
      .filter(Boolean)
  );
}

function getPartiesSortedByRoles(parties, rolesOrder) {
  const maxIdx = rolesOrder.length;
  return sortBy(parties, (p) => {
    return Math.min(
      ...p.roles.map((r) => {
        const idx = rolesOrder.indexOf(r);
        return idx >= 0 ? idx : maxIdx;
      })
    );
  });
}

export default class Parties {
  @observable transactionId = null;
  @observable partiesIdList = [];

  constructor(store, transaction) {
    makeObservable(this);
    this.store = store;
    this.updateFromJson(transaction);
  }

  @computed
  get me() {
    const meUserId =
      this.store.parent.account.user && this.store.parent.account.user.id;
    if (!meUserId) {
      return null;
    }
    return this.getByUserId(meUserId);
  }

  @computed
  get meOrEmpty() {
    return this.me
      ? this.me
      : {
          id: null,
          roles: [],
        };
  }

  @computed
  get isCurrentUserInOrg() {
    return this.store.parent.account.user.orgId === this.transaction.orgId;
  }

  @computed
  get allowedInviteRoles() {
    if (!this.transaction) {
      return [];
    }

    if (this.transaction.side === 'SALE') {
      return [...LISTING_SIDE_ROLES];
    }

    if (this.transaction.side === 'PURCHASE') {
      return [...BUYER_SIDE_ROLES];
    }

    return [];
  }

  @computed
  get allowedAutoInviteRolesForTxmpPartyModal() {
    if (!this.transaction || !this.transaction.isCompassTransaction) {
      return [];
    }

    if (this.transaction.side === 'SALE') {
      return [
        LISTING_AGENT,
        LISTING_AGENT2,
        LISTING_BROKER,
        LISTING_TC,
        LISTING_TEAM,
      ];
    }

    if (this.transaction.side === 'PURCHASE') {
      return [BUYER_AGENT, BUYER_AGENT2, BUYER_BROKER, BUYER_TC, BUYER_TEAM];
    }

    return [];
  }

  @computed
  get all() {
    return this.partiesIdList.map((id) =>
      this.store.getItem(this.transactionId, 'PARTY', id)
    );
  }

  @computed
  get primaryAgentRole() {
    return this.transaction.isPurchase ? BUYER_AGENT : LISTING_AGENT;
  }

  @computed
  get primaryAgent() {
    return this.getByRole(this.primaryAgentRole);
  }

  @computed
  get otherSidePrimaryAgentRole() {
    return this.transaction.isPurchase ? LISTING_AGENT : BUYER_AGENT;
  }

  @computed
  get otherPrimaryAgent() {
    return this.getByRole(this.otherSidePrimaryAgentRole);
  }

  @computed
  get thisSideRoles() {
    if (this.transaction.isPurchase) {
      return BUYER_SIDE_ROLES;
    }
    if (this.transaction.isSale) {
      return LISTING_SIDE_ROLES;
    }

    return [];
  }

  @computed
  get thisSideRolesForTxmpPartyModal() {
    if (this.transaction.isPurchase) {
      return BUYER_SIDE_ROLES_FOR_TXMP_PARTY_MODAL;
    }
    if (this.transaction.isSale) {
      return LISTING_SIDE_ROLES_FOR_TXMP_PARTY_MODAL;
    }
    return [];
  }

  @computed
  get otherSideRoles() {
    if (this.transaction.isPurchase) {
      return difference(LISTING_SIDE_ROLES, THIS_SIDE_ONLY_ROLES);
    }
    if (this.transaction.isSale) {
      return difference(BUYER_SIDE_ROLES, THIS_SIDE_ONLY_ROLES);
    }

    return [];
  }

  @computed
  get otherSideRolesForTxmpPartyModal() {
    if (this.transaction.isPurchase) {
      return difference(
        LISTING_SIDE_ROLES_FOR_TXMP_PARTY_MODAL,
        THIS_SIDE_ONLY_ROLES_FOR_TXMP_PARTY_MODAL
      );
    }
    if (this.transaction.isSale) {
      return difference(
        BUYER_SIDE_ROLES_FOR_TXMP_PARTY_MODAL,
        THIS_SIDE_ONLY_ROLES_FOR_TXMP_PARTY_MODAL
      );
    }
    return [];
  }

  @computed
  get primaryParties() {
    // principals and agents on this side of the txn
    return this.filterByRoles(this.primaryRoles);
  }

  @computed
  get primaryRoles() {
    if (this.transaction.isPurchase) {
      return [...BUYER_ROLES, ...BUYER_AGENT_ROLES];
    }
    if (this.transaction.isSale) {
      return [...SELLER_ROLES, ...LISTING_AGENT_ROLES];
    }
    return [];
  }

  @computed
  get signRoles() {
    if (this.transaction.isPurchase) {
      return [
        ...BUYER_ROLES,
        ...BUYER_AGENT_ROLES,
        ...SELLER_ROLES,
        ...LISTING_AGENT_ROLES,
      ];
    }
    if (this.transaction.isSale) {
      return [
        ...SELLER_ROLES,
        ...LISTING_AGENT_ROLES,
        ...BUYER_ROLES,
        ...BUYER_AGENT_ROLES,
      ];
    }
    return [];
  }

  @computed
  get thisSideParties() {
    return this.filterByRoles(this.thisSideRoles);
  }

  @computed
  get thisSideClients() {
    return this.filterByRoles(this.thisSideClientRoles);
  }

  @computed
  get thisSideClientLabel() {
    if (this.transaction.isLease) {
      return this.transaction.isPurchase ? 'Tenant' : 'Landlord';
    }
    return this.transaction.isPurchase ? 'Buyer' : 'Seller';
  }

  @computed
  get thisSideAgentRoles() {
    return this.transaction.isPurchase
      ? BUYER_AGENT_ROLES
      : LISTING_AGENT_ROLES;
  }

  @computed
  get thisSideClientRoles() {
    return this.transaction.isPurchase ? BUYER_ROLES : SELLER_ROLES;
  }

  @computed
  get thisSideClient1Role() {
    return this.transaction.isPurchase ? BUYER : SELLER;
  }

  @computed
  get thisSideClient2Role() {
    return this.transaction.isPurchase ? BUYER2 : SELLER2;
  }

  @computed
  get thisSideClient3Role() {
    return this.transaction.isPurchase ? BUYER3 : SELLER3;
  }

  @computed
  get thisSideClient4Role() {
    return this.transaction.isPurchase ? BUYER4 : SELLER4;
  }

  @computed
  get thisSideAgent1Role() {
    return this.transaction.isPurchase ? BUYER_AGENT : LISTING_AGENT;
  }

  @computed
  get thisSideAgent2Role() {
    return this.transaction.isPurchase ? BUYER_AGENT2 : LISTING_AGENT2;
  }

  @computed
  get thisSideTeamRoles() {
    return this.transaction.isPurchase ? BUYER_TEAM_ROLES : LISTING_TEAM_ROLES;
  }

  @computed
  get thisSideTeamRolesForTxmpPartyModal() {
    return this.transaction.isPurchase
      ? [BUYER_AGENT, BUYER_AGENT2, BUYER_BROKER, BUYER_TEAM]
      : [LISTING_AGENT, LISTING_AGENT2, LISTING_BROKER, LISTING_TEAM];
  }

  @computed
  get thisSideTeamMemberRoleForTxmpPartyModal() {
    return this.transaction.isPurchase ? BUYER_TEAM : LISTING_TEAM;
  }

  @computed
  get thisSideBrokerRole() {
    return this.transaction.isPurchase ? BUYER_BROKER : LISTING_BROKER;
  }

  @computed
  get thisSideTcRole() {
    return this.transaction.isPurchase ? BUYER_TC : LISTING_TC;
  }

  @computed
  get thisSideNonUniqueRoles() {
    return intersection(this.thisSideTeamRoles, NON_UNIQUE_ROLES);
  }

  @computed
  get thisSideTeamParties() {
    return this.filterByRoles(this.thisSideTeamRoles);
  }

  @computed
  get thisSideTeamPartiesForTxmpPartyModal() {
    return this.filterByRoles(this.thisSideTeamRolesForTxmpPartyModal);
  }

  @computed
  get thisSideAgents() {
    return this.filterByRoles(this.thisSideAgentRoles, true);
  }

  @computed
  get thisSideTcs() {
    return this.filterByRoles([this.thisSideTcRole]);
  }

  @computed
  get otherSideParties() {
    return this.filterByRoles(this.otherSideRoles);
  }

  @computed
  get otherSideClients() {
    return this.filterByRoles(this.otherSideClientRoles);
  }

  @computed
  get otherSideAgentRoles() {
    return this.transaction.isPurchase
      ? LISTING_AGENT_ROLES
      : BUYER_AGENT_ROLES;
  }

  @computed
  get otherSideClientRoles() {
    return this.transaction.isPurchase ? SELLER_ROLES : BUYER_ROLES;
  }

  @computed
  get otherSideClient1Role() {
    return this.transaction.isPurchase ? SELLER : BUYER;
  }

  @computed
  get otherSideClient2Role() {
    return this.transaction.isPurchase ? SELLER2 : BUYER2;
  }

  @computed
  get otherSideClient3Role() {
    return this.transaction.isPurchase ? SELLER3 : BUYER3;
  }

  @computed
  get otherSideClient4Role() {
    return this.transaction.isPurchase ? SELLER4 : BUYER4;
  }

  @computed
  get otherSideAgent1Role() {
    return this.transaction.isPurchase ? LISTING_AGENT : BUYER_AGENT;
  }

  @computed
  get otherSideAgent2Role() {
    return this.transaction.isPurchase ? LISTING_AGENT2 : BUYER_AGENT2;
  }

  @computed
  get otherSideTeamRoles() {
    return this.transaction.isPurchase ? LISTING_TEAM_ROLES : BUYER_TEAM_ROLES;
  }

  @computed
  get otherSideTeamParties() {
    return this.filterByRoles(this.otherSideTeamRoles);
  }

  @computed
  get otherSideAgents() {
    return this.filterByRoles(this.otherSideAgentRoles, true);
  }

  @computed
  get bound() {
    return this.all.filter((p) => !!p.userId);
  }

  @computed
  get existingRoles() {
    return uniq(
      this.all
        .map((item) => item.roles || [])
        .reduce((allRoles, roles) => allRoles.concat(roles.slice()), [])
    );
  }

  @computed
  get existingEmails() {
    return this.all.filter((p) => !!p.email).map((p) => p.email);
  }

  @computed
  get loweredExistingEmails() {
    return this.existingEmails.map((email) => email.toLowerCase().trim());
  }

  @computed
  get hasSeller() {
    return this.hasRoles(SELLER_ROLES);
  }

  @computed
  get hasBuyer() {
    return this.hasRoles(BUYER_ROLES);
  }

  @computed
  get hasClient() {
    return this.hasRoles(this.thisSideClientRoles);
  }

  @computed
  get clientNoun() {
    if (this.transaction.isLease) {
      return this.transaction.isPurchase ? 'Tenant' : 'Landlord';
    }
    return this.transaction.isPurchase ? 'Buyer' : 'Seller';
  }

  @computed
  get otherSideClientNoun() {
    if (this.transaction.isLease) {
      return this.transaction.isPurchase ? 'Landlord' : 'Tenant';
    }
    return this.transaction.isPurchase ? 'Seller' : 'Buyer';
  }

  @computed
  get hasAgent() {
    return this.hasRoles(this.thisSideAgentRoles);
  }

  @computed
  get thisSideClient1() {
    return this.getByRole(this.thisSideClient1Role);
  }

  @computed
  get otherSideClient1() {
    return this.getByRole(this.otherSideClient1Role);
  }

  @computed
  get thisSideClient2() {
    return this.getByRole(this.thisSideClient2Role);
  }

  @computed
  get hasListingAgent() {
    return this.hasRoles(LISTING_AGENT_ROLES);
  }

  @computed
  get sellers() {
    return this.filterByRoles(SELLER_ROLES);
  }

  @computed
  get buyers() {
    return this.filterByRoles(BUYER_ROLES);
  }

  hasRoles(roles) {
    return intersection(this.existingRoles, roles).length > 0;
  }

  loweredEmailsToExcludeForDuplicates(roles) {
    return getLoweredEmailsToExcludeForDuplicates(this.all, roles);
  }

  findByRole(role) {
    return this.all.filter((p) => p.roles.includes(role));
  }

  getByRole(role) {
    return this.findByRole(role).find((p) => p) || null;
  }

  getByUserId(userId) {
    return this.all.filter((p) => p.userId === userId)[0] || null;
  }

  getById(partyId, includeUnbound = false) {
    if (!includeUnbound && !this.partiesIdList.includes(partyId)) {
      return null;
    }
    return this.store.getItem(this.transactionId, 'PARTY', partyId);
  }

  firstByEmail = (email) => {
    const targetEmail = email.toLowerCase().trim();
    return this.all.find(
      (p) => p.email && p.email.toLowerCase() === targetEmail
    );
  };

  filterByRoles(roles, sortByRoles = false) {
    const res =
      this.all.filter((p) => intersection(roles, p.roles).length > 0) || [];

    return sortByRoles ? getPartiesSortedByRoles(res, roles) : res;
  }

  @action
  updateFromJson(transaction) {
    this.transaction = transaction;
    this.transactionId = transaction.id;
    const partiesIds = transaction.data.meta?.parties
      ? transaction.data.meta.parties.map((p) => p.id)
      : transaction.data.meta?.partiesIds || [];

    if (!arraysEqual(this.partiesIdList, partiesIds)) {
      this.partiesIdList.replace(partiesIds);
    }

    if (transaction.data.meta?.parties) {
      const partiesJsonById = {};
      transaction.data.meta.parties.forEach((p) => {
        partiesJsonById[p.id] = p;
      });
      this.store.updateItemsById(partiesJsonById);
    }
  }

  @action
  ensurePartyId(partyId) {
    if (!this.partiesIdList.includes(partyId)) {
      this.partiesIdList.push(partyId);
    }
  }

  addSelfAsParty = async () => {
    const { router, transactions } = this.store.parent;

    try {
      await transactions.dispatch(this.transaction.id, invitePartiesIntent());
      router.reload();
    } catch (unusedErr) {
      // pass
    }
  };

  removeParty = async (
    party,
    navigateAwayIfRemovingMyself = true,
    fromOppositePartyPane = false
  ) => {
    const { transactions, ui, router } = this.store.parent;
    const isMe = party.isMe;
    const successMessage = !isMe
      ? `Successfully removed ${party.fullName} from the transaction.`
      : 'Successfully left the transaction.';

    const hasOwnSideRoles =
      intersection(party.roles, this.thisSideRoles).length > 0;
    // If fromOppositePartyPane and has own side roles
    // Remove will keep the party but remove all opposite side roles
    // And keep all own side roles
    if (fromOppositePartyPane && hasOwnSideRoles) {
      try {
        await transactions.dispatch(
          this.transaction.id,
          updatePartyContactDetails({
            contact: party.contact,
            id: party.id,
            roles: intersection(this.thisSideRoles, party.roles),
          })
        );
        ui.toast({
          message: `Successfully removed ${
            this.transaction.isSale ? 'purchase' : 'listing'
          } side roles.`,
          type: 'success',
        });
      } catch (err) {
        ui.wentWrong(err);
        throw err;
      }
      return;
    }

    try {
      await transactions.dispatch(
        party.transactionId,
        partyRemoveIntent(party)
      );
      ui.toast({
        message: successMessage,
        type: 'success',
      });
      if (
        isMe &&
        navigateAwayIfRemovingMyself &&
        !party.transaction.isTemplate
      ) {
        await router.navigatePromise('home');
      }
    } catch (err) {
      ui.wentWrong(err);
      throw err;
    }
  };

  confirmAndRemoveParty = async (
    party,
    navigateAwayIfRemovingMyself = true,
    customPrompt = null,
    fromOppositePartyPane = false
  ) => {
    const { ui } = this.store.parent;
    invariant(
      party.transactionId === this.transactionId,
      'Party must be in transaction.'
    );
    if (!party.isBound) {
      await this.removeParty(party, true, fromOppositePartyPane);
      return true;
    }

    const isPrimaryAgent = this.primaryAgent?.id === party.id;
    const primaryAgentMessage =
      ' If you intend to download or e-sign forms,' +
      " you'll need to invite a primary agent to this transaction.";
    const isMe = party.isMe;
    let confirm;
    if (isMe) {
      const teamIds = this.store.parent.account.teams.map((t) => t.id);
      const transTeamIds = party.transaction.teamIds;
      const mayStillHaveAccess = intersection(teamIds, transTeamIds).length > 0;
      confirm = await ui.typeToConfirmAsync({
        title: `Are you sure you want to leave the transaction? You ${
          mayStillHaveAccess ? 'may' : 'will'
        } no longer have access to this transaction if you do this.${
          isPrimaryAgent ? primaryAgentMessage : ''
        }`,
        confirmString: 'LEAVE',
      });
    } else {
      const title =
        customPrompt ??
        `Are you sure you want to remove ${
          party.fullName
        } from the transaction?${isPrimaryAgent ? primaryAgentMessage : ''}`;
      confirm = await ui.confirmAsync({
        title,
      });
    }

    if (confirm) {
      await this.removeParty(party, navigateAwayIfRemovingMyself);
    }
    return confirm;
  };
}
