import NProgress from 'nprogress';
import invariant from 'invariant';
import navigateToFlow from 'src/components/flows/navigate-to-flow';
import { action, makeObservable } from 'mobx';
import {
  archiveSignatureRequest,
  createSignatureRequestFlow,
  duplicateSignatureRequest,
  refreshSignatureRequest,
  resendSignatureRequest,
  signatureRequestCancelRevision,
  signatureRequestStartRevision,
  voidSignatureRequest,
} from 'src/models/transactions/intents';

export default class SignaturePacketsStore {
  constructor(parent) {
    makeObservable(this);
    this.parent = parent;
  }

  get transactions() {
    return this.parent.transactions;
  }

  refreshSignatureRequest = async ({ transactionId, signatureRequestId }) => {
    const { result } = await this.transactions.dispatch(
      transactionId,
      refreshSignatureRequest(signatureRequestId)
    );
    return result;
  };

  voidSignatureRequest = async ({
    transactionId,
    signatureRequestId,
    voidReason,
  }) => {
    const { result } = await this.transactions.dispatch(
      transactionId,
      voidSignatureRequest(signatureRequestId, voidReason)
    );
    return result;
  };

  duplicateSignatureRequest = async ({ transactionId, signatureRequestId }) => {
    const { result } = await this.transactions.dispatch(
      transactionId,
      duplicateSignatureRequest(signatureRequestId)
    );
    invariant(result.flow_id, 'Must have flow id in dispatch result.');
    return result.flow_id;
  };

  reviseSignatureRequest = async ({ transactionId, signatureRequestId }) => {
    const { result } = await this.transactions.dispatch(
      transactionId,
      signatureRequestStartRevision(signatureRequestId)
    );
    invariant(result.flow_id, 'Must have flow id in dispatch result.');
    return result.flow_id;
  };

  cancelSignatureRequestRevision = async ({
    transactionId,
    signatureRequestId,
  }) => {
    await this.transactions.dispatch(
      transactionId,
      signatureRequestCancelRevision(signatureRequestId)
    );
  };

  resendSignatureRequest = async ({ transactionId, signatureRequestId }) => {
    const { result } = await this.transactions.dispatch(
      transactionId,
      resendSignatureRequest(signatureRequestId)
    );
    return result;
  };

  archiveSignatureRequest = async ({ transactionId, signatureRequestId }) => {
    const { result } = await this.transactions.dispatch(
      transactionId,
      archiveSignatureRequest([
        {
          signatureRequestId,
          archived: true,
        },
      ])
    );
    return result;
  };

  @action.bound
  async launchWizard(router, transactionId, options) {
    NProgress.start();
    try {
      if (
        options.transactionDocumentsIds &&
        options.transactionDocumentsIds.length
      ) {
        await this.transactions.getOrFetchItemMulti(
          transactionId,
          'TRANSACTION_DOCUMENT',
          options.transactionDocumentsIds
        );
      }

      if (options.tdvIds && options.tdvIds.length) {
        const tdvs = await this.transactions.getOrFetchItemMulti(
          transactionId,
          'TRANSACTION_DOCUMENT_VERSION',
          options.tdvIds
        );
        await this.transactions.getOrFetchItemMulti(
          transactionId,
          'TRANSACTION_DOCUMENT',
          tdvs.map((tdv) => tdv.tdId)
        );
      }

      const { result } = await this.transactions.dispatch(
        transactionId,
        createSignatureRequestFlow({
          callbackPath: location.pathname,
          ...options,
        })
      );
      invariant(result.flow_id, 'Must have flow id in dispatch result.');
      return navigateToFlow(router, result.flow_id);
    } catch (err) {
      NProgress.done();
      this.parent.ui.wentWrong(err);
      throw err;
    }
  }
}
