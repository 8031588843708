import moment from 'moment';
import { computed, makeObservable, observable, runInAction } from 'mobx';
import { makeItem } from 'src/models/transactions/items';
import {
  updateKeyDateCompletionStatus,
  updateTimelineDate,
} from 'src/models/transactions/intents';
import type { Transaction, TransactionStore } from './transaction';
import type KeyDateItem from './items/key-date';
import type { KeyDate as KeyDateJson } from 'src/types/proto/dates';

export const HOLIDAYS = [
  '1577865600000',
  '1579507200000',
  '1581926400000',
  '1590390000000',
  '1593759600000',
  '1599462000000',
  '1602486000000',
  '1605081600000',
  '1606377600000',
  '1608883200000',
  '1609488000000',
  '1610956800000',
  '1613376000000',
  '1622444400000',
  '1625468400000',
  '1630911600000',
  '1633935600000',
  '1636617600000',
  '1637827200000',
  '1640332800000',
  '1640937600000',
  '1642406400000',
  '1645430400000',
  '1653894000000',
  '1656918000000',
  '1662361200000',
  '1665385200000',
  '1668153600000',
  '1669276800000',
  '1672041600000',
];

export default class Dates {
  @observable dates: KeyDateItem[] = [];

  store: TransactionStore;
  transaction: Transaction;

  constructor(store: TransactionStore, transaction: Transaction) {
    makeObservable(this);
    this.store = store;
    this.transaction = transaction;
  }

  ensureAll = async () => {
    const dates = (await this.store.getFetchItems.getOrFetch({
      transactionId: this.transaction.id,
      kind: 'KEY_DATE',
    })) as KeyDateItem[];
    runInAction(() => {
      this.dates = dates;
    });
    return dates;
  };

  @computed
  get sortedDates() {
    // eslint-disable-next-line consistent-return
    return this.dates.slice().sort((a, b) => {
      if (a.computedDate && b.computedDate) {
        return moment(a.computedDate, 'x').isSameOrBefore(
          moment(b.computedDate, 'x')
        )
          ? -1
          : 1;
      }
      if (a.computedDate) {
        return -1;
      }
      if (b.computedDate) {
        return 1;
      }
      return 0;
    });
  }

  @computed
  get activeDates() {
    return this.sortedDates.filter((date) => date.isActive);
  }

  @computed
  get completedDates() {
    return this.activeDates
      .filter((date) => date.isComplete)
      .sort((a, b) => b.completedAt - a.completedAt);
  }

  @computed
  get incompletedDates() {
    return this.activeDates.filter((date) => !date.isComplete);
  }

  @computed
  get escrowDate() {
    return this.activeDates.find((date) => date.isEscrow);
  }

  @computed
  get acceptanceDate() {
    return this.activeDates.find((date) => date.isAcceptance);
  }

  syncDates = async () => {
    const dateKinds = this.dates.map((d) => d.kindItem);
    try {
      await this.store.dispatch(
        this.transaction.id,
        updateTimelineDate(dateKinds)
      );
    } catch (e) {
      this.store.parent.ui.wentWrong(e);
    }
  };

  saveDate = (update: KeyDateJson) => {
    const date = this.dates.find((d) => d.uuid === update.uuid);
    let otherDates = this.dates.filter((d) => d.uuid !== update.uuid);

    const data = date
      ? date.data
      : {
          kind: 'KEY_DATE',
        };

    const dateItem = makeItem(this.store, {
      ...data,
      keyDate: update,
    });
    if (date && date.isAcceptance) {
      otherDates = otherDates.map((d) => {
        return d;
      });
    }
    runInAction(() => {
      this.dates = [dateItem, ...otherDates];
    });
    this.syncDates();
  };

  removeDate = (date: KeyDateItem, onRemove?: () => void) => {
    this.store.parent.ui.confirm({
      title: `Are you sure you want to delete ${date.title}?`,
      okText: 'Yes',
      onOk: () => {
        if (onRemove) {
          onRemove();
        }
        this.handleRemoveDate(date);
      },
      onCancel: () => {},
    });
  };

  handleRemoveDate = (date: KeyDateItem) => {
    let updatedDates = this.dates.filter((d) => d.uuid !== date.uuid);
    const key = date.isRelative ? 'relative' : 'static';

    if (date.type !== 'CUSTOM') {
      const dateItem = makeItem(this.store, {
        ...date.data,
        keyDate: {
          ...date.kindItem,
          computedDate: null,
          [key]: {},
        },
      });
      updatedDates = updatedDates.concat(dateItem);
    }

    runInAction(() => {
      this.dates = updatedDates;
    });
    this.syncDates();
  };

  updateCompletionStatus = async (uuid: string, isComplete: boolean) => {
    const updatedDate = this.dates.find((d) => d.uuid === uuid);

    const dateItem = makeItem(this.store, {
      ...updatedDate?.data,
      keyDate: {
        ...updatedDate?.kindItem,
        isComplete,
        completedAt: moment().valueOf(),
      },
    });

    runInAction(() => {
      this.dates = [dateItem, ...this.dates.filter((d) => d.uuid !== uuid)];
    });
    try {
      await this.store.dispatch(
        this.transaction.id,
        updateKeyDateCompletionStatus({
          uuid,
          isComplete,
        })
      );
    } catch (e) {
      this.store.parent.ui.wentWrong(e);
    }
  };
}
