import { computed, makeObservable } from 'mobx';
import Item, { ItemJson, ItemStore } from './item';

export type TransactionFieldsJson = ItemJson<'TRANSACTION_FIELDS'>;

export default class TransactionFields extends Item<
  ItemStore,
  TransactionFieldsJson
> {
  constructor(store: ItemStore, json: TransactionFieldsJson) {
    super(store, json);

    makeObservable(this);
  }

  @computed
  get namespace() {
    return this.kindItem!.namespace || '';
  }
  getFieldIsUnlinked() {
    return false;
  }
}
