import {
  ObservableMap,
  action,
  computed,
  makeObservable,
  observable,
} from 'mobx';
import type { Router, Route as Route5 } from 'router5';
import publicApi from 'src/api';
import type { OverloadedArguments } from 'src/types/utils';
import getEmbeddedRedirectUrl from 'src/utils/get-embedded-redirect-url';
import isSafeUrl from 'src/utils/is-safe-url';
import {
  receiveMessage as receiveMobileBridgeMessage,
  sendMessage as sendMobileBridgeMessage,
} from 'src/utils/mobile-bridge';
import promisify from 'src/utils/promisify';
import querySerialize from 'src/utils/query-serialize';
import appRoutes, { iosAllowedRoutes } from '../entries/app/routes';
import type { AppStore } from './app-store';
import type EmbeddedAppStore from './embedded-app-store';
import type UiStore from './ui-store';

type Route = Route5<Record<string, any>> & {
  titleFunction?: string;
  params?: Record<string, any>;
  component?: () => Promise<any>;
  redirect?: () => Promise<any>;
};

const loginPath = '/auth/login';

export default class RouterStore {
  // Danger don't use this in your render() methods because it will
  // trigger rerenders even if it no longer matches what the component
  // is expecting. Use .route instead.
  @observable hotRoute: Route | null = null;
  @observable modelsById: ObservableMap<string, any> = new ObservableMap();
  @observable navigateCallbacks: Record<
    string,
    (_route: Route, _fromRoute: Route) => void
  > = {};
  @observable externalWindows: Record<string, Window> = {};

  fromRoute: Route | null = null;
  route: Route | null = null;
  blockMessage: string | null = null;
  interceptRedirectRoute: Route | null = null;

  parent?: AppStore;
  routes?: Route[];
  router?: Router;

  constructor(parent: AppStore, routes: Route[]) {
    makeObservable(this);

    this.parent = parent;
    this.fromRoute = null;
    this.route = null;
    this.routes = routes;
    this.navigateCallbacks = {};
    // @ts-ignore
    window.appNavigate = this.navigate; // DEPRECATE?

    sendMobileBridgeMessage({
      data: {
        event: 'allowedRoutes',
        routes: iosAllowedRoutes,
      },
      dispose: false,
    });

    receiveMobileBridgeMessage({
      name: 'navigate',
      cb: (...args: Parameters<Router['navigate']>) => {
        this.navigate(...args);
      },
      permanent: true,
    });
  }

  get ui(): UiStore {
    return this.parent.ui;
  }

  get api(): typeof publicApi {
    return this.parent.api;
  }

  get embeddedApp(): EmbeddedAppStore {
    return this.parent.embeddedApp;
  }

  @computed
  get hasRoute(): boolean {
    return !!this.hotRoute;
  }

  getRoutesDetails(): Record<string, { route?: Route }> {
    const indexRoutes: Record<string, { route?: Route }> = {};
    const generateIndex = (route: Route, parentName?: string) => {
      const name = parentName ? `${parentName}.${route.name}` : route.name;
      indexRoutes[name] = {};

      if (route.titleFunction) {
        indexRoutes[name] = {
          route,
        };
      }
      if (route.children) {
        route.children.forEach((currentRoute) => {
          generateIndex(currentRoute, name);
        });
      }
    };
    this.routes.forEach((currentRoute) => {
      generateIndex(currentRoute);
    });

    return indexRoutes;
  }

  @action
  subscribeToNavigate = (
    callback: (_route: Route, _fromRoute: Route) => void,
    cbAfterSubscription?: (_nextId: string) => void
  ) => {
    let nextId: string;
    while (true) {
      nextId = String(Math.floor(Math.random() * 10000) + 1);
      if (!this.navigateCallbacks[nextId]) {
        this.navigateCallbacks[nextId] = callback;
        if (cbAfterSubscription) {
          cbAfterSubscription(nextId);
        }
        return nextId;
      }
    }
  };

  @action
  unsubscribeFromNavigate = (subscriberId: string) => {
    delete this.navigateCallbacks[subscriberId];
  };

  setInterceptRedirectRoute = (route: Route) => {
    this.interceptRedirectRoute = route;
  };

  clearInterceptRedirectRoute = () => {
    this.setInterceptRedirectRoute(null);
  };

  triggerNavigateCallbacks = (route: Route, fromRoute: Route) => {
    Object.values(this.navigateCallbacks).forEach((cb) => {
      try {
        cb(route, fromRoute);
      } catch (unusedErr) {
        // ignoring error about no callback function for entry
      }
    });
  };

  @action
  updateRoute(route: Route, fromRoute: Route) {
    this.triggerNavigateCallbacks(route, fromRoute);
    this.parent.forceStopSpinning();
    this.route = route;
    this.fromRoute = fromRoute;
    this.hotRoute = route;

    // @ts-ignore
    this.ui.didUpdateRoute(route, fromRoute);
    if (this.parent.didUpdateRoute) {
      // @ts-ignore
      this.parent.didUpdateRoute(route, fromRoute);
    }
  }

  @computed
  get firstLevelAppRoutes() {
    return appRoutes.map((r) => r.path.split('/')[1]);
  }

  @action
  updateModelsById(modelsById: ObservableMap<string, any>) {
    this.modelsById.merge(modelsById);
  }

  getRoute() {
    return this.route;
  }

  get simpleRoute() {
    const { name, params } = this.route;
    return {
      name,
      params,
    };
  }

  isActive(...args: Parameters<Router['isActive']>) {
    // access observable to track
    this.hotRoute; // eslint-disable-line no-unused-expressions
    return this.router.isActive(...args);
  }

  redirectToLogin(query?: { [key: string]: any }) {
    if (query) {
      window.location.assign(`${loginPath}?${querySerialize(query)}`);
    } else {
      window.location.assign(loginPath);
    }
  }

  redirectAfterLogin(next: string) {
    const myNext = next && isSafeUrl(next) ? next : '/';
    window.location.assign(myNext);
  }

  sendNavigateToBridge = (...args) => {
    // args[2] will have replace param if provided
    const replace = !!args[2]?.replace;

    const url = new URL(
      this.buildFullUrl(...(args as Parameters<Router['buildUrl']>))
    );
    const { pathname, search, hash } = url;

    this.embeddedApp.sendHistory(pathname, search, hash, replace);
  };

  openExternalUrl = (
    urlString: string,
    callbackPath: string,
    options: { authenticateEmbedded?: boolean; openInBrowser?: boolean } = {}
  ) => {
    const isInternal =
      urlString.indexOf('http://') === -1 &&
      urlString.indexOf('https://') === -1;
    const leadingRoutePath = urlString.startsWith('/')
      ? urlString.split('/')[1]
      : urlString.split('/')[0];
    const isWithinAppRoot = this.firstLevelAppRoutes.includes(leadingRoutePath);
    if (isInternal && isWithinAppRoot) {
      // it's not external
      if (this.matchPath(urlString)) {
        this.navigatePath(urlString);
        return;
      }
    }
    // if embedded, send CAB event to open as new tab
    if (this.ui.isEmbedded) {
      let redirectUrl = urlString;
      if (options.authenticateEmbedded) {
        redirectUrl = getEmbeddedRedirectUrl(urlString);
      }
      this.embeddedApp.navigateToUrl(redirectUrl, 'blank');
      return;
    }
    if (!this.ui.isGlideMobileApp || isWithinAppRoot) {
      window.location.href = urlString;
      return;
    }
    sendMobileBridgeMessage({
      data: {
        event: 'openExternalURL',
        url: urlString,
        callbackPath,
        openInBrowser: Boolean(options.openInBrowser),
      },
    });
  };

  navigate = (...args: OverloadedArguments<Router['navigate']>) => {
    // eslint-disable-next-line no-restricted-globals
    if (!this.blockMessage || confirm(`Navigate away? ${this.blockMessage}`)) {
      if (window.disposeBlockBack) {
        window.disposeBlockBack();
      }
      if (
        window.Glide.LATEST_RELEASE !== window.Glide.RELEASE &&
        !this.ui.isEmbedded
      ) {
        const url = this.buildFullUrl(...args);
        window.location.href = url;
        return true;
      }
      if (this.ui.isEmbedded) {
        this.sendNavigateToBridge(...args);
      }
      this.router?.navigate(...args);
      return true;
    }
    return false;
  };

  navigatePromise = (...args) => {
    // eslint-disable-next-line no-restricted-globals
    if (!this.blockMessage || confirm(`Navigate away? ${this.blockMessage}`)) {
      if (window.disposeBlockBack) {
        window.disposeBlockBack();
      }

      return new Promise((resolve) =>
        // Make sure args length is of 3 so the callback is the default callback is the
        // 4th argument, as router5 API requires
        this.navigate(
          // @ts-ignore
          ...args.concat(Array(Math.max(3 - args.length, 0)).fill(undefined)),
          resolve.bind(this, true)
        )
      );
    }
    return Promise.resolve(false);
  };

  openGlideExternal = async (urlString: string) => {
    if (this.ui.isEmbedded) {
      const {
        data: { token: loginToken },
      } = await this.api.auth.getLoginToken();
      const loginUrl = `${
        window.location.origin
      }/auth/login?token=${loginToken}&next=${encodeURIComponent(urlString)}`;
      this.openExternalUrl(loginUrl, '');
    }
  };

  @action
  registerExternalWindow = (tab: Window, tabId: string) => {
    this.externalWindows[tabId] = tab;
  };

  @action
  unregisterExternalWindow = (tabId: string) => {
    if (!tabId) {
      return;
    }
    delete this.externalWindows[tabId];
  };

  getExternalWindow = (tabId: string) => {
    return this.externalWindows[tabId];
  };

  setState(...args: Parameters<Router['setState']>) {
    return this.router.setState(...args);
  }

  makeState(...args: Parameters<Router['navigate']>) {
    return this.router.makeState(...args);
  }

  matchPath(...args: Parameters<Router['matchPath']>) {
    return this.router.matchPath(...args);
  }

  matchPathOrJson(...args: Parameters<Router['matchPath']>) {
    const routeFromPath = this.router.matchPath(...args);
    if (routeFromPath) {
      return routeFromPath;
    }

    try {
      return JSON.parse(args[0]);
    } catch (_err) {
      return null;
    }
  }

  navigatePath(...args: [string, string?]) {
    const route = this.matchPath(...args);
    this.navigate(
      route.name,
      route.params as (_err?: any, _state?: any) => void
    );
  }

  buildPath(name: string, ...args) {
    if (name.startsWith('/')) {
      return name;
    }
    return this.router.buildPath(name, ...args);
  }

  @action
  replaceHistoryState = (name: string, params) => {
    if (this.ui.isEmbedded) {
      this.sendNavigateToBridge(name, params, {
        replace: true,
      });
    }
    const res = this.router.replaceHistoryState(name, params);
    const newRoute = {
      ...this.route,
      name,
      params,
    };
    this.route = newRoute;
    this.hotRoute = newRoute;
    return res;
  };

  blockNavigate(blockMessage: string | null) {
    this.blockMessage = blockMessage;
  }

  unblockNavigate() {
    this.blockMessage = null;
    window.onbeforeunload = null;
  }

  buildUrl(routeName: string, routeParams) {
    return this.router.buildUrl
      ? this.router.buildUrl(routeName, routeParams)
      : this.router.buildPath(routeName, routeParams);
  }
  buildFullUrl(...args: Parameters<Router['buildUrl']>) {
    return `${window.location.origin}${this.router.buildUrl(...args)}`;
  }

  reload() {
    return promisify(this.router.navigate)(this.route.name, this.route.params, {
      reload: true,
    });
  }
}
