import { computed, makeObservable } from 'mobx';
import { type ItemItemLabel } from 'src/types/proto/transactions';
import Item, { type ItemStore as ItemLabelStore, type ItemJson } from './item';

export interface ItemLabelJson extends ItemJson<'ITEM_LABEL'> {
  itemLabel: ItemItemLabel;
}

export default class ItemLabel extends Item<ItemLabelStore, ItemLabelJson> {
  constructor(store: ItemLabelStore, json: ItemLabelJson) {
    super(store, json);
    makeObservable(this);
  }

  @computed
  get color() {
    return this.kindItem.color;
  }

  set color(value) {
    this.kindItem.color = value;
  }

  @computed
  get label() {
    return this.kindItem.label;
  }

  set label(value) {
    this.kindItem.label = value;
  }
}
