import type { SendIntercomLeadRequest } from 'src/types/proto/services/growth_public_service';
import BaseApi from '../base-api';

export default class Growth extends BaseApi {
  sendIntercomLead(data: SendIntercomLeadRequest) {
    return this.post<void>('/growth/send_intercom_lead', data);
  }

  sendOffersUpsellEmail() {
    return this.post<void>('/growth/send_offers_upsell_email');
  }
}
