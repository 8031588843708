import React, { Component } from 'react';
import get from 'lodash/get';
import { computed, makeObservable } from 'mobx';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import navigateClick from 'src/utils/navigate-click';
import TaskAction from './action';

export const viewTask = async ({ event, router, task, ...props }) => {
  return navigateClick(event, router, 'transactions.transaction.items.item', {
    transactionId: task.transactionId,
    taskId: task.id,
    ...props,
  });
};

@inject('router')
@observer
export default class ViewTask extends Component {
  static propTypes = {
    task: PropTypes.object,
    transactions: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  @computed
  get taskTypeProps() {
    return get(this.props.task, 'statusMeta.viewProps') || {};
  }

  handleClick = (event) => {
    const { router, task } = this.props;
    return viewTask({
      event,
      router,
      task,
      back: this.taskTypeProps.back
        ? JSON.stringify(router.simpleRoute)
        : undefined,
    });
  };

  render() {
    const { task } = this.props;
    const defaultLabel = task.isOpen ? 'Start' : 'Open';
    return (
      <TaskAction {...this.props} onClick={this.handleClick}>
        {this.taskTypeProps.label || defaultLabel}
      </TaskAction>
    );
  }
}
