import React from 'react';
import { Input } from 'antd';
import { observer } from 'mobx-react';
import AppIcon from './app-icon';
import { ModalQuickForm, QuickForm } from 'src/components/forms/quick-form';

const clsPrefix = 'app-type-to-confirm-modal';

export default observer((props) => {
  const { title, onClose, onOk, confirmString, help, hideClue } = props;
  return (
    <ModalQuickForm
      modalProps={{
        visible: true,
        title: (
          <div className="padded-right-sm">
            <AppIcon
              className={`${clsPrefix}__icon warning-text margin-right-sm`}
              type="antd"
              name="exclamation-circle"
            />
            {title}
          </div>
        ),
      }}
      initialValue={{
        confirm: '',
      }}
      onCancel={onClose}
      onSave={async () => {
        await onOk();
        onClose();
      }}
      validation={{
        confirm: (v) => {
          return v === confirmString ? undefined : true;
        },
      }}
    >
      {({ errors }) => (
        <React.Fragment>
          {help ? <div>{help}</div> : null}
          <QuickForm.Item
            name="confirm"
            validateStatus={errors.confirm ? 'error' : null}
            label={`Please type ${
              !hideClue ? confirmString : 'the required code'
            } to confirm this action.`}
            validateTriggers={['onBlur']}
          >
            <Input placeholder={!hideClue ? confirmString : ''} />
          </QuickForm.Item>
        </React.Fragment>
      )}
    </ModalQuickForm>
  );
});
