import Avid from './avid';
import BuyerProspectProperty from './buyer-prospect-property';
import Chore from './chore';
import CoverPhoto from './cover-photo';
import CoverSheet from './cover-sheet';
import Dpp from './dpp';
import FileReview from './file-review';
import Folder from './folder';
import GuidedFormPacket from './guided-form-packet';
import GuidedFormPacketFlow from './guided-form-packet-flow';
import Invoice from './invoice';
import Item, { ItemStore, ItemJson } from './item';
import ItemLabel from './item-label';
import KeyDate from './key-date';
import ListingInfo from './listing-info';
import LoadSummary from './load-summary';
import Nhd from './nhd';
import Offer from './offer';
import Packet from './packet';
import Party from './party';
import Pead from './pead';
import PropertyInfo from './property-info';
import PurchaseInfo from './purchase-info';
import SharePacket from './share-packet';
import SharePacketPublication from './share-packet-publication';
import SignatureRequest from './signature-request';
import SyncRecord from './sync-record';
import Task from './task';
import TaskReminder from './task-reminder';
import TemplateSetting from './template-setting';
import Timeline from './timeline';
import TimelineDate from './timeline-date';
import TransactionDocument from './transaction-document';
import TransactionApp from './transaction-app';
import TransactionDocumentVersion from './transaction-document-version';
import TransactionFields from './transaction-fields';
import TransactionPackage from './transaction-package';

const ITEM_MODEL_BY_KIND = {
  CHORE: Chore,
  COVER_PHOTO: CoverPhoto,
  FOLDER: Folder,
  GUIDED_FORM_PACKET: GuidedFormPacket,
  GUIDED_FORM_PACKET_FLOW: GuidedFormPacketFlow,
  INVOICE: Invoice,
  ITEM_LABEL: ItemLabel,
  LISTING_INFO: ListingInfo,
  LOAD_SUMMARY: LoadSummary,
  PACKET: Packet,
  PARTY: Party,
  PEAD: Pead,
  PROPERTY_INFO: PropertyInfo,
  PURCHASE_INFO: PurchaseInfo,
  SIGNATURE_REQUEST: SignatureRequest,
  SHARE_PACKET: SharePacket,
  SHARE_PACKET_PUBLICATION: SharePacketPublication,
  TASK: Task,
  TEMPLATE_SETTING: TemplateSetting,
  TIMELINE: Timeline,
  TIMELINE_DATE: TimelineDate,
  TRANSACTION_APP: TransactionApp,
  TRANSACTION_DOCUMENT: TransactionDocument,
  TRANSACTION_DOCUMENT_VERSION: TransactionDocumentVersion,
  TRANSACTION_FIELDS: TransactionFields,
  SYNC_RECORD: SyncRecord,
  TASK_REMINDER: TaskReminder,
  COVER_SHEET: CoverSheet,
  NHD: Nhd,
  DPP: Dpp,
  AVID: Avid,
  FILE_REVIEW: FileReview,
  OFFER: Offer,
  KEY_DATE: KeyDate,
  BUYER_PROSPECT_PROPERTY: BuyerProspectProperty,
  TRANSACTION_PACKAGE: TransactionPackage,
};

export type ItemModelByKindKeys = keyof typeof ITEM_MODEL_BY_KIND;
type ItemModels = InstanceType<typeof ITEM_MODEL_BY_KIND[ItemModelByKindKeys]>;

export function makeItem<T extends ItemModelByKindKeys>(
  store: ItemStore,
  json: ItemJson<T>
): ItemModels | Item<ItemStore, ItemJson<T>> {
  const ItemModel = ITEM_MODEL_BY_KIND[json.kind] || Item;
  return new ItemModel(store, json);
}
