import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import type {
  FormInitiation,
  FormInitiationList,
} from 'src/types/proto/growth';
import type {
  GetFormInitiationPageListRequest,
  GetFormInitiationPageQuery,
  GetFormInitiationPageResponse,
} from 'src/types/proto/services/form_initiation_page_admin_service';
import type { ApiResponseData, DeepPartial } from 'src/types/utils';
import FormInitiationPages from '../public/form-initiation-pages';

export default class AdminFormInitiationPages extends FormInitiationPages {
  createPage(request: DeepPartial<FormInitiation>) {
    return this.post<FormInitiation>('/form_initiation_pages/create', request);
  }

  listPages({ cursor, limit }: GetFormInitiationPageListRequest) {
    const params = omitBy(
      {
        cursor,
        limit,
      },
      isNil
    );

    return this.get<FormInitiationList>('/form_initiation_pages', {
      params,
    });
  }

  savePage(request: FormInitiation) {
    return this.post<FormInitiation>('/form_initiation_pages/edit', request);
  }

  getPage(id: GetFormInitiationPageQuery['id']) {
    return this.get<ApiResponseData<GetFormInitiationPageResponse>>(
      `/form_initiation_pages/${encodeURIComponent(id)}`
    );
  }
}
