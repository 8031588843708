import { DEFAULT_SECTIONS } from '../sections/kinds';
import { DEFAULT_VITALS } from '../vitals/kinds';
import { VIEW_TASK } from '../actions/kinds';
import { viewTask } from '../actions/view-task';

export default {
  actions: [VIEW_TASK],
  sections: [...DEFAULT_SECTIONS],
  vitals: [...DEFAULT_VITALS],
  spotIcon: () =>
    import('src/images/spot-icons/workflows/spot-commission-advance.svg'),
  onClick: viewTask,
  beta: true,
  trackCard: true,
  getStatusMeta: () => {
    return {
      message: 'Offering the lowest rates on an advance up to $5,000',
    };
  },
};
