import Item from './item';
import { computed, makeObservable } from 'mobx';

export default class TemplateSetting extends Item {
  constructor(store, json) {
    super(store, json);

    makeObservable(this);
  }

  @computed
  get isActive() {
    return this.kindItem.status === 'ACTIVE';
  }
}
