import * as LDClient from 'launchdarkly-js-client-sdk';

declare global {
  interface Window {
    // eslint-disable-line no-shadow
    ldClient: LDClient.LDClient;
    getLDClient(): Promise<LDClient.LDClient>;
  }
}

let initializingPromise: null | Promise<LDClient.LDClient> = null;

export const getLDClient = async (): Promise<LDClient.LDClient> => {
  let context: LDClient.LDContext;
  if (window.Glide && window.Glide.user) {
    context = {
      kind: 'user',
      key: window.Glide.user.id,
      email: window.Glide.user.contact.email,
      is_admin: window.Glide.user.isAdmin,
      access_modes: window.Glide.user.accessModes,
      disable_beta_features:
        window.Glide.user.config &&
        window.Glide.user.config.disableBetaFeatures,
      created_at: window.Glide.user.createdAt,
    };
  } else {
    context = {
      kind: 'user',
      key: 'public',
    };
  }
  if (window.ldClient) {
    return window.ldClient;
  }

  if (initializingPromise) {
    return initializingPromise;
  }

  if (window.Glide.env && !['demo', 'local'].includes(window.Glide.env)) {
    const useBootstrappedFlags = Boolean(
      !window.Glide.isEmbedded && window.Glide.LAUNCHDARKLY_BOOTSTRAP_FLAGS
    );
    const { LAUNCHDARKLY_BOOTSTRAP_FLAGS } = window.Glide;
    const disableLdSdk = Boolean(
      LAUNCHDARKLY_BOOTSTRAP_FLAGS?.['flags.disable_ld_client_sdk']
    );
    if (disableLdSdk) {
      initializingPromise = new Promise((r) => {
        const _allFlags = {
          ...window.Glide.CONSTANTS.DEFAULT_FEATURES,
          ...LAUNCHDARKLY_BOOTSTRAP_FLAGS,
        };
        (window as any).ldClient = {
          variation: (key: string) => _allFlags[key],
          allFlags: () => _allFlags,
        };
        r(window.ldClient);
      });
    } else {
      const ldClient = LDClient.initialize(
        window.LAUNCHDARKLY_FRONTEND_KEY!,
        context,
        useBootstrappedFlags
          ? {
              bootstrap: window.Glide.LAUNCHDARKLY_BOOTSTRAP_FLAGS,
            }
          : undefined
      );

      initializingPromise = new Promise((r) => {
        ldClient.on('ready', () => {
          window.ldClient = ldClient;
          r(ldClient);
        });
      });
    }
  } else {
    initializingPromise = new Promise((r) => {
      (window as any).ldClient = {
        variation: (key: string) =>
          (window.Glide.CONSTANTS.DEFAULT_FEATURES ?? {})[key],
        allFlags: () => window.Glide.CONSTANTS.DEFAULT_FEATURES ?? {},
      };
      r(window.ldClient);
    });
  }
  return initializingPromise;
};

window.getLDClient = getLDClient;

export const variation = async (key: string, defaultValue?: any) => {
  try {
    const client = await getLDClient();
    return client.variation(key, defaultValue);
  } catch (e) {
    return false;
  }
};
