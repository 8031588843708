import { computed, makeObservable } from 'mobx';
import type { ItemDpp } from 'src/types/proto/transactions';
import Item, { type ItemJson, type ItemStore } from './item';

export type DppJson = ItemJson<'DPP'> & {
  dpp: ItemDpp;
};

export default class Dpp extends Item<ItemStore, DppJson> {
  constructor(store: ItemStore, json: DppJson) {
    super(store, json);

    makeObservable(this);
  }

  @computed
  get status() {
    return this.kindItem?.status;
  }

  @computed
  get orderDppFlowId() {
    return this.kindItem?.orderDppFlowId;
  }
}
