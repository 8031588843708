import { computed, makeObservable } from 'mobx';
import Item, { ItemJson, ItemStore } from './item';

const EXCLUDE_FROM_PACKETS_LIST_TYPES = ['SHARE_PACKET'];

export type PacketJson = ItemJson<'PACKET'>;

export default class Packet extends Item<ItemStore, PacketJson> {
  resolvedItems = ['child', 'folder'];

  constructor(store: ItemStore, json: PacketJson) {
    super(store, json);

    makeObservable(this);
  }

  @computed
  get childKind() {
    return this.kindItem?.childKind;
  }

  @computed
  get childId() {
    return this.kindItem?.childId;
  }

  @computed
  get folderId() {
    return this.kindItem?.folderId;
  }

  @computed
  get child() {
    return this.store.itemsById.get(this.childId);
  }

  @computed
  get folder() {
    return this.store.itemsById.get(this.folderId);
  }

  @computed
  get tdIds() {
    return this.folder?.documentIds ?? [];
  }

  @computed
  get archived() {
    return this.kindItem?.archived;
  }

  can(op: string) {
    if (op === 'archive') {
      return !this.archived;
    }
    if (op === 'unarchive') {
      return !!this.archived;
    }
    if (op === 'view_in_packets_list' && this.childKind) {
      return (
        this.id &&
        this.child &&
        EXCLUDE_FROM_PACKETS_LIST_TYPES.indexOf(this.childKind) === -1
      );
    }
    return super.can(op);
  }
}
