import type {
  GetTemplateChecklistsRequest,
  GetTemplateChecklistsResponse,
} from 'src/types/proto/services/template_checklist_public_service';
import type { ApiResponseData } from 'src/types/utils';
import { formatToSnakeCase } from 'src/utils/format-data-object';

import BaseApi from '../base-api';

export default class TemplateChecklists extends BaseApi {
  list(params: GetTemplateChecklistsRequest = {}) {
    return this.get<ApiResponseData<GetTemplateChecklistsResponse>>(
      '/template_checklists',
      {
        params: formatToSnakeCase(params),
      }
    );
  }
}
