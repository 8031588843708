

import React, { FC } from 'react';
import classNames from 'classnames';
import { observer, inject } from 'mobx-react';
import hideBars from 'src/components/common/hide-bars';
import Link from 'src/components/router/link';
import RouterStore from 'src/stores/router-store';

const clsPrefix = 'app-full-error-message';
const HOME_ANCHOR = 'Take me home';

export interface AppFullErrorMessageProps {
  message: string;
  fullPage?: boolean;
}

export interface AppFullErrorMessageOwnProps extends AppFullErrorMessageProps {
  router: RouterStore;
}

const InnerErrorMessage: FC<AppFullErrorMessageOwnProps> = ({
  message,
  fullPage,
  router,
}) => {
  const homeRoute = router.routes?.find((r) => r.name === 'home');

  return (
    <div
      className={classNames(clsPrefix, 'flex-column flex-center', {
        'full-page': Boolean(fullPage),
      })}
    >
      <h3 className={`${clsPrefix}__message`}>{message}</h3>
      <p>
        Need help? Contact us at{' '}
        <a href="mailto:support@glide.com">support@glide.com</a>.
      </p>
      <p>
        {homeRoute?.component || homeRoute?.redirect ? (
          <Link routeName="home">{HOME_ANCHOR}</Link>
        ) : (
          <a href="/">{HOME_ANCHOR}</a>
        )}{' '}
        or <a href="">reload page</a>
      </p>
    </div>
  );
};

const AppFullErrorMessage: FC<AppFullErrorMessageProps> = inject('router')(
  observer(({ fullPage = true, ...restProps }) => {
    const Inner = fullPage ? hideBars()(InnerErrorMessage) : InnerErrorMessage;
    return <Inner fullPage={fullPage} {...restProps} />;
  })
);

export default AppFullErrorMessage as FC<AppFullErrorMessageProps>;
