import { computed, makeObservable } from 'mobx';
import type TransactionStore from 'src/stores/transaction-store';
import type { Transaction as TransactionJson } from 'src/types/proto/transactions';
import Item from './item';

export default class CoverPhoto extends Item {
  constructor(store: TransactionStore, json: TransactionJson) {
    super(store, json);

    makeObservable(this);
  }

  @computed
  get content() {
    return this.kindItem.content;
  }
}
