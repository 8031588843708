import type {
  FindFormInitiationPageByHashQuery,
  FindFormInitiationPageByHashResponse,
} from 'src/types/proto/services/form_initiation_page_public_service';
import type { ApiResponseData } from 'src/types/utils';
import BaseApi from '../base-api';

export default class FormInitiationPages extends BaseApi {
  getPageByHash(urlHash: FindFormInitiationPageByHashQuery['urlPath']) {
    return this.get<ApiResponseData<FindFormInitiationPageByHashResponse>>(
      `/form_initiation_pages/find_by_hash/${encodeURIComponent(urlHash)}`
    );
  }

  // DEPRECATED
  createPublicGFPPacket(data: unknown) {
    return this.post('/transactions/create_client_gfp_packet', data);
  }
}
